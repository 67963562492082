<template>
  <custom-dict :mobile="mobile" :component="component"></custom-dict>
</template>

<script>
import customDict from '@/components/custom/custom-dict'

export default {
  components: {
    customDict
  },

  props: {
    component: {
      type: String,
      default: ''
    },
    mobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>
