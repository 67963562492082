<template>
  <v-layout wrap>
    <v-flex md1 sm12 xs12>
      <v-layout column class="mx-2 mb-4">
        <dict-title :title="$t('statistics.season')"></dict-title>

        <v-list class="mt-3 pt-8 elevation-2">
          <v-list-item-group color="main_color">
            <v-list-item
              class="options__list"
              @click.stop
              v-for="(item, i) in lists.years"
              :key="i"
              :style="item.border ? 'border-bottom: 1px solid #E2E2E2' : ''"
            >
              <v-list-item-content>
                <v-list-item-title class="statistics__list--text">{{ item }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-layout>
    </v-flex>
    <v-flex md11 sm12 xs12>
      <v-layout wrap>
        <v-flex md4 sm12 xs12>
          <v-layout column class="ma-0 mb-4">
            <v-layout column class="mx-2 mb-4">
              <dict-title :title="$t('statistics.stock')"></dict-title>
              <custom-list :list="lists.stock"></custom-list>
            </v-layout>

            <v-layout column class="mx-2">
              <dict-title :title="$t('statistics.structure')"></dict-title>
              <custom-list :list="lists.structures"></custom-list>
            </v-layout>
          </v-layout>
        </v-flex>

        <v-flex md4 sm12 xs12>
          <v-layout column class="ma-0 mb-4">
            <v-layout column class="mx-2 mb-4">
              <dict-title :title="$t('statistics.weather')"></dict-title>
              <v-card class="mt-3 pt-6 px-4 elevation-2 pb-3 weather" style="border-radius: 0">
                <v-flex md4 offset-md8 class="mt-2 mb-4">
                  <v-select
                    class="elevation-0 statistics__select"
                    :items="lists.cities"
                    hide-details
                    solo
                    single-line
                  ></v-select>
                </v-flex>
                <p class="mb-4 statistics__text--14">12 сентября, 12:40</p>
                <v-flex d-flex class="mb-8">
                  <v-flex md4>
                    <v-layout fill-height align-center>
                      <v-icon
                        style="width: 34px"
                        size="34"
                        v-text="'$vuetify.icons.statistics_sun'"
                      ></v-icon>
                      <span class="ml-3 statistics__text--20">18 °C</span>
                    </v-layout>
                  </v-flex>
                  <v-flex md3 offset-md1>
                    <v-layout justify-center>
                      <v-layout column align-center>
                        <v-icon v-text="'$vuetify.icons.statistics_blob'"></v-icon>
                        <span class="statistics__text--10">24%</span>
                      </v-layout>
                      <v-layout column align-center>
                        <v-icon v-text="'$vuetify.icons.statistics_wind'"></v-icon>
                        <span class="statistics__text--10">0 м/с</span>
                      </v-layout>
                    </v-layout>
                  </v-flex>
                </v-flex>
                <v-flex>
                  <v-layout justify-space-between>
                    <p class="statistics__text--14">15 сентября, пн</p>
                    <span>
                      <v-icon v-text="'$vuetify.icons.statistics_rain'"></v-icon>
                      <span class="statistics__text--12"> 13...15 °C</span>
                    </span>
                  </v-layout>
                  <v-layout justify-space-between>
                    <p class="statistics__text--14">16 сентября, вт</p>
                    <span>
                      <v-icon v-text="'$vuetify.icons.statistics_rain'"></v-icon>
                      <span class="statistics__text--12"> 13...15 °C</span>
                    </span>
                  </v-layout>
                  <v-layout justify-space-between>
                    <p class="statistics__text--14">17 сентября, ср</p>
                    <span>
                      <v-icon v-text="'$vuetify.icons.statistics_rain'"></v-icon>
                      <span class="statistics__text--12"> 13...15 °C</span>
                    </span>
                  </v-layout>
                </v-flex>
              </v-card>
            </v-layout>

            <v-layout column class="mx-2">
              <dict-title :title="$t('statistics.expense')"></dict-title>
              <custom-list :list="lists.expense"></custom-list>
            </v-layout>
          </v-layout>
        </v-flex>

        <v-flex md4 sm12 xs12>
          <v-layout column class="ma-0 mb-4">
            <v-layout column class="mx-2 mb-4">
              <dict-title :title="$t('statistics.completed_jobs')"></dict-title>
              <custom-list :list="lists.completed_jobs"></custom-list>
            </v-layout>

            <v-layout column class="mx-2">
              <dict-title :title="$t('statistics.fields')"></dict-title>
              <custom-list :list="lists.fields"></custom-list>
            </v-layout>

            <v-layout column class="mx-2 mt-4">
              <dict-title :title="$t('statistics.persons')"></dict-title>
              <custom-list :list="lists.persons"></custom-list>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import dictTitle from '@/components/custom/dicts/title'
import customList from '@/components/custom/statistics/list'

export default {
  components: {
    dictTitle,
    customList
  },

  data() {
    return {
      lists: {
        years: [2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010],
        cities: ['Москва', 'Омск', 'Махачкала'],
        stock: [
          {
            name: 'Средства защиты растений',
            value: '30 кг'
          },
          {
            name: 'Удобрения',
            value: '30 кг'
          },
          {
            name: 'Семена',
            value: '30 кг'
          }
        ],
        structures: [
          {
            name: 'Зерновые и зернобобовые культуры',
            value: '>60'
          },
          {
            name: 'Технические культуры',
            value: '45-60'
          },
          {
            name: 'Подсолнечник на зерно',
            value: '30-45'
          },
          {
            name: 'Картофель',
            value: '20-30'
          },
          {
            name: 'Продовольственные бахчевые культуры',
            value: '10-20'
          },
          {
            name: 'Корнеплодные кормовые культуры',
            value: '<10'
          }
        ],
        expense: [
          {
            name: 'Полей',
            value: '3'
          },
          {
            name: 'Общая площадь',
            value: '116.9 га'
          }
        ],
        fields: [
          {
            name: 'Полей',
            value: '3'
          },
          {
            name: 'Общая площадь',
            value: '116.9 га'
          }
        ],
        persons: [
          {
            name: 'Всего сотрудников',
            value: '13'
          },
          {
            name: 'Общая выработка',
            value: '85.9 га'
          }
        ],
        completed_jobs: [
          {
            name: 'Всего',
            value: '334'
          },
          {
            name: 'Общая стоимость',
            value: '334334 руб.',
            border: true
          },
          {
            name: 'Полевых работ',
            value: '23'
          },
          {
            name: 'Стоимость полевых работ',
            value: '12456 руб.'
          },
          {
            name: 'Выполнено помощниками',
            value: '123',
            className: 'statistics__list--text-small'
          },
          {
            name: 'Видов работ',
            value: '21',
            className: 'statistics__list--text-small'
          },
          {
            name: 'Общ.продолжительность',
            value: '86 часов',
            className: 'statistics__list--text-small'
          },
          {
            name: 'Полей',
            value: '3',
            className: 'statistics__list--text-small'
          }
        ]
      }
    }
  }
}
</script>
