<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="$t('history.title')">
      <v-btn dark icon @click="getReport($route)">
        <v-icon>mdi-update</v-icon>
      </v-btn>
    </dict-title>

    <div class="d-flex overflow-hidden pt-4 fill-height">
      <v-card class="d-flex flex-column elevation-2 pt-4 flex-grow-1">
        <v-layout class="pt-3 px-3 flex-grow-0" justify-space-between align-center wrap>
          <v-flex>
            <v-layout class="my-0" align-center>
              <date-picker
                v-model="date_picker.time"
                class="px-3 black--text input__calendar"
                type="datetime"
                range
                value-type="format"
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="Выберите период"
                confirm
                confirm-text="Применить"
                range-separator=" - "
                :clearable="false"
                :shortcuts="shortcuts"
                @confirm="changeTimeRange()"
              >
                <template v-slot:icon-calendar>
                  <v-icon v-text="'$calendar'" size="17"></v-icon>
                </template>
              </date-picker>
            </v-layout>
          </v-flex>
          <v-flex md5>
            <v-layout justify-end>
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn class="btn__reports" @click="togglePage()" v-on="on">
                    <v-icon v-text="'$graph'"></v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('rtables.graph') }}</span>
              </v-tooltip>

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    class="btn__reports"
                    :href="getReportFile()"
                    v-on="on"
                    >
                    <v-icon v-text="'$save_report'"></v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('rtables.save_report') }}</span>
              </v-tooltip> -->

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    class="btn__reports"
                    v-on="on"
                    >
                    <v-icon v-text="'$total'"></v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('rtables.total') }}</span>
              </v-tooltip> -->
            </v-layout>
          </v-flex>
        </v-layout>

        <div class="px-3">
          <v-text-field class="pt-0" type="search" v-model="search" color="main_color">
            <template v-slot:append>
              <v-icon v-text="'$search'" size="14" class="mt-2"></v-icon>
            </template>
          </v-text-field>
        </div>

        <v-card ref="table" class="table__main elevation-0 overflow-y-auto flex-grow-1">
          <custom-table v-if="page === 'table'" :table="table" :search="search"></custom-table>
          <line-chart
            v-if="page === 'graph'"
            class="fill-height"
            :chart-data="chart.data"
          ></line-chart>
        </v-card>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import HTTP from '@/api/http'
import reports from '@/mixins/reports'

import customTable from '@/components/custom/custom-table'
import dictTitle from '@/components/custom/dicts/title'

import lineChart from '@/components/secondComponents/LineChart'

export default {
  components: {
    DatePicker,
    customTable,
    dictTitle,
    lineChart
  },

  mixins: [reports],

  props: {
    component: {
      type: String
    }
  },

  data: () => ({
    chart: {
      data: {
        labels: [],
        datasets: []
      },
      options: {}
    }
  }),

  beforeRouteUpdate(to, from, next) {
    this.getReport(to)
    next()
  },

  methods: {
    async getReport(route) {
      const [date_from, date_to] = this.date_picker.time
      const options = {
        [`id_${this.component.replace(/s$/, '')}`]: route.params[this.component],
        date_from,
        date_to
      }

      this.loading = true

      const {request} = this.$route.query
      const {data} = await HTTP.post(request, options)

      if (data) {
        const header = [
          {
            value: 'number',
            sortable: false,
            text: '№'
          }
        ]
        const items = data.models

        this.table.names = data.columns
          .filter(item => item.visible)
          .sort((a, b) => a.forder - b.forder)
          .map(item => item.value)
        this.table.headers = header.concat(
          data.columns.filter(item => item.visible).sort((a, b) => a.forder - b.forder)
        )
        this.table.items = items.map((item, index) => ({
          ...item,
          index: index + 1
        }))

        this.chart.data = {
          labels: items.map(item => item.datepoint),
          datasets: [
            {
              label: 'value',
              borderColor: this.$vuetify.theme.themes.light.main_color,
              backgroundColor: this.$vuetify.theme.themes.light.main_color,
              fill: false,
              data: items.map(item => item.val)
            }
          ]
        }
      }

      this.loading = false
    }
  }
}
</script>
