const parseLatlon = (latlon, type) => {
  if (['rectangle', 'polygon'].includes(type)) {
    const coords = latlon
      .replace(/SRID=4326;POLYGON\(\((.+)\)\)/, '$1')
      .split(/,\s?/)
      .map(coord => coord.split(' '))
    // console.log(coords)
    return coords
  }
  if (['circle', 'marker'].includes(type)) {
    const coords = latlon.replace(/SRID=4326;POINT\s?\((.+)\)/i, '$1').split(/ /)
    // console.log(coords[0])
    return coords
  }
  if (['polyline'].includes(type)) {
    const coords = latlon
      .replace(/SRID=4326;LINESTRING\((.+)\)/, '$1')
      .split(/,\s?/)
      .map(coord => coord.split(' '))
    // console.log(coords)
    return coords
  }
  return null
}

const stringifyLatlon = (latlon, type) => {
  if (['rectangle', 'polygon'].includes(type)) {
    let coords = latlon
      .map(coord => {
        const [x, y] = [coord.lat, coord.lng]
        return `${x} ${y}`
      })
      .join(', ')
    coords = `${coords}, ${latlon[0].lat} ${latlon[0].lng}`
    return `SRID=4326;POLYGON((${coords}))`
  }
  if (['circle', 'marker'].includes(type)) {
    const [x, y] = [latlon[0], latlon[1]]
    const coords = `SRID=4326;POINT(${x} ${y})`
    return coords
  }
  if (['polyline'].includes(type)) {
    const coords = latlon
      .map(coord => {
        const [x, y] = [coord.lat, coord.lng]
        return `${x} ${y}`
      })
      .join(', ')
    return `SRID=4326;LINESTRING(${coords})`
  }
  return null
}

module.exports = {
  parseLatlon,
  stringifyLatlon
}
