import Vue from 'vue'
import Router from 'vue-router'

import Auth from '@/views/unic/Auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/'
    },
    {
      path: '/auth',
      components: {
        main: Auth,
        mobile: Auth
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const {token} = to.query

  if (token) {
    localStorage.setItem('token', token)
  }

  next()
})

export default router
