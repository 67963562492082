import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {firestorePlugin} from 'vuefire'
import L, {Icon} from 'leaflet'
import store from '@/store/store'
import vuetify from '@/plugins/vuetify'
import keycloak from '@/utils/keycloak'
import App from './App.vue'
import router from './router'
import messages from './locales/messages'

import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import '@/styles/main.sass'
import '@/styles/simcir/simcir-basicset.css'
import '@/styles/simcir/simcir.css'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'firebaseui/dist/firebaseui.css'

import 'vue2-datepicker/locale/ru'
import '@/styles/datepicker.scss'

/* eslint-disable global-require */

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

/* eslint-enable global-require */

Vue.use(VueI18n)
Vue.use(firestorePlugin)

Vue.config.productionTip = false

const i18n = new VueI18n({locale: 'ru_ru', messages})

export default i18n

keycloak.init({onLoad: 'login-required'}).then((authenticated) => {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app')
})
