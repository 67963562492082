<template>
  <v-navigation-drawer
    class="mr-0 pr-0 drawer--gradient"
    style="padding-top: 77px; overflow-y: auto"
    hide-overlay
    :mini-variant="mobile === false"
    mini-variant-width="64"
    :expand-on-hover="mobile === false"
    app
  >
    <div>
      <div class="d-flex justify-center mb-4">
        <v-btn icon @click="goToMainPage()">
          <v-icon v-text="getModuleIcon"></v-icon>
        </v-btn>
      </div>
      <v-divider class="main_color--text"></v-divider>
      <v-layout align-center>
        <v-flex v-if="/^\/reports/.test($route.path)">
          <v-list nav dense>
            <v-list-item
              link
              v-for="(item, index) in getDicts('rtables')"
              :key="item.id"
              class="transparent justify-space-between"
              active-class="main_color--text"
              elevation="0"
              :to="`/reports/rtable_${item.id}/${item.jdata.type_dict || 'groups'}`"
              style="height: 48px"
            >
              <v-list-item-icon>
                <v-icon
                  v-if="auth.type_module !== 'smis'"
                  :color="$route.params.rtables === item.id ? 'main_color' : 'white'"
                  v-text="`$dicts_rtables`"
                  style="min-width: 32px; min-height: 32px"
                ></v-icon>
                <v-icon
                  v-if="auth.type_module === 'smis' && index === 2"
                  v-text="'$dicts_reports_report'"
                  style="min-width: 32px; min-height: 32px"
                >
                </v-icon>
                <v-icon
                  v-if="auth.type_module === 'smis' && index === 1"
                  v-text="'$dicts_reports_accident'"
                  style="min-width: 32px; min-height: 32px"
                >
                </v-icon>
                <v-icon
                  v-if="auth.type_module === 'smis' && index === 0"
                  v-text="'$dicts_reports_failure'"
                  style="min-width: 32px; min-height: 32px"
                >
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class="$route.params.rtables === item.id ? 'main_color--text' : 'white--text'"
                >{{ item.name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-flex>

        <v-flex v-else>
          <v-list nav dense>
            <v-list-item
              link
              dark
              v-for="(title, index) in titles"
              :key="title"
              :ref="title"
              :data-index="index"
              class="transparent justify-space-between"
              active-class="main_color--text"
              elevation="0"
              :to="`/dictionary/${title}`"
              style="height: 48px"
            >
              <v-list-item-icon>
                <v-icon
                  v-text="`$dicts_${title}`"
                  style="min-width: 32px; min-height: 32px"
                ></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ getTitle(title) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  props: {
    mobile: {
      type: Boolean
    }
  },

  computed: {
    getTitle() {
      return title => {
        if (this.config.module[title]) {
          return this.config.module[title].label
        }
        return ''
      }
    },
    getModuleIcon() {
      if (this.auth?.jdata) {
        if (this.auth.type_module === 'skifme') {
          return `$modules_eco_${this.auth.jdata.color}`
        }
        return `$modules_${this.auth.type_module}_${this.auth.jdata.color}`
      }
      return null
    },
    titles() {
      return Object.keys(this.getOptions)
        .filter(dict => this.getOptions[dict].is_visible)
        .sort((a, b) => this.getOptions[a].sort - this.getOptions[b].sort)
    },
    ...mapState(['auth']),
    ...mapGetters(['getDicts', 'config', 'getOptions'])
  },

  methods: {
    goToMainPage() {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    }
  }
}
</script>
