<template>
  <v-layout class="px-4" column>
    <v-layout class="mt-4" wrap style="width: 100%" v-if="hideActions === false">
      <v-flex md6 xs6>
        <v-layout justify-space-between>
          <v-flex md4 xs4>
            <p class="mb-0 text-center">{{ $t('master.works.id_product') }}</p>
            <v-input class="input__main px-2" height="25" hide-details>
              <v-autocomplete
                v-model="options.id_product"
                item-value="id"
                item-text="name"
                :items="getDicts('products')"
              >
              </v-autocomplete>
            </v-input>
          </v-flex>
          <v-flex md4 xs4>
            <p class="mb-0 text-center">{{ $t('master.works.id_stock') }}</p>
            <v-input class="input__main px-2" height="25" hide-details>
              <v-autocomplete
                v-model="options.id_stock"
                item-value="id"
                item-text="name"
                :items="getDicts('stocks')"
              >
              </v-autocomplete>
            </v-input>
          </v-flex>
          <v-flex md3 xs3>
            <p class="mb-0 text-center">{{ $t('master.works.tcount') }}</p>
            <v-text-field
              solo
              single-line
              flat
              hide-details
              class="input__options"
              v-model="options.tcount"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex md2 xs2 offset-md1 align-self-center>
        <v-btn class="btn__save px-3" text style="width: 100%" @click="addProduct()">
          <span>{{ $t('add') }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-card class="mt-2">
      <v-flex md6 xs12 class="px-3">
        <v-layout class="pr-3 posr">
          <v-text-field type="search" class="grey_custom--text" v-model="search"></v-text-field>
          <v-icon
            v-text="'$vuetify.icons.search'"
            class="dict__icon--search mt-8"
            size="14"
          ></v-icon>
        </v-layout>
      </v-flex>
      <v-flex class="table__main">
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :loading="loading"
          :search="search"
          hide-default-footer
          :items-per-page="100000"
          fixed-header
          no-data-text="Товаров нет"
          elevation="0"
        >
          <!-- <template v-slot:body="{ item }">
          <v-icon
            v-text="'$vuetify.icons.add'"
            @click="deleteProduct(item)"
          ></v-icon>
        </template> -->
          <!-- <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.name + index"
                class="table__items--td"
                :class="[index % 2 === 1 ? 'report_color' : '']">
                <td class="black--text px-4">{{ item.name}}</td>
                <td v-if="component === 'works'" class="black--text px-4 ">{{ item.type_work}}</td>
                <td v-else class="black--text px-4 ">{{ item.type_work}}</td>
                <td class="black--text px-4">{{ item.tcount}}</td> -->
          <!-- <td class="black--text py-0 px-1" v-for="(name, index) in table.names" :key="index" colspan> -->
          <!-- <td class=" px-4">
                    <v-btn icon @click="deleteProduct(item)">
                      <v-icon small v-text="'$vuetify.icons.remove'"> </v-icon>
                    </v-btn>
                  </td> -->
          <!-- </td> -->
          <!-- </tr>
            </tbody>
          </template> -->
          <template v-slot:progress>
            <v-progress-linear color="main_color" :height="10" indeterminate></v-progress-linear>
          </template>
          <template v-slot:items="props">
            <tr class="table__items--td">
              <td class="black--text" v-for="(item, index) in table.names" :key="index" colspan>
                {{ props.item[item] }}
              </td>
              <td v-if="hideActions === false">
                <v-layout align-center justify-end>
                  <v-btn icon @click="deleteProduct(props.item)">
                    <v-icon small v-text="'$vuetify.icons.remove'"> </v-icon>
                  </v-btn>
                </v-layout>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-card>
  </v-layout>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'

export default {
  components: {},

  props: {
    component: {
      type: String,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    typeTable: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      options: {},
      table: {
        headers: [],
        items: [],
        names: [],
      },
      search: '',
      loading: false,
    }
  },
  computed: {
    ...mapState(['auth', 'user_data']),
    ...mapGetters(['getDicts']),
  },

  watch: {
    '$route.params': function (obj) {
      this.getTableItems()
    },
  },
  created() {
    this.init()
  },

  methods: {
    // here generate the tables head ( must add new field here based on the type of the field )
    async init() {
      if (this.component === 'works') {
        this.table.headers = [
          {
            value: 'name',
            text: this.$t('master.works.id_product'),
          },
          {
            value: 'name_stock',
            text: this.$t('master.works.id_stock'),
          },
          {
            value: 'tcount',
            text: this.$t('master.works.tcount'),
          },
        ]
      } else if (this.component === 'stocks') {
        this.table.headers = [
          {
            value: 'name',
            text: this.$t('master.works.id_product'),
          },
          {
            value: 'name_work',
            text: this.$t('master.works.id_work'),
          },
          {
            value: 'tcount',
            text: this.$t('master.works.tcount'),
          },
        ]
      }
      this.table.names = this.table.headers.map((header) => header.value)
      this.getTableItems()
    },
    async addProduct() {
      const getProduct = (id) => {
        const findProduct = this.getDicts('products').find((product) => product.id === id)
        return findProduct
      }
      const {name} = getProduct(this.options.id_product)
      const findWork = this.getDicts('works').find((work) => work.id === this.$route.params.works)
      const {type_work} = findWork
      const options = {
        name,
        type_work,
        id_work: this.$route.params.works,
        ...this.options,
      }
      if (type_work !== 'in_products') {
        options.tcount = -options.tcount
      }
      const {data} = await HTTP.put('stocks_products', options)
      if (data.id) {
        this.table.items.push({
          id: data.id,
          ...this.mapProduct(options),
        })
      }
      this.options = {}
    },
    async deleteProduct(item) {
      const {data} = await HTTP.delete(`stocks_products?id=${item.id}`)
      if (data.id) {
        this.table.items = this.table.items.filter((row) => row.id !== item.id)
      }
    },
    mapProduct(product) {
      return {
        ...product,
        tcount: Math.abs(product.tcount),
        name_stock: this.getElemName(product.id_stock, 'stocks'),
        name_work: this.getElemName(product.id_work, 'works'),
      }
    },
    getElemName(id, dict) {
      const findElem = this.getDicts(dict).find((elem) => elem.id === id)
      // console.log(findElem)
      return findElem ? findElem.name : ''
    },
    async getTableItems() {
      const {data} = await HTTP.post('stocks_products')
      console.log(this.items)
      if (this.component === 'works') {
        this.table.items = data
          .filter((item) => item.id_work === this.$route.params.works)
          .map(this.mapProduct)
      } else if (this.component === 'stocks') {
        if (this.typeTable === 'itog') {
          this.table.items = data.reduce((goods, product) => {
            if (product.id_stock === this.$route.params.stocks) {
              const findProduct = goods.find((good) => good.id_product === product.id_product)
              if (findProduct) {
                findProduct.tcount += product.tcount
                return goods
              }
              goods.push({
                ...product,
                name_stock: this.getElemName(product.id_stock, 'stocks'),
                name_work: this.getElemName(product.id_work, 'works'),
              })
              return goods
            }
            return goods
          }, [])
        } else if (this.typeTable === 'in_products') {
          this.table.items = data
            .filter(
              (item) =>
                item.id_stock === this.$route.params.stocks && item.type_work === 'in_products'
            )
            .map(this.mapProduct)
        } else {
          this.table.items = data
            .filter(
              (item) =>
                item.id_stock === this.$route.params.stocks && item.type_work !== 'in_products'
            )
            .map(this.mapProduct)
        }
      }
    },
  },
}
</script>
