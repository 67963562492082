<template>
  <div class="pa-2">
    <div class="d-flex mb-4">
      <v-flex md6 class="px-2">
        <h2 class="text-center mb-4">{{ $t('options.commands.phones') }}</h2>
        <v-card
          v-for="item in phones.items"
          :key="item.key"
          @click="choosePhone(item)"
          :class="[
            phones.phone_to === item.key ? 'report_color' : '',
            'mb-2 py-1 px-2 d-flex justify-space-between align-center'
          ]"
        >
          <span>{{ item.label }}</span>
          <span>{{ getTsensor(item).name }}</span>
        </v-card>
      </v-flex>
      <v-flex md6 class="px-2">
        <h2 class="text-center mb-4">{{ $t('component.tsensors') }}</h2>
        <v-input class="input__main px-2" height="25" hide-details>
          <custom-select
            v-model="id_tsensor"
            :items="getDicts('tsensors')"
            item-value="id"
            item-text="name"
          >
          </custom-select>
        </v-input>
      </v-flex>
    </div>
    <div class="d-flex justify-center">
      <v-btn
        class="btn__save px-3 mx-1"
        text
        :disabled="!(phones.phone_to && getExistPcall)"
        @click="removePcall()"
      >
        <span class="subtitle-1 text-none">{{ $t('remove') }}</span>
      </v-btn>
      <v-btn
        class="btn__save px-3 mx-1"
        text
        @click="savePcall()"
        :disabled="!(phones.phone_to && id_tsensor)"
      >
        <span class="subtitle-1 text-none">{{ $t('save') }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import HTTP from '@/api/http'
import {mapGetters, mapState} from 'vuex'

import customSelect from '@/components/custom/custom-select'

export default {
  components: {
    customSelect
  },

  data: () => ({
    phones: {
      phone_to: null,
      items: []
    },
    id_tsensor: null,
    pcalls: []
  }),
  computed: {
    getExistPcall() {
      const {persons: id_person} = this.$route.params
      const exist_pcall = this.pcalls.find(
        pcall =>
          pcall.phone_to === this.phones.phone_to &&
          pcall.id_tsensor === this.id_tsensor &&
          pcall.id_person === id_person
      )
      if (exist_pcall) {
        return true
      }
      return false
    },
    getTsensor() {
      return phone => {
        const {persons: id_person} = this.$route.params
        const exist_pcall = this.pcalls.find(
          pcall => pcall.phone_to === phone.key && pcall.id_person === id_person
        )

        if (exist_pcall) {
          const exist_tsensor = this.getDicts('tsensors').find(
            tsensor => tsensor.id === exist_pcall.id_tsensor
          )
          return exist_tsensor || {}
        }
        return {}
      }
    },
    ...mapGetters(['getDicts'])
  },

  watch: {
    '$route.params': function() {
      this.setIdTsensor()
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.getPcalls()
      this.getPhones()
    },
    async getPcalls() {
      const {data: pcalls} = await HTTP.post('pcalls')
      this.pcalls = pcalls
    },
    async getPhones() {
      const {data} = await HTTP.get('ats_in')
      this.phones.items = data
    },
    async choosePhone({key}) {
      this.phones.phone_to = key
      this.setIdTsensor()
    },
    setIdTsensor() {
      const {persons: id_person} = this.$route.params
      const exist_pcall = this.pcalls.find(
        pcall => pcall.phone_to === this.phones.phone_to && pcall.id_person === id_person
      )
      if (exist_pcall) {
        this.id_tsensor = exist_pcall.id_tsensor
      } else {
        this.id_tsensor = null
      }
    },
    async savePcall() {
      const {persons: id_person} = this.$route.params
      const pcall = {
        id_person,
        phone_to: this.phones.phone_to,
        id_tsensor: this.id_tsensor
      }

      await this.removePcall()

      const {data} = await HTTP.put('pcalls', pcall)
      if (data.id) {
        this.pcalls.push({
          id: data.id,
          ...pcall
        })
        this.id_tsensor = pcall.id_tsensor
      }
    },
    async removePcall() {
      const {persons: id_person} = this.$route.params
      const exist_pcall = this.pcalls.find(
        pcall => pcall.phone_to === this.phones.phone_to && pcall.id_person === id_person
      )
      // console.log(this.phones.phone_to)
      if (exist_pcall) {
        const {data} = await HTTP.delete(`pcalls?id=${exist_pcall.id}`)
        if (data.id) {
          this.pcalls = this.pcalls.filter(pcall => pcall.id !== exist_pcall.id)
          this.id_tsensor = null
        }
      }
    }
  }
}
</script>
