// Для создания и настройки элементов

import HTTP from '@/api/http'

export default {
  data() {
    return {
      elem: {
        parent: '',
        child: '',
      },
      notify: {
        show: false,
        message: '',
      },
    }
  },
  computed: {
    getOptions() {
      return this.$store.getters.options
    },
    getTypes() {
      try {
        const tableName = this.getOptions[this.component].type_terminal[this.options.type_terminal]
        const types = tableName[`${this.component}_types`].map(
          (obj) => obj[`type_${this.elem.parent}`]
        )
        return types
      } catch (e) {
        return []
      }
    },
    getItemsForLists() {
      return (key) => {
        if (this.getOptions[this.component][key]) {
          const list = Object.keys(this.getOptions[this.component][key])
          return list
        }
        return ['default']
      }
    },
    shouldShowComponent() {
      // return () => true
      return (item) => {
        const prop = `type_${this.component.replace(/s$/, '')}`
        const {[prop]: type_elem = 'default'} = this.options
        const matchTypeElem = () =>
          item.field === type_elem ||
          item.type_entries.some((entry) => [type_elem, 'default'].includes(entry))
        return matchTypeElem()
      }
    },
    getModelInput() {
      return (item) => {
        if (item.is_jdata) {
          return this.options.jdata
        }
        return this.options
      }
    },
  },

  methods: {
    setElems(component = this.component, secondComponent = this.secondComponent) {
      this.elem = {
        parent: component.split('_')[0].replace(/s$/, ''),
        child: secondComponent.split('_')[0].replace(/s$/, ''),
      }
    },
    async uploadPdf(pdf) {
      const file = pdf.target.files[0]
      console.log(file)
      if (!file) return

      const data = new FormData()
      data.append('pdf', file)

      const {data: src} = await HTTP.post(
        `upload/${this.component}/${this.$route.params[this.component]}`,
        data
      )

      if (src) {
        this.options.jdata.reg_doc = src.pdf
      }
    },
    goToPdfFile() {
      const {src} = this.options.jdata.reg_doc
      this.$router.push(`/docs/${src}`)
    },
    async saveItemEdit({field, items = {}}, options = {}) {
      const getParams = () => {
        const params = Object.entries(items).reduce((result, [key, value]) => {
          if (result === '?') {
            return `${result}${key}=${value}`
          }
          return `${result}&${key}=${value}`
        }, '?')

        if (params === '?') {
          return ''
        }
        return params
      }

      const obj = {
        ...this.options,
        ...options,
      }

      const {data} = await HTTP.put(`${field}${getParams()}`, {
        ...obj,
      })

      this.showNotifyMsg(data.mess)

      if (data.id) {
        obj.id = data.id
      }

      return obj
    },

    updateItemOfDictionary(obj) {
      this.$store.commit('PUT_ITEM', {
        ...obj,
        jdata: {
          ...obj.jdata,
          component: this.component,
        },
      })
    },

    async saveChangesOfItem(button) {
      const itemOfDictionary = await this.saveItemEdit(button, {
        id: this.$route.params[this.component],
      })
      this.updateItemOfDictionary(itemOfDictionary)
    },

    showNotifyMsg(msg) {
      this.notify.message = msg
      this.notify.show = true
    },
  },
}
