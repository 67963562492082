<template>
  <v-flex class="px-3">
    <v-layout justify-center>
      <v-flex md5 sm12 class="px-3">
        <v-card class="transparent mb-2" elevation="0">
          <v-layout wrap align-center>
            <v-flex md12 sm12>
              <v-layout justify-space-between align-center wrap class="px-2">
                <v-flex style="padding: 0 3%" md4>
                  <v-layout justify-center align-center>
                    <v-btn
                      v-ripple="true"
                      @click="createNotify('fire')"
                      :disabled="checkNotifies"
                      text
                      class="monitoring__btn monitoring__btn--fire"
                    >
                      {{ $t('monitoring.fire') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
                <v-flex style="padding: 0 3%" md4>
                  <v-layout justify-center align-center>
                    <v-btn
                      v-ripple="true"
                      @click="createNotify('failure')"
                      :disabled="checkNotifies"
                      text
                      class="monitoring__btn monitoring__btn--failure"
                    >
                      {{ $t('monitoring.failure') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
                <v-flex style="padding: 0 3%" md4>
                  <v-layout justify-center align-center>
                    <v-btn
                      v-ripple="true"
                      @click="createNotify('alarm')"
                      :disabled="checkNotifies"
                      text
                      class="monitoring__btn monitoring__btn--alarm"
                    >
                      {{ $t('monitoring.alarm') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm12 md6 lg3 v-for="item in postLinks" :key="item.id" class="px-4 py-3">
        <v-card
          flat
          :class="[['warning', 'error'].includes(item.className) ? 'card__active--size' : '']"
          class="card__transform black--text"
          style="background-color: #EDF2F4; box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.3); border-radius: 5px; font-size: 18px; min-height: 90px"
          active-class="card__active--size card__active--outline"
          :to="`/monitoring/sensor_${item.id}`"
          height="100%"
          :data-el="item.id"
          @click="getActiveNotify(item)"
        >
          <v-layout column fill-height>
            <v-layout class="px-2 py-1">
              <v-flex xs11>
                <v-layout style="min-height: 54px;">
                  <v-flex xs2>
                    <v-layout align-center justify-center fill-height>
                      <v-icon
                        v-if="item.jdata.icon"
                        v-text="`$vuetify.icons.monitoring_systems_${item.jdata.icon}`"
                        style="width: 35px; height: 35px;"
                        :class="[item.value === '0' ? 'red--text' : 'main_color--text']"
                      ></v-icon>
                    </v-layout>
                  </v-flex>
                  <v-flex xs10>
                    <v-layout align-center fill-height class="px-2">
                      <p style="z-index: 5; color: #4B4A4A; line-height: 18px" class="mb-0">
                        {{ item.name }}
                      </p>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout
                  class="pl-1"
                  v-if="item.className === 'warning'"
                  style="color: #ED5F55; line-height: 16px; font-size: 16px"
                >
                  <span>{{ $t('monitoring.needToFix') }}: {{ item.timer || '' }}</span>
                </v-layout>
              </v-flex>
              <v-flex xs1>
                <v-layout column align-end>
                  <v-tooltip top>
                    <template v-slot:activator="{on}">
                      <v-btn text small icon class="ma-1" v-on="on" @click="showInfoWindow(item)">
                        <v-icon v-text="'$vuetify.icons.monitoring_info'" color="#A0A4A7"></v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('monitoring.orientation') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="auth.acc_role === 'admin'">
                    <template v-slot:activator="{on}">
                      <v-btn text small icon class="ma-1" v-on="on" @click="editLink(item)">
                        <v-icon
                          v-text="'$vuetify.icons.monitoring_settings'"
                          color="#A0A4A7"
                        ></v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('monitoring.settings') }}</span>
                  </v-tooltip>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-flex
              :style="item.className === 'warning' ? 'background: #EF8614' : 'background: #02895D'"
              style="position: relative; max-height: 20px; height: 20px; min-height: 20px; border-radius: 0 0 5px 5px; margin: 0 1px"
            >
              <v-layout
                class="card__overlay"
                style="position: absolute; background-color: #ff5252; height: calc(100% + 1px); left: 1px; bottom: 0; top: 0; transition: all 1s linear; border-radius: 0 0 5px 5px;"
              ></v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex md12 xs12 offset-xs0 class="px-3">
        <v-card v-if="error.id">
          <v-layout align-center fill-height wrap>
            <v-flex md7 xs12 fill-height>
              <v-layout fill-height wrap>
                <v-flex md4 xs12>
                  <v-layout column align-center fill-height class="py-2 monitoring__settings px-1">
                    <p>{{ $t('monitoring.name') }}</p>
                    <span>{{ error.name || 'Неизвестная система' }}</span>
                  </v-layout>
                </v-flex>
                <v-flex md4 xs12>
                  <v-layout column align-center fill-height class="py-2 monitoring__settings">
                    <p>{{ $t('monitoring.time') }}</p>
                    <span>{{ error.datepoint || '00:00' }}</span>
                  </v-layout>
                </v-flex>
                <v-flex md4 xs12>
                  <v-layout column align-center fill-height class="py-2 monitoring__settings">
                    <p>
                      {{ $t('monitoring.timeLeft') }}
                      {{ error.timer || '00:00' }}
                    </p>
                    <span>{{
                      error.type_mess === 'warning'
                        ? $t('monitoring.needToFix')
                        : $t('monitoring.timeIsOver')
                    }}</span>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md5 xs12>
              <v-layout justify-center align-center>
                <v-flex xs6>
                  <v-layout justify-center class="px-3">
                    <v-btn
                      text
                      @click="showResponseWindow()"
                      class="monitoring__btn monitoring__btn--green"
                    >
                      {{ $t('monitoring.saveReport') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6>
                  <v-layout justify-center class="px-3">
                    <v-btn
                      text
                      @click="showInfoWindow(error)"
                      class="monitoring__btn monitoring__btn--red"
                    >
                      {{ $t('monitoring.orientation') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="messages.length" max-width="480px" content-class="elevation-0" persistent>
      <v-layout class="px-2" column>
        <v-card style="background: rgba(0, 0, 0, .3)">
          <v-layout column v-for="(msg, index) in messages" :key="msg.id" class="mx-1 my-2">
            <dict-title
              :class="[msg.type_mess ? `dict__header--monitoring-${msg.type_mess}` : '']"
              :title="
                msg.type_mess === 'error' ? $t('monitoring.failure') : $t('monitoring.accident')
              "
              >ww</dict-title
            >
            <v-layout class="pt-4 monitoring__messages">
              <v-card
                class="pa-3 pt-8"
                style="width: 100%"
                :class="[msg.type_mess ? `monitoring__messages--${msg.type_mess}` : '']"
              >
                <v-flex>
                  <v-layout align-center column class="mb-2">
                    <h3>{{ msg.name }}</h3>
                  </v-layout>
                  <v-layout align-center column>
                    <h3>{{ $t('monitoring.timeOfNotify') }}:</h3>
                    <p>{{ msg.datepoint }}</p>
                  </v-layout>
                  <v-layout align-center column>
                    <h3>{{ $t('monitoring.actionsOfReglament') }}:</h3>
                    <p style="white-space: pre-wrap" v-if="msg.type_mess === 'warning'">
                      {{ getInfoMsg(msg) }}
                    </p>
                    <p style="white-space: pre-wrap" v-if="msg.type_mess === 'error'">
                      {{ getInfoMsg(msg) }}
                    </p>
                  </v-layout>
                  <v-layout align-center column class="mb-2" v-if="msg.type_mess === 'warning'">
                    <h3>{{ $t('monitoring.needToFix') }}:</h3>
                    <h3>{{ getTimerMsg(msg) }}</h3>
                  </v-layout>
                  <v-layout justify-center>
                    <v-flex md6 sm12>
                      <v-btn
                        text
                        @click="agreeNotify(msg, index)"
                        class="monitoring__btn"
                        :class="[`monitoring__btn--${msg.type_mess}`]"
                      >
                        {{ $t('monitoring.accept') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-card>
            </v-layout>
          </v-layout>
        </v-card>
      </v-layout>
    </v-dialog>
    <v-dialog v-model="showEdit" max-width="480px" content-class="elevation-0" persistent>
      <v-layout class="px-2">
        <v-layout column>
          <dict-title :title="$t('monitoring.options')"></dict-title>
          <v-layout class="pt-4">
            <v-card class="pa-3 pt-8" style="width: 100%">
              <v-layout column>
                <p>{{ $t('monitoring.name') }}</p>
                <input
                  class="input__main px-2"
                  ref="response"
                  rows="5"
                  v-model="options.name"
                  style="width: 100%; font-size: 18px"
                />
              </v-layout>
              <v-layout column>
                <p>{{ $t('monitoring.info') }}</p>
                <textarea
                  class="input__main px-2"
                  ref="response"
                  rows="5"
                  v-model="options.info"
                  style="width: 100%; font-size: 18px"
                ></textarea>
              </v-layout>
              <v-layout column>
                <p>{{ $t('monitoring.info_failure') }}</p>
                <textarea
                  class="input__main px-2"
                  ref="response"
                  rows="5"
                  v-model="options.jdata.info_failure"
                  style="width: 100%; font-size: 18px"
                ></textarea>
              </v-layout>
              <v-layout column>
                <p>{{ $t('monitoring.info_accident') }}</p>
                <textarea
                  class="input__main px-2"
                  ref="response"
                  rows="5"
                  v-model="options.jdata.info_accident"
                  style="width: 100%; font-size: 18px"
                ></textarea>
              </v-layout>
              <v-layout column>
                <p>{{ $t('monitoring.reglamentTime') }}</p>
                <date-picker
                  class="input__main"
                  v-model="options.jdata.custom_time_origin"
                  type="time"
                  lang="ru"
                  range-separator=":"
                  placeholder="Регламентное время"
                  format="HH:mm:ss"
                  confirm
                ></date-picker>
              </v-layout>
              <v-layout justify-space-between>
                <v-flex xs6 class="px-2">
                  <v-layout justify-center>
                    <v-btn
                      text
                      @click="saveEditChanges()"
                      class="monitoring__btn monitoring__btn--green"
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6 class="px-2">
                  <v-layout justify-center>
                    <v-btn
                      text
                      @click="closeWindow('showEdit')"
                      class="monitoring__btn monitoring__btn--red"
                    >
                      {{ $t('monitoring.out') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-dialog>
    <v-dialog v-model="responseWindow" max-width="480px" content-class="elevation-0" persistent>
      <v-layout class="px-2">
        <v-layout column>
          <dict-title :title="$t('monitoring.reportAboutProblem')"></dict-title>
          <v-layout class="pt-4">
            <v-card class="pa-3 pt-8" style="width: 100%">
              <v-layout column>
                <!-- <p>{{options.name}}</p> -->
                <textarea
                  class="input__main px-2"
                  ref="response"
                  rows="15"
                  v-model="responseText"
                  style="width: 100%"
                ></textarea>
              </v-layout>
              <v-layout justify-space-between>
                <v-flex xs6 class="px-2">
                  <v-layout justify-center>
                    <v-btn
                      text
                      @click="sendResponseText()"
                      class="monitoring__btn monitoring__btn--green"
                    >
                      {{ $t('monitoring.sendReport') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
                <v-flex xs6 class="px-2">
                  <v-layout justify-center>
                    <v-btn
                      text
                      @click="closeWindow('responseWindow')"
                      class="monitoring__btn monitoring__btn--red"
                    >
                      {{ $t('monitoring.out') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-dialog>
    <v-dialog v-model="infoWindow" max-width="480px" content-class="elevation-0" persistent>
      <v-layout class="px-2">
        <v-layout column>
          <dict-title :title="options.name"></dict-title>
          <v-layout class="pt-4 monitoring__href">
            <v-card class="pa-3 pt-8" style="width: 100%">
              <v-layout column>
                <v-layout align-center>
                  <v-icon v-text="'$vuetify.icons.monitoring_dot'" color="#A0A4A7"></v-icon>
                  <a target="_blank">Действия по регламенту</a>
                </v-layout>
                <v-layout align-center>
                  <v-icon v-text="'$vuetify.icons.monitoring_dot'" color="#A0A4A7"></v-icon>
                  <a target="_blank">Описание системы</a>
                </v-layout>
                <v-layout align-center>
                  <v-icon v-text="'$vuetify.icons.monitoring_dot'" color="#A0A4A7"></v-icon>
                  <a target="_blank" :href="`/docs/${options.jdata.pdf_link}.pdf`"
                    >Сигналы и способ Сопряжения со СМИС</a
                  >
                </v-layout>
              </v-layout>
              <v-layout justify-center>
                <v-flex xs6 class="px-2">
                  <v-layout justify-center>
                    <v-btn
                      text
                      @click="closeWindow('infoWindow')"
                      class="monitoring__btn monitoring__btn--red"
                    >
                      {{ $t('monitoring.out') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-dialog>
    <v-snackbar top v-model="showNotify" class="pt-10">
      <v-layout column class="px-2">
        <h2 class="mb-3 mx-auto">{{ recoveryElem.name }} {{ $t('monitoring.recovered') }}</h2>
        <v-flex>
          <v-layout justify-center>
            <v-btn
              color="blue_custom"
              text
              depressed
              @click="showNotify = false"
              class="mx-0 my-0 px-2"
            >
              {{ $t('close') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-snackbar>
  </v-flex>
</template>
<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'
import wsMonitoring from '@/mixins/wsMonitoring'
import pSocket from '@/mixins/pSocket'

import dictTitle from '@/components/custom/dicts/title'

export default {
  components: {
    DatePicker,
    dictTitle
  },

  mixins: [wsMonitoring, pSocket],

  mqtt: {
    DATA(data, topic) {
      this.setSensorValue(data.data)
    },
    TASKS(data, topic) {
      const json = JSON.parse(data.data)
      if (json) {
        this.setNotify(json)
      }
    }
  },

  data() {
    return {
      options: {
        jdata: {}
      },
      error: {
        jdata: {}
      },
      messages: [],
      responseWindow: false,
      showNotify: false,
      showEdit: false,
      showMessages: false,
      infoWindow: false,
      responseElem: {},
      recoveryElem: {},
      responseText: '',
      window: {},
      edit: {},
      timer: '',
      datePicker: '',
      terminal: {}
    }
  },
  computed: {
    postLinks() {
      const filterLink = this.getDicts('tsensors').filter(link => {
        if (!this.terminal.id) return true
        return this.terminal.id === link.id_terminal
      })

      return filterLink
    },
    postTerminals() {
      return [{name: 'Все типы систем'}].concat(this.getDicts('terminals'))
    },
    checkNotifies() {
      if (this.$route.params.sensors) {
        const findNotify = this.notificationsAll.find(
          notify => notify.id_tsensor === this.$route.params.sensors
        )
        if (findNotify) {
          return true
        }
        return false
      }

      return true
    },
    ...mapState(['auth']),
    ...mapGetters(['getDicts'])
  },

  beforeRouteLeave(to, from, next) {
    if (!/monitoring/.test(to.path)) {
      clearInterval(this.timer)
    }
    next()
  },
  created() {
    this.init()
    this.timer = setInterval(() => {
      this.checkTimers()
    }, 1000)
    this.spy()
    window.addEventListener('mousemove', this.spy)
    window.addEventListener('keyup', this.spy)

    this.$mqtt.subscribe(`TASKS`)
    this.$mqtt.subscribe(`DATA`)
  },

  methods: {
    async init() {
      const {data} = await HTTP.post('alarm', {
        date_from: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD')
      })
      const dataSorted = data.sort((a, b) => (a.datepoint > b.datepoint ? 1 : -1))

      dataSorted.forEach(notify => {
        if (notify.is_ok !== null) {
          this.addNotify(notify)

          if (notify.is_ok === false) {
            this.messages.push({
              ...notify,
              type_mess: 'warning'
            })
          }
        }
      })

      this.checkLinks()
      this.checkAmountsTroubles()
      this.initInfo()

      if (this.$route.params.sensors) {
        const findLink = this.getDicts('tsensors').find(
          link => link.id === this.$route.params.sensors
        )
        if (!findLink) return

        this.getActiveNotify(findLink)
      }

      this.getSensorsValues()
    },
    async getSensorsValues() {
      const {data} = await HTTP.post('key_redis', {
        hget: 'SENSORS'
      })
      const links = Object.entries(data)

      links.forEach(item => {
        const [terminal, sensor, addr] = item[0].split('@')
        const value = item[1]

        const findLink = this.getDicts('tsensors').find(
          link => link.id_terminal === terminal && link.id_sensor === sensor && link.addr === addr
        )
        if (findLink) {
          this.$set(findLink, 'value', value)
        }
      })
    },
    checkTimers() {
      this.getDicts('tsensors').forEach(link => {
        const findAccident = this.notificationsAll.find(notify => notify.id_tsensor === link.id)

        if (!findAccident) {
          this.$set(link, 'timer', link.jdata.custom_timer)
          this.$set(link, 'className', '')
        }

        if (findAccident) {
          const now = moment()
          const customTimer = moment(link.jdata.custom_timer, 'HH:mm:ss')
          const datepoint = moment(findAccident.datepoint).add({
            seconds: customTimer.seconds(),
            minutes: customTimer.minutes(),
            hours: customTimer.hours()
          })
          const datepoint2 = moment(findAccident.datepoint)
          const different = datepoint.diff(now)
          const time = moment(different)
            .utc()
            .format('HH:mm:ss')

          const diffForOverlay = datepoint.diff(datepoint2) - different

          document.querySelectorAll('.card__transform').forEach(node => {
            if (node.dataset.el === link.id) {
              const overlay = node.querySelector('.card__overlay')
              const percent = (diffForOverlay * 100) / datepoint.diff(datepoint2)
              if (percent < 100) {
                overlay.style.width = `${percent}%`
              } else {
                overlay.style.width = '100%'
              }
            }
          })

          if (different > 0) {
            if (findAccident.type_mess === 'error') {
              findAccident.timer = null
            } else {
              findAccident.timer = time
            }

            this.$set(link, 'className', 'warning')
            this.$set(link, 'timer', findAccident.timer)
            if (this.error.id === findAccident.id) {
              this.error = findAccident
            }
            this.$set(findAccident, 'type_mess', 'warning')

            const findMsg = this.messages.find(msg => msg.id === findAccident.id)
            if (findMsg) {
              this.$set(findMsg, 'type_mess', 'warning')
            }
          }

          if (different < 0 && findAccident.type_mess !== 'error') {
            this.$set(findAccident, 'type_mess', 'error')
            this.$set(findAccident, 'is_ok', false)
            this.$set(link, 'className', 'error')

            const findMsg = this.messages.find(msg => msg.id === findAccident.id)
            if (findMsg) {
              this.$set(findMsg, 'type_mess', 'error')
            }
          }
        } else {
          document.querySelectorAll('.card__transform').forEach(node => {
            if (node.dataset.el === link.id) {
              node.querySelector('.card__overlay').style.width = 0
            }
          })
        }
      })

      this.checkAmountsTroubles()
      this.$forceUpdate()
    },
    spy() {
      if (this.spyTimeout) {
        clearTimeout(this.spyTimeout)
      }

      this.spyTimeout = setTimeout(() => {
        // this.putNotifications({
        //   id: 'f684abc5-ed4a-49ee-b7ac-d63ccfa08d3a',
        //   type_mess: 'warning',
        //   mess: 'Сотрудник не на месте'
        // })
        // this.showNotifications = true
      }, 900000)
    },
    initInfo() {
      const findLink = this.getDicts('tsensors').find(
        sensor => sensor.id === this.$route.params.sensors
      )
      if (findLink) {
        const findNotify = this.notificationsAll.find(notify => notify.id_tsensor === findLink.id)
        this.options = findLink
        if (!findNotify) return
        this.error = findNotify
      }
    },
    agreeNotify(elem, index) {
      const obj = {...elem}

      HTTP.put('alarm?update', {
        id: obj.id,
        is_ok: true
      }).then(response => {
        this.messages.splice(index, 1)
        this.putNotify({
          ...elem,
          is_ok: true
        })
      })
    },
    showResponseWindow() {
      this.responseWindow = true
      this.responseElem = this.error
      this.window.name = this.error.name
      this.window.mess = this.error.mess
      this.responseText = this.error.mess

      setTimeout(() => {
        this.$refs.response.focus()
      }, 500)
    },
    showInfoWindow(link) {
      this.responseElem = {
        ...link
      }
      this.infoWindow = true
    },
    editLink(link) {
      this.showEdit = true
      this.edit.name = link.name
      this.datePicker = link.jdata.custom_time_origin
    },
    saveEditChanges() {
      const obj = {
        ...this.options,
        jdata: {
          ...this.options.jdata,
          custom_timer: moment(this.options.jdata.custom_time_origin).format('HH:mm:ss')
        }
      }
      HTTP.put('tsensors', obj).then(response => {
        this.$store.commit('PUT_ITEM', obj)
        this.datePicker = ''
        this.showEdit = false
      })
    },
    sendResponseText() {
      const obj = {
        id_alarm: this.responseElem.id,
        id_user: this.auth.id,
        message: this.responseText,
        type_mess: this.responseElem.type_mess
      }

      this.responseWindow = false
      this.responseText = ''

      HTTP.put('response', obj)
        .then(response => {
          this.messages.forEach((message, index) => {
            if (message.id === this.responseElem.id) this.messages.splice(index, 1)
          })

          this.delNotify(this.responseElem)
          this.checkColorLink()

          this.error = {}

          return HTTP.put('alarm?update', {
            id: this.responseElem.id,
            is_ok: null
          })
        })
        .catch(err => {
          console.error(err, err.message)
        })
    },
    checkAmountsTroubles() {
      const systems = this.getDicts('tsensors').length
      this.$store.commit('UPDATE_AMOUNT_TROUBLES', {
        value: systems,
        type: 'systems'
      })
      const accidents = this.notificationsAll.filter(notify => notify.type_mess === 'warning')
        .length
      this.$store.commit('UPDATE_AMOUNT_TROUBLES', {
        value: accidents,
        type: 'accidents'
      })
      const failures = this.notificationsAll.filter(notify => notify.type_mess === 'error').length
      this.$store.commit('UPDATE_AMOUNT_TROUBLES', {
        value: failures,
        type: 'failures'
      })
    },
    getActiveNotify(item) {
      const findNotify = this.notificationsAll.find(notify => item.id === notify.id_tsensor)
      if (!findNotify) {
        this.error = {}
      } else {
        this.error = {...findNotify}
      }

      this.options = {...item}
    },
    closeWindow(dialog) {
      this[dialog] = false
    },
    getInfoMsg(msg) {
      const findLink = this.getDicts('tsensors').find(link => link.id === msg.id_tsensor)
      if (!findLink) return ''

      if (msg.type_mess === 'warning') {
        return findLink.jdata.info_accident
      }
      if (msg.type_mess === 'error') {
        return findLink.jdata.info_failure
      }

      return ''
    },
    getTimerMsg(msg) {
      const findLink = this.getDicts('tsensors').find(link => link.id === msg.id_tsensor)
      if (!findLink) return null
      return findLink.timer
    },
    async createNotify(name) {
      const findLink = this.getDicts('tsensors').find(
        link => link.id === this.$route.params.sensors
      )
      if (!findLink) return

      const notify_obj = {
        id_tsensor: this.$route.params.sensors,
        name: `${findLink.name} (${this.$t(`monitoring.${name}`)})`,
        is_ok: false,
        datepoint: moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss'),
        local_time: moment().format('YYYY-MM-DDTHH:mm:ss')
      }

      const findNotify = this.notificationsAll.find(
        notify => notify.id_tsensor === this.$route.params.sensors
      )
      if (!findNotify) {
        const {data} = await HTTP.put('alarm', notify_obj)
        if (!data) return

        this.pushNotify({
          ...notify_obj,
          id: data.id
        })
      }
    }
  }
}
</script>
