import moment from 'moment'
import {parseLatlon, stringifyLatlon} from './leaflet'

const formatDate = (date, format = 'L') => {
  if (!date) return null

  return moment(date).format(format)
}

const toLocalDate = date => {
  if (!date) return null

  return moment(date)
    .local()
    .format()
}

export {parseLatlon, stringifyLatlon, formatDate, toLocalDate}
