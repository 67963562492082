<template>
  <v-flex>
    <v-layout justify-center fill-height align-center>
      <v-flex md4 xs12>
        <dict-title :title="$t('component.cabinet')"></dict-title>

        <!-- Профиль -->
        <v-card style="padding-top: 57px; margin-top: 14px">
          <v-tabs
            v-model="tabs.value"
            background-color="#F0EEEE"
            color="main_color"
            class="fill-height"
          >
            <v-tabs-slider color="main_color"></v-tabs-slider>
            <v-tab
              v-for="n in ['enter']"
              :key="n"
              ripple
              style="text-transform: none; font-size: 11px; font-weight: 400; color: #a0a4a7"
            >
              {{ $t(`login.${[n]}`) }}
            </v-tab>
            <v-tab-item :transition="false" :reverse-transition="false">
              <v-card class="py-4 fill-height" elevation="0">
                <!-- <section id="firebaseui-auth-container"></section> -->
                <v-flex xs6>
                  <v-layout column class="px-3 mb-3">
                    <div>
                      <p class="master__title">{{ $t('cabinet.phone') }}</p>
                      <v-text-field
                        id="login"
                        name="login"
                        @keyup.enter="authIn()"
                        v-mask="'+7##########'"
                        solo
                        single-line
                        flat
                        class="input__options"
                        v-model="auth.phone"
                      ></v-text-field>
                    </div>
                    <div v-if="auth.type === 'phone'" id="recaptcha-container"></div>
                    <div v-if="auth.type === 'password'">
                      <p class="master__title">{{ $t('cabinet.password') }}</p>
                      <v-text-field
                        @keyup.enter="authIn()"
                        solo
                        single-line
                        flat
                        class="input__options"
                        v-model="auth.password"
                      ></v-text-field>
                    </div>
                  </v-layout>
                </v-flex>
                <v-layout align-center column>
                  <v-btn
                    v-if="auth.type === 'phone'"
                    small
                    @click="login()"
                    depressed
                    class="btn__company btn__save mx-0 my-0"
                  >
                    {{ $t('login.logIn') }}
                  </v-btn>
                  <v-btn
                    v-if="auth.type === 'password'"
                    small
                    @click="submitPhoneNumberAuthCode"
                    depressed
                    class="btn__company btn__save mx-0 my-0"
                  >
                    {{ $t('login.logIn') }}
                  </v-btn>
                  <v-fade-transition>
                    <span v-if="err.showMsg" class="red_color--text">{{ $t('login.errMsg') }}</span>
                  </v-fade-transition>
                </v-layout>
              </v-card>
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false">
              <v-card class="py-3" elevation="0">
                <v-flex xs6>
                  <v-layout column class="px-3 mb-3">
                    <p class="master__title">{{ $t('cabinet.phone') }}</p>
                    <v-text-field
                      v-mask="'+7##########'"
                      solo
                      single-line
                      flat
                      class="input__options"
                      v-model="reg.phone"
                    ></v-text-field>
                  </v-layout>
                </v-flex>
                <v-layout align-center column>
                  <v-btn
                    small
                    @click="recoverAccount()"
                    depressed
                    class="btn__company btn__save mx-0 my-0"
                  >
                    {{ $t('login.recovery_account') }}
                  </v-btn>
                </v-layout>
              </v-card>
            </v-tab-item>

            <v-tab-item :transition="false" :reverse-transition="false">
              <v-card class="py-3" elevation="0">
                <v-flex xs8 class="px-3 mb-3">
                  <div class="d-flex align-center">
                    <v-flex xs10>
                      <p class="master__title">{{ $t('cabinet.company') }}</p>
                      <v-input class="input__main px-2" height="25" hide-details>
                        <input type="text" v-model="reg.company" style="width: 100%" />
                      </v-input>
                    </v-flex>
                    <v-flex> </v-flex>
                  </div>
                  <div class="d-flex align-end">
                    <v-flex xs10>
                      <p class="master__title">{{ $t('cabinet.phone') }}</p>
                      <v-input class="input__main px-2" height="25" hide-details>
                        <input
                          v-mask="'+7##########'"
                          type="text"
                          v-model="reg.phone"
                          style="width: 100%"
                        />
                      </v-input>
                    </v-flex>
                    <v-flex xs2>
                      <v-btn icon class="main_color--text mb-3 ml-2" @click="recoverAccount">
                        <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
                      </v-btn>
                    </v-flex>
                  </div>
                  <div class="d-flex align-end">
                    <v-flex xs10>
                      <p class="master__title">{{ $t('cabinet.password') }}</p>
                      <v-input class="input__main px-2" height="25" hide-details>
                        <input type="password" v-model="reg.password" style="width: 100%" />
                      </v-input>
                    </v-flex>
                    <v-flex xs2>
                      <!-- <v-btn icon class="main_color--text mb-3 ml-2">
                        <v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
                      </v-btn> -->
                    </v-flex>
                  </div>
                  <div class="d-flex align-center">
                    <v-flex xs10>
                      <p class="master__title">{{ $t('cabinet.module') }}</p>
                      <v-input class="input__main px-2" height="25" hide-details>
                        <custom-select
                          v-model="reg.type_module"
                          :items="getModules"
                          item-text="label"
                          item-value="key"
                          default-value
                        ></custom-select>
                      </v-input>
                    </v-flex>
                    <v-flex> </v-flex>
                  </div>
                </v-flex>

                <v-layout align-center column>
                  <v-btn
                    small
                    @click="createUser()"
                    depressed
                    class="btn__company btn__save mx-0 my-0"
                  >
                    {{ $t('login.registration') }}
                  </v-btn>
                </v-layout>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import HTTP from '@/api/http'

import customSelect from '@/components/custom/custom-select'
import dictTitle from '@/components/custom/dicts/title'

import {mask} from 'vue-the-mask'

// import firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import * as firebaseui from 'firebaseui'

export default {
  directives: {
    mask,
  },

  components: {
    customSelect,
    dictTitle,
  },

  data() {
    return {
      tabs: {
        value: 0,
        params: ['', 'recovery', 'create'],
      },
      auth: {
        type: 'phone',
      },
      reg: {},
      err: {
        showMsg: false,
      },
      modules: ['acs', 'smis', 'hmg', 'auto', 'eco'],
      recaptchaVerifier: null,
      confirmationResult: null,
    }
  },
  computed: {
    getModules() {
      return this.modules.map((module) => ({
        key: module,
        label: this.$t(`menu.${module}`),
      }))
    },
  },

  watch: {
    'tabs.value': function (index) {
      const value = this.tabs.params[index]
      if (value) {
        this.$router.push(`/auth?auth=${value}`)
      } else {
        this.$router.push('/auth')
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'normal',
        callback(response) {
          this.submitPhoneNumberAuth()
          this.auth.type = 'password'
        },
      })
    })
  },

  methods: {
    async submitPhoneNumberAuth() {
      const {phone} = this.auth
      const appVerifier = this.recaptchaVerifier
      const confirmationResult = await firebase.auth().signInWithPhoneNumber(phone, appVerifier)
      this.confirmationResult = confirmationResult
      this.auth.type = 'password'
    },
    async submitPhoneNumberAuthCode() {
      const {password} = this.auth
      const result = await this.confirmationResult.confirm(password)
      const token = await result.user.getIdToken()
      localStorage.setItem('token', token)
      this.$router.push('/')
    },
    async recoverAccount() {
      const {data} = await HTTP.post('reg', {
        phone: this.reg.phone,
      })
    },
    async createUser() {
      await HTTP.post('reg', this.reg)
      this.auth.login = this.reg.login
      this.auth.password = this.reg.password
      this.$router.push('/')
    },
    login() {
      localStorage.setItem(
        'token',
        '$5$rounds=535000$OuXyjcKrgysXFlFY$JGuOBoA46k2KgymcXHhZc3ph.HfcCYyb4yUnMxEXZHD'
      )
      this.$router.push('/')
      // keycloak.login()
    },
  },
}
</script>
