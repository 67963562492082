<template>
  <v-layout justify-center align-center fill-height class="stream">
    <img :src="`http://${location.host}/rtsp/${options.name}`" alt="" class="train__img" />
    <canvas height="720" width="1280" style="position: absolute; cursor: crosshair"></canvas>
  </v-layout>
</template>

<script>
export default {
  props: {
    component: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      last_mousex: 0,
      last_mousey: 0,
      mousex: 0,
      mousey: 0,
      mousedown: false,
      ctx: null,
      location
    }
  },

  watch: {
    'options.name': function(value) {
      this.clearRect()
      this.strokeRect()
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      const img = document.querySelector('.train__img')
      const {width, height} = getComputedStyle(img)

      const canvas = document.querySelector('canvas')
      canvas.onmousedown = this.canvasMouseDown
      canvas.onmouseup = this.canvasMouseUp
      canvas.onmousemove = this.canvasMouseMove
      this.ctx = canvas.getContext('2d')
      this.ctx.strokeStyle = 'yellow'
      this.ctx.lineWidth = 2
      this.strokeRect()
    },
    canvasMouseDown(e) {
      this.last_mousex = e.layerX
      this.last_mousey = e.layerY
      this.clearRect()
      this.mousedown = true
    },
    canvasMouseUp(e) {
      const {layerX, layerY} = e
      // console.log([this.last_mousex, this.last_mousey], [layerX, layerY])
      const [xmin, xmax] = [Math.min(this.last_mousex, layerX), Math.max(this.last_mousex, layerX)]
      const [ymin, ymax] = [Math.min(this.last_mousey, layerY), Math.max(this.last_mousey, layerY)]
      console.log([xmin, xmax], [ymin, ymax])
      this.options.jdata = {
        ...this.options.jdata,
        xmin,
        xmax,
        ymin,
        ymax
      }
      this.mousedown = false
    },
    canvasMouseMove(e) {
      const canvas = document.querySelector('canvas')
      this.mousex = e.layerX
      this.mousey = e.layerY
      if (this.mousedown) {
        this.clearRect()
        // this.ctx.beginPath()
        const width = this.mousex - this.last_mousex
        const height = this.mousey - this.last_mousey
        this.ctx.strokeRect(this.last_mousex, this.last_mousey, width, height)
        // this.ctx.stroke()
      }
    },
    clearRect() {
      const canvas = document.querySelector('canvas')
      this.ctx.clearRect(0, 0, canvas.width, canvas.height)
    },
    strokeRect() {
      const {xmin, xmax, ymin, ymax} = this.options.jdata
      this.ctx.strokeRect(xmin, ymin, xmax, ymax)
    }
  }
}
</script>

<style scoped lang="sass">
.train__img
  width: 1280px
  height: 720px
</style>
