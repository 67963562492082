// import firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const config = {
  apiKey: 'AIzaSyBuTBcaDEFv3a2itJeYgDu-YLL0IHmwpAI',
  authDomain: 'skif-on-63500.firebaseapp.com',
  databaseURL: 'https://skif-on-63500.firebaseio.com',
  projectId: 'skif-on-63500',
  storageBucket: 'skif-on-63500.appspot.com',
  messagingSenderId: '562760485875',
  appId: '1:562760485875:web:12c613bf61577f4402a086',
  measurementId: 'G-XYHZQ2LZGX',
}

export const db = firebase.initializeApp(config).firestore()

const {Timestamp, GeoPoint} = firebase.firestore
export {Timestamp, GeoPoint}
