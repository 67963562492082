export default {
  ru_ru: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Строк на странице',
        pageText: '{0}-{1} из {2}',
      },
      dataTable: {
        rowsPerPageText: 'Строк на странице:',
        noDataText: 'Нет данных',
      },
      noDataText: 'Нет данных',
    },
    menu: {
      handbooks: 'Настройки',
      accounting: 'Отчетность',
      monitoring: 'Мониторинг',
      consumption: 'Потребление',
      tax: 'Мониторинг',
      meteo: 'Метеозоны',
      statistics: 'Статистика',
      weather: 'Погода',
      simcir: 'Схема',
      smis: 'СМИС',
      acs: 'СКД',
      hmg: 'ЖКХ',
      eco: 'ЭкоСорт',
      auto: 'АВТО',
      barrier: 'Шлагбаум',
      agro: 'АГРО',
      skifme: 'Skifme',
      map: 'Карта',
      gantt: 'Гантт',
    },
    component: {
      hmg: {
        persons: 'Сотрудники',
        geometrys: 'Поля',
        plans: 'Техплан',
        works: 'Работы',
        stocks: 'Склад',
        products: 'Товары',
      },
      acs: {},
      cabinet: 'Личный кабинет',
      persons: 'Жители',
      cards: 'Пропуска',
      groups: 'Зоны контроля',
      units: 'Объекты',
      terminals: 'Терминалы',
      sensors: 'Шаблоны датчиков',
      tsensors: 'Датчики',
      templates: 'Шаблоны отчетов',
      rtables: 'Отчеты',
      notify_templ: 'Шаблоны уведомлений',
      control: 'Контроль посещений',
      devices: 'Схема',
      impdata: 'Импорт данных',
      tasks: 'Задачи',
      schedules: 'Расписания',
      intervals: 'Интервалы',
      access: 'Аккаунты',
      flats: 'Квартиры',
      maps: 'Карты',
      geometrys: 'Геометрия',
      cams: 'Камеры',
      plans: 'Техпланы',
      works: 'Работы',
      stocks: 'Склад',
      products: 'Товары',
      train: 'Обучение',
      commands: 'Команды',
    },
    statistics: {
      season: 'Сезон',
      stock: 'Склад',
      structure: 'Структура посевов',
      weather: 'Погода',
      expense: 'Расходы хозяйств',
      completed_jobs: 'Выполненные работы',
      fields: 'Поля',
      persons: 'Сотрудники',
    },
    weather: {
      temperature: 'Температура',
      humidity: 'Влажность',
      pressure: 'Давление',
      precipitation: 'Осадки',
    },
    consumption: {
      name: 'Имя',
      surname: 'Фамилия',
      street: 'Улица',
      apartment: 'Квартира',
      water: 'Вода',
      electr: 'Электричество',
      gas: 'Газ',
      compare: 'Сравнить',
    },
    login: {
      enter: 'Вход',
      registration: 'Создать Модуль контроля',
      logIn: 'Войти',
      logout: 'Выйти',
      errMsg: 'Ошибка входа',
      recovery: 'Восстановление',
      recovery_account: 'Выслать пароль',
    },
    cabinet: {
      phone: 'Телефон',
      user_role: 'Роль',
      login: 'Логин',
      language: 'Язык',
      timezone: 'Часовой пояс',
      username: 'Имя сотрудника',
      password: 'Пароль',
      profile: 'Профиль',
      companies: 'Компании',
      company: 'Компания',
      loginOfPerson: 'Логин (сотрудника)',
      renameTheCompany: 'Переименовать компанию',
      personsOfCompany: 'Пользователи компании',
      acc_role: 'Роль в компании',
      email: 'Почта',
      invite: 'Пригласить',
      rename: 'Переименовать',
      name: 'Название',
      nickname: 'Имя',
      module: 'Модуль',
      user: 'Пользователь',
      flat: 'Квартира',
      createModule: 'Создать модуль',
    },
    cards: {
      employee: 'Сотрудник',
      numbercard: 'Номер пропуска',
      freeCards: 'Свободные пропуска',
      busyCards: 'Занятые пропуска',
      person: 'Житель',
    },
    master: {
      title: 'Мастер создания',
      main: 'Главная',
      addToUnits: 'Добавить для объекта',
      addToTerminals: 'Добавить для терминала',
      addToTemplates: 'Добавить для шаблона',
      createElem: 'Создать элемент',
      addElem: 'Добавить элемент',
      timeFrom: 'Начало',
      timeTo: 'Конец',
      typeOfTerminal: 'Тип терминала',
      typeOfSensor: 'Тип датчика',
      typeOfTable: 'Тип таблицы',
      typeOfNotify: 'Тип уведомления',
      typeOfDevice: 'Тип оборудования',
      typeOfTask: 'Тип задачи',
      typeOfSchedule: 'Тип расписания',
      block: 'Заблокирован',
      status: 'Статус',
      flats: {
        name: 'Квартира',
        is_block: 'Заблокирован',
      },
      plans: {
        name: 'Название',
        type_plan: 'Тип техплана',
        id_geometry: 'Поле',
        date_to: 'Действие до',
      },
      works: {
        name: 'Название',
        type_work: 'Тип работы',
        type_stock: 'Тип склада',
        id_person: 'Сотрудник',
        id_geometry: 'Поле',
        id_product: 'Название товара',
        id_stock: 'Склад',
        id_work: 'Работа',
        date_from: 'Начало',
        duration: 'Длительность',
        tcount: 'Количество',
      },
      stocks: {
        name: 'Название',
        type_stock: 'Тип склада',
        id_product: 'Товар',
        tcount: 'Количество',
        price: 'Стоимость',
        date_price: 'Дата закупки',
        id_work: 'Работа',
        products_on_stock: 'Поступившие товары',
        products_out_stock: 'Товары в работе',
        is_close: 'Закрыт',
      },
      products: {
        name: 'Название',
        price: 'Стоимость',
        type_product: 'Тип товара',
      },
      persons: {
        name: 'Имя жителя',
        id_user: 'Аккаунт',
        id_flat: 'Квартира',
        type_person: 'Тип пользователя',
        is_block: 'Заблокирован',
      },
      units: {
        name: 'Название',
        temperature: 'Температура',
        humidity: 'Влажность',
        pressure: 'Давление',
        precipitation: 'Осадки',
        addr: 'Адрес',
        type_unit: 'Тип',
        id_geometry: 'Геометрия',
      },
      cards: {
        name: 'Название',
        numcard: 'Номер пропуска',
        id_person: 'Сотрудник',
        destination: 'Назначение',
        gosnumber: 'Номер автомобиля',
        telnumber: 'Номер телефона',
        date_from: 'Дата',
        type_card: 'Пропуск',
      },
      devices: {
        name: 'Название',
        column_name: 'Название колонки',
        addr: 'Адрес',
        type_sensor: 'Тип датчика',
      },
      groups: {
        name: 'Название',
        type_group: 'Тип группы',
        time_parking: 'Время стоянки',
        id_geometry: 'Геометрия',
      },
      tasks: {
        name: 'Название',
        ip: 'IP-Адрес',
        port: 'Порт',
        is_ok: 'Подтверждать',
        is_email: 'Отправлять на почту',
        email: 'Почта',
        email_subject: 'Тема письма',
        email_message: 'Сообщение',
        is_sms: 'Отправлять СМС',
        telnumber: 'Номер телефона',
        sms_message: 'Сообщение',
        type_task: 'Тип задачи',
        is_save: 'Сохранять',
        emails: 'Почта',
      },
      access: {
        name: 'Название',
        user_name: 'Имя',
        user_login: 'Логин',
        acc_role: 'Роль',
        email: 'Почта',
        password: 'Пароль',
        id_flat: 'Квартира',
        id_person: 'Житель',
      },
      geometrys: {
        name: 'Название',
        latlon: 'Координаты',
        type_geometry: 'Тип',
        id_map: 'Карта',
        type_gate: 'Тип ворот',
      },
      cams: {
        name: 'Название',
        url: 'Ссылка',
        id_map: 'Карта',
        latlon: 'Координаты',
        id_geometry: 'Геометрия',
        type_cam: 'Тип камеры',
      },
      terminals: {
        name: 'Название',
        imei: 'imei',
        is_notify: 'Получать уведомления',
        type_terminal: 'Название таблицы',
        id_unit: 'Объект',
      },
      tsensors: {
        name: 'Название',
        cams: 'Камера',
        id_geometry: 'Геометрия',
        id_terminal: 'Терминал',
        id_sensor: 'Датчик',
        addr: 'Адрес',
        info: 'Инфо',
        is_notify: 'Получать уведомления',
      },
      schedules: {
        name: 'Название',
        schedule: 'Расписание',
        is_save: 'Сохранять',
        date_from: 'Начало',
        date_to: 'Конец',
      },
      sensors: {
        name: 'Название',
        type_sensor: 'Тип',
        formula: 'Формула',
        filter: 'Фильтр',
        type_terminal: 'Название таблицы',
        description: 'Описание',
        terminal_column: 'Параметр',
      },
      notify_templ: {
        formula: 'Формула',
      },
      rtables: {
        name: 'Название',
        type_report: 'Тип',
        type_dict: 'Тип справочника',
        type_group: 'Тип группы',
        show_itog: 'Показывать итог',
        id_template: 'Шаблон таблицы',
      },
      maps: {
        name: 'Название',
        type_map: 'Тип',
      },
      name: 'Название',
      imei: 'Идентификационный номер',
      type: 'Тип',
      addr: 'Адрес',
      terminal: 'Терминал',
      sensor: 'Датчик',
      icon: 'Иконка',
    },
    rtables: {
      template: 'Шаблон',
      rtable: 'Таблица',
      timeFrom: 'От начала дня',
      timeTo: 'До начала дня',
      cancel: 'Отмена',
      ok: 'Подтвердить',
      update: 'Обновить',
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      quarter: 'Квартал',
      year: 'Год',
      graph: 'График',
      save_report: 'Сохранить отчет',
      total: 'Итог',
    },
    terminals: {
      online: '(Онлайн)',
      offline: '(Оффлайн)',
      in: 'Вход',
      out: 'Выход',
      subscribe: 'Подписаться',
      journal: 'Журнал',
      unit: 'Объект',
      debug: 'Отладка',
    },
    tsensors: {
      is_in: {
        true: 'Вход',
        false: 'Выход',
        undefined: 'Выход',
      },
    },
    monitoring: {
      name: 'Название',
      edit: 'Редактировать',
      settings: 'Настройки',
      terminal: 'Терминал',
      activeItems: 'Аварии',
      needToFix: 'До перехода в аварию',
      waitForDecision: 'Ожидает решения',
      hasDecided: 'Решено',
      details: 'Подробнее',
      noActiveItems: 'Аварий нет',
      accident: 'Инцидент',
      problem: 'Авария',
      message: 'Сообщение',
      notify: 'Уведомление',
      typeOfProblem: 'Тип проблемы',
      error: 'Ошибка',
      warning: 'Предупреждение',
      accept: 'Принять',
      reportAboutProblem: 'Отчет о решении проблемы',
      description: 'Описание на сервере',
      saveReport: 'Сохранить отчёт',
      sendReport: 'Отправить',
      everythingIsGood: 'Все отлично',
      recovered: '(Восстановлено)',
      timeIsOver: 'Время вышло',
      time: 'Время возникновения',
      timeOfNotify: 'Время события',
      timeLeft: 'Осталось',
      orientation: 'Инструкции',
      out: 'Выйти',
      failure: 'Авария',
      alarm: 'Тревога',
      fire: 'Пожар',
      info: 'Описание системы',
      info_failure: 'Инструкции для аварии',
      info_accident: 'Инструкции для инцидента',
      reglamentTime: 'Регламентное время',
      options: 'Настройки',
      actionsOfReglament: 'Действия по регламенту',
    },
    history: {
      title: 'История',
    },
    debug: {
      title: 'Отладчик',
      clearTable: 'Очистить таблицу',
    },
    gantt: {
      title: 'Диаграмма Гантта',
    },
    main: {
      form: 'Форма',
      forms: 'Настройки',
      receiveNotifies: 'Получать уведомления',
      childrenElements: 'Дочерние элементы',
      linksElements: 'Связанные элементы',
      employees: 'Жители',
      card: 'Пропуск',
      group: 'Группа',
      unit: 'Объект',
      terminal: 'Терминал',
      sensor: 'Шаблон датчика',
      template: 'Шаблон',
      rtable: 'Таблица',
      tasks: 'Задача',
      schedules: 'Расписание',
      untieCard: 'Отвязать пропуск',
      tieCard: 'Привязать пропуск',
      persons_cards: 'Пропуска сотрудника',
      groups_persons: 'Жители группы',
      typeOfTerminal: 'Тип терминала',
      restOfEmployees: 'Остальные жители',
      groups_units: 'Объекты группы',
      restOfUnits: 'Остальные объекты',
      units_terminals: 'Терминалы объекта',
      tsensors: 'Датчики терминала',
      templates_rtables: 'Таблицы шаблона',
      saveToTheTable: 'Сохранять в таблицу',
      controlOfConfirmations: 'Контроль подтверждений',
      typeOfNotifies: 'Тип уведомлений',
      typeOfMessages: 'Тип сообщений',
      templates_sensors: 'Сенсоры шаблона',
      terminals_devices: 'Доступное оборудование',
      restOfDevices: 'Остальное оборудование',
      sensors_notify_templ: 'Уведомления датчика',
      descriptionForSensor: 'Описание для датчика',
      descriptionForLink: 'Описание для связи',
      descriptionForAccident: 'Инструкции для инцидента',
      descriptionForFailure: 'Инструкции для аварии',
      removeFromGroup: 'Убрать из группы',
      addToGroup: 'Добавить в группу',
      removeFromTerminal: 'Убрать из терминала',
      columnsOfTypeTerminal: 'Параметры терминала',
      removeFromColumn: 'Убрать из колонки',
      thereIsDeviceInColumn: 'Это оборудование уже присутствует в колонке',
      addToColumn: 'Добавить в колонку',
      addToTerminal: 'Добавить в терминал',
      datepoint: 'Последняя активность',
      typeOfNotify: 'Тип уведомления',
      notify_templ_tasks: 'Задачи уведомлений',
      schedules_tasks: 'Задачи расписания',
      terminalLink: 'Терминал',
      sensorLink: 'Датчик',
      info: 'Описание',
      pdf_link: 'Ссылка на PDF',
      flats_persons: 'Жители',
      flats_access: 'Аккаунты',
      groups_cards: 'Пропуска зоны',
      groups_tsensors: 'Датчики зоны',
      maps_cams: 'Камеры',
      activeItems: 'Добавленные',
      restItems: 'Недобавленные',
    },
    options: {
      employees: 'Сотрудник',
      groups: 'Группа',
      units: 'Объект',
      terminals: 'Терминал',
      tsensors: 'Датчик',
      sensors: 'Шаблон датчика',
      devices: 'Оборудование',
      templates: 'Шаблон таблицы',
      rtables: 'Таблица',
      notify_templ: 'Шаблон уведомления',
      schedules: 'Расписание',
      tasks: 'Задача',
      commands: {
        phones: 'Телефоны',
      },
      formulae: {
        formula: 'Формула',
        condition: 'Условие',
      },
      access: {
        user_name: 'Никнейм',
        user_login: 'Логин',
        acc_role: 'Роль',
      },
    },
    list: {
      groups: 'Список зон контроля',
      cards: 'Список пропусков',
      notify_templ: 'Список уведомлений',
      persons: 'Список жителей',
      sensors: 'Список шаблонов датчиков',
      rtables: 'Список отчетов',
      templates: 'Список шаблонов',
      terminals: 'Список терминалов',
      tsensors: 'Список датчиков',
      units: 'Список объектов',
      control: 'Список пользователей',
      devices: 'Список оборудования',
      impdata: 'Список импортных данных',
      tasks: 'Список задач',
      schedules: 'Список расписаний',
      access: 'Список аккаунтов',
      flats: 'Список квартир',
      cams: 'Список камер',
      geometrys: 'Список геометрий',
      maps: 'Список карт',
      plans: 'Список техпланов',
      works: 'Список работ',
      products: 'Список товаров',
      stocks: 'Список складов',
    },
    rest: {
      no_rtables: 'Шаблон пуст',
    },
    info: {
      goToApplication: 'Добавить компанию',
    },
    tax: {
      territory: 'На территории',
      out: 'Выехали',
      entry: 'Номер въезда',
    },
    meteo: {
      units: 'Список объектов',
      map: 'Карта',
      month: '1 мес.',
      quarter: '3 мес.',
      half_year: '6 мес.',
    },
    search: 'Поиск',
    add: 'Добавить',
    name: 'Название',
    save: 'Сохранить',
    invite: 'Пригласить',
    rename: 'Переименовать',
    remove: 'Удалить',
    required: '* Обязательное поле',
    type: 'Тип',
    formula: 'Параметр "X"',
    description: 'Описание',
    copy: 'Копировать',
    notFound: 'Страницы с таким адресом не существует',
    close: 'Закрыть',
    yes: 'Да',
    no: 'Нет',
    graph: 'График',
    filter: 'Фильтр',
    data_type: 'data_type',
    kind_sensor: 'kind_sensor',
    addr: 'Адрес',
    column: 'Колонка',
    column_name: 'Название колонки',
    type_terminal: 'Название таблицы',
    cancel: 'Отмена',
    move: 'Перейти',
    imei: 'Imei',
    main_menu: 'Меню',
    addToTerminal: 'Перенести в терминалы',
    period: 'Интервал',
    typeDict: 'Тип справочника',
    value: 'Значение',
    addCompany: 'Добавить модуль',
    type_interval: 'Тип интервала',
    show_itog: 'Показывать итог',
    typeGroup: 'Группировать по',
    true: 'Да',
    false: 'Нет',
    null: 'Нет',
    undefined: 'Неопределено',
    outIn: 'Выход/вход',
    create: 'Создать',
    open: 'Открыть',
    train: 'Обучение',
  },
  en_en: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Lines per page',
        pageText: '{0}-{1} from {2}',
      },
      dataTable: {
        rowsPerPageText: 'Lines per page:',
        noDataText: 'No data',
      },
      noDataText: 'No data',
    },
    menu: {
      handbooks: 'Settings',
      accounting: 'Reporting',
      monitoring: 'Monitoring',
      consumption: 'Consumption',
      tax: 'Monitoring',
      meteo: 'Meteozone',
      statistics: 'Statistics',
      weather: 'Weather',
      simcir: 'Scheme',
      smis: 'СМИС',
      acs: 'СКД',
      hmg: 'ЖКХ',
      eco: 'ЭкоСорт',
      auto: 'Auto',
      barrier: 'Barrier',
      skifme: 'Skifme',
    },
    component: {
      hmg: {
        persons: 'Employees',
        geometrys: 'Fields',
        plans: 'Technical plans',
        works: 'Works',
        stocks: 'Stocks',
        products: 'Goods',
      },
      acs: {},
      cabinet: 'Personal accounts',
      persons: 'Residents',
      cards: 'Passes',
      groups: 'Control zones',
      units: 'Objects',
      terminals: 'Terminals',
      sensors: 'Sensors templates',
      tsensors: 'Sensors',
      templates: 'Reporting templates',
      rtables: 'Reports',
      notify_templ: 'Notifications templates',
      control: 'Visit control',
      devices: 'Devices',
      impdata: 'Data import',
      tasks: 'Tasks',
      schedules: 'Schedules',
      intervals: 'Intervals',
      access: 'Access',
      flats: 'Flats',
      maps: 'Maps',
      geometrys: 'Geometry',
      cams: 'Cameras',
      plans: 'Technical plans',
      works: 'Works',
      stocks: 'Stock',
      products: 'Goods',
      train: 'Training',
      commands: 'Commands',
    },
    statistics: {
      season: 'Season',
      stock: 'Stock',
      structure: 'Crop Structure',
      weather: 'Weather',
      expense: 'Household expenses',
      completed_jobs: 'Completed works',
      fields: 'Fields',
      persons: 'Employess',
    },
    weather: {
      temperature: 'Temperature',
      humidity: 'Humidity',
      pressure: 'Pressure',
      precipitation: 'Precipitation',
    },
    consumption: {
      name: 'Name',
      surname: 'Surname',
      street: 'Street',
      apartment: 'Flat',
      water: 'Water',
      electr: 'Electricity',
      gas: 'Gas',
      compare: 'Compare',
    },
    login: {
      enter: 'Enter',
      registration: 'Create account',
      logIn: 'Login',
      logout: 'Logout',
      errMsg: 'Login failed',
      recovery: 'Recover',
      recovery_account: 'Recover account',
    },
    cabinet: {
      phone: 'Telephone',
      user_role: 'Role',
      login: 'Login',
      language: 'Language',
      timezone: 'Timezone',
      username: 'User name',
      password: 'Password',
      profile: 'Profile',
      companies: 'Companies',
      company: 'Company',
      loginOfPerson: 'Emloyees login',
      renameTheCompany: 'Rename the company',
      personsOfCompany: 'Users',
      acc_role: 'Role',
      email: 'Email',
      invite: 'Invite',
      rename: 'Rename',
      name: 'Name',
      nickname: 'Nickname',
      module: 'Module',
    },
    cards: {
      employee: 'Employees',
      numbercard: 'Card number',
      freeCards: 'Free cards',
      busyCards: 'Busy cards',
      person: 'Residents',
    },
    master: {
      title: 'Creation wizard',
      main: 'Main',
      addToUnits: 'Edd for object',
      addToTerminals: 'Edd for terminal',
      addToTemplates: 'Edd for template',
      createElem: 'Create element',
      addElem: 'Edd  element',
      timeFrom: 'Start',
      timeTo: 'End',
      typeOfTerminal: 'Type of terminal',
      typeOfSensor: 'Type of sensor',
      typeOfTable: 'Type of table',
      typeOfNotify: 'Type of notfication',
      typeOfDevice: 'Type of device',
      typeOfTask: 'Type of task',
      typeOfSchedule: 'Type of schedule',
      block: 'Blocked',
      status: 'Status',
      flats: {
        name: 'Flat',
        is_block: 'Blocked',
      },
      plans: {
        name: 'Name',
        type_plan: 'Type of technical plan',
        id_geometry: 'Field',
        date_to: 'Date to',
      },
      works: {
        name: 'Название',
        type_work: 'Type of work',
        type_stock: 'Type of stock',
        id_person: 'Employee',
        id_geometry: 'Field',
        id_product: 'Name of good',
        id_stock: 'Stock',
        id_work: 'Work',
        date_from: 'Date from',
        duration: 'Duration',
        tcount: 'Quantity',
      },
      stocks: {
        name: 'Name',
        type_stock: 'Тype of stock',
        id_product: 'Goods',
        tcount: 'Quantity',
        price: 'Price',
        date_price: 'Date of purchase',
        id_work: 'Work',
        products_on_stock: 'Goods on stock',
        products_out_stock: 'Goods out of stock',
        is_close: 'Closed',
      },
      products: {
        name: 'Name',
        price: 'Price',
        type_product: 'Type of goods',
      },
      persons: {
        name: 'name of resident',
        id_user: 'Account',
        id_flat: 'Flat',
        type_person: 'Type of user',
        is_block: 'Blocked',
      },
      units: {
        name: 'Name',
        temperature: 'Temperature',
        humidity: 'humidity',
        pressure: 'Pressure',
        precipitation: 'Precipitation',
        addr: 'Adress',
        type_unit: 'Type',
        id_geometry: 'Geometry',
      },
      cards: {
        name: 'Name',
        numcard: 'Pass number',
        id_person: 'Employee',
        destination: 'Destination',
        gosnumber: 'Car number',
        telnumber: 'Telephone number',
        date_from: 'Data',
        type_card: 'Pass',
      },
      devices: {
        name: 'Name',
        column_name: 'Column number',
        addr: 'Adress',
        type_sensor: 'Type of sensor',
      },
      groups: {
        name: 'Name',
        type_group: 'Type of group',
        time_parking: 'Parking time',
        id_geometry: 'Geometry',
      },
      tasks: {
        name: 'Name',
        ip: 'IP-adress',
        port: 'Port',
        is_ok: 'Confirm',
        is_email: 'Send email',
        email: 'Email',
        email_subject: 'Email subject',
        email_message: 'Message',
        is_sms: 'Send SMS',
        telnumber: 'Telephone number',
        sms_message: 'Message',
        type_task: 'Type of task',
        is_save: 'Save',
        emails: 'Email',
      },
      access: {
        name: 'Name',
        user_name: 'User name',
        user_login: 'Login',
        acc_role: 'Role',
        email: 'Email',
        password: 'Password',
        id_flat: 'Field',
        id_person: 'Employee',
      },
      geometrys: {
        name: 'Name',
        latlon: 'Coordinates',
        type_geometry: 'Type og geometry',
        id_map: 'Map',
        type_gate: 'Type of gate',
      },
      cams: {
        name: 'Name',
        url: 'Link',
        id_map: 'Map',
        latlon: 'Coordinates',
        id_geometry: 'Geometry',
        type_cam: 'Type of camera',
      },
      terminals: {
        name: 'Name',
        imei: 'Imail',
        is_notify: 'Receive notfications',
        type_terminal: 'Name of table',
        id_unit: 'Object',
      },
      tsensors: {
        name: 'Name',
        cams: 'Camera',
        id_geometry: 'Geometry',
        id_terminal: 'Terminal',
        id_sensor: 'Sensor',
        addr: 'Adress',
        info: 'Info',
        is_notify: 'Receive notfication',
      },
      schedules: {
        name: 'Name',
        schedule: 'Schedule',
        is_save: 'Save',
        date_from: 'Start',
        date_to: 'End',
      },
      sensors: {
        name: 'Name',
        type_sensor: 'Type',
        formula: 'Formula',
        filter: 'Filter',
        type_terminal: 'Table name',
        description: 'Description',
        terminal_column: 'Options',
      },
      notify_templ: {
        formula: 'Formula',
      },
      rtables: {
        name: 'Name',
        type_report: 'Type',
        type_dict: 'Type of directory',
        type_group: 'Type of group',
        show_itog: 'Show  total',
        id_template: 'Table template',
      },
      maps: {
        name: 'Name',
        type_map: 'Тype',
      },
      name: 'Name',
      imei: 'ID number',
      type: 'Type',
      addr: 'Adress',
      terminal: 'Terminal',
      sensor: 'Sensor',
      icon: 'Icon',
    },
    rtables: {
      template: 'Template',
      rtable: 'Table',
      timeFrom: 'Time from',
      timeTo: 'Time to',
      cancel: 'Cancel',
      ok: 'ok',
      update: 'Update',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      quarter: 'Quarter',
      year: 'Year',
      graph: 'Graph',
      save_report: 'Save report',
      total: 'Total',
    },
    terminals: {
      online: 'Online',
      offline: 'Offline',
      in: 'Enter',
      out: 'Exit',
      subscribe: 'Subscribe',
      journal: 'Journal',
      unit: 'Object',
      debug: 'Debug',
    },
    tsensors: {
      is_in: {
        true: 'Enter',
        false: 'False',
        undefined: 'Exit',
      },
    },
    monitoring: {
      name: 'Name',
      edit: 'Edit',
      settings: 'Settings',
      terminal: 'Terminal',
      activeItems: 'Accident',
      needToFix: 'Before accident',
      waitForDecision: 'Waiting for decision',
      hasDecided: 'Desided',
      details: 'Details',
      noActiveItems: 'No accident',
      accident: 'Incident',
      problem: 'Problem',
      message: 'Message',
      notify: 'Notification',
      typeOfProblem: 'Type of problem',
      error: 'Error',
      warning: 'Warning',
      accept: 'Accept',
      reportAboutProblem: 'Problem report',
      description: 'Server description',
      saveReport: 'Save report',
      sendReport: 'Send',
      everythingIsGood: 'All perfect',
      recovered: 'Recovered',
      timeIsOver: 'Time is over',
      time: 'Tme of occurrence',
      timeOfNotify: 'Event time',
      timeLeft: 'Left',
      orientation: 'Instruction',
      out: 'Exit',
      failure: 'Авария',
      alarm: 'Alarm',
      fire: 'Fire',
      info: 'System description',
      info_failure: 'Accident description',
      info_accident: 'Acident instruction',
      reglamentTime: 'Reglament time',
      options: 'Settings',
      actionsOfReglament: 'Reglament actions',
    },
    history: {
      title: 'History',
    },
    debug: {
      title: 'Debug',
      clearTable: 'Clear table',
    },
    main: {
      form: 'Form',
      forms: 'Settings',
      receiveNotifies: 'Recieve notfications',
      childrenElements: 'Subcidiary elements',
      linksElements: 'Link elements',
      employees: 'Residents',
      card: 'Pass',
      group: 'Group',
      unit: 'Object',
      terminal: 'Terminal',
      sensor: 'Sensor template',
      template: 'Template',
      rtable: 'Table',
      tasks: 'Task',
      schedules: 'Schedule',
      untieCard: 'Untie pass',
      tieCard: 'Tie pass',
      persons_cards: 'Employees passes',
      groups_persons: 'Group residents',
      typeOfTerminal: 'Terminal type',
      restOfEmployees: 'Rest of employees',
      groups_units: 'Groups of objects',
      restOfUnits: 'Rest of objects',
      units_terminals: 'Objects of terminal',
      tsensors: 'Sensors of terminal',
      templates_rtables: 'rtables of templates',
      saveToTheTable: 'Save in table',
      controlOfConfirmations: 'Control of confirmatons',
      typeOfNotifies: ' Type of notfications',
      typeOfMessages: 'Tyoe of messages',
      templates_sensors: 'Sensors template',
      terminals_devices: 'Affordable devices',
      restOfDevices: 'Rest of devices',
      sensors_notify_templ: 'Sensors notfications',
      descriptionForSensor: 'Sensors description',
      descriptionForLink: 'Description for connection',
      descriptionForAccident: 'Instruction for accident',
      descriptionForFailure: 'Instruction for incident',
      removeFromGroup: 'Delete from group',
      addToGroup: 'Add to group',
      removeFromTerminal: 'Delete from terminal',
      columnsOfTypeTerminal: 'Terminal options',
      removeFromColumn: 'Deleete from column',
      thereIsDeviceInColumn: 'This device is already in the column',
      addToColumn: 'Add to column',
      addToTerminal: 'Add to terminal',
      datepoint: 'Last activity',
      typeOfNotify: 'Type of notfications',
      notify_templ_tasks: 'Tasks template',
      schedules_tasks: 'Schedules tasks',
      terminalLink: 'Terminal',
      sensorLink: 'Sensor',
      info: 'Description',
      pdf_link: 'Link to PDF',
      flats_persons: 'Residents',
      flats_access: 'Access',
      groups_cards: 'Passes zones',
    },
    options: {
      employees: 'Employees',
      groups: 'Groups',
      units: 'Objects',
      terminals: 'Terminals',
      tsensors: 'Sensors',
      sensors: 'Subcidiary  Templates',
      devices: 'Devices',
      templates: 'Table templates',
      rtables: 'Table',
      notify_templ: 'Notifications template',
      schedules: 'Schedule',
      tasks: 'Tasks',
      commands: {
        phones: 'Phones',
      },
      formulae: {
        formula: 'formula',
        condition: 'Conditions',
      },
      access: {
        user_name: 'Nickname',
        user_login: 'Login',
        acc_role: 'Role',
      },
    },
    list: {
      groups: 'List of control zones',
      cards: 'List of passes',
      notify_templ: 'List of notfications',
      persons: 'List of residents',
      sensors: 'List of sensors templates',
      rtables: 'List of reports',
      templates: 'List of templates',
      terminals: 'List of terminals',
      tsensors: 'List of sensors',
      units: 'List of objects',
      control: 'List of users',
      devices: 'List of devices',
      impdata: 'List of import data',
      tasks: 'List of tasks',
      schedules: 'List of schedules',
      access: 'List of accounts',
      flats: 'List of flats',
      cams: 'List of cameras',
      geometrys: 'List of geometry',
      maps: 'List of passes',
      plans: 'List of technical plans',
      works: 'List of works',
      products: 'List of goods',
      stocks: 'List of stocks',
    },
    rest: {
      no_rtables: 'Template is empty',
    },
    info: {
      goToApplication: 'Add module',
    },
    tax: {
      territory: 'Within territory',
      out: 'Out',
      entry: 'Entry number',
    },
    meteo: {
      units: 'List of objects',
      map: 'Map',
      month: '1 month',
      quarter: '3 months',
      half_year: '6 months',
    },
    search: 'Search',
    add: 'Add',
    name: 'Name',
    save: 'Save',
    invite: 'Invite',
    rename: 'Rename',
    remove: 'Delete',
    required: '* Obligatory field',
    type: 'Type',
    formula: 'Option X',
    description: 'Description',
    copy: 'Copy',
    notFound: 'Pages with this address do not exist',
    close: 'Close',
    yes: 'Yes',
    no: 'No',
    graph: 'graph',
    filter: 'Filter',
    data_type: 'data_type',
    kind_sensor: 'kind_sensor',
    addr: 'Adress',
    column: 'Column',
    column_name: 'Column name',
    type_terminal: 'Table name',
    cancel: 'Cancel',
    move: 'Move to',
    imei: 'Imei',
    main_menu: 'Menu',
    addToTerminal: 'Transfer to terminals',
    period: 'Interval',
    typeDict: 'Type of directory',
    value: 'Value',
    addCompany: 'Add to module',
    type_interval: 'Type of interval',
    show_itog: 'Show total',
    typeGroup: 'Group',
    true: 'Yes',
    false: 'No',
    null: 'No',
    undefined: 'Undefined',
    outIn: 'Out in',
    create: 'Create',
    open: 'Open',
    train: 'Training',
  },
}
