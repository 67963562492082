<template>
  <v-flex class="px-3">
    <v-layout column>
      <v-layout wrap>
        <v-flex md3 sm5 xs12>
          <v-layout>
            <v-layout column align-center>
              <span class="persons-list__title">вышел</span>
              <v-checkbox
                color="#CF0707"
                class="mt-0 pt-0 pl-2"
                v-model="controls.offline"
              ></v-checkbox>
            </v-layout>
            <v-layout column align-center class="ml-4">
              <span class="persons-list__title">на месте</span>
              <v-checkbox
                color="#0BB208"
                class="mt-0 pt-0 pl-2"
                v-model="controls.online"
              ></v-checkbox>
            </v-layout>
            <v-layout column align-center class="ml-4" z>
              <span class="persons-list__title">отсутствует</span>
              <v-checkbox
                color="#D17F04"
                class="mt-0 pt-0 pl-2"
                v-model="controls.none"
              ></v-checkbox>
            </v-layout>
          </v-layout>
        </v-flex>
        <v-flex md3 sm7 xs12>
          <v-layout align-center fill-height>
            <input class="px-2 persons-list__input" type="text" v-model="search" />
          </v-layout>
          <!-- <v-text-field
            solo
            hide-details
            single-line
            style="box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25); border-radius: 2px; border: #858383"
          ></v-text-field> -->
        </v-flex>
        <v-flex md2 offset-md4 sm12 xs12>
          <v-layout justify-center align-center fill-height>
            <v-btn color="black--text" icon small dark slot="activator" @click="prevDay">
              <v-icon color="black" v-text="'$vuetify.icons.material_chevron_left'"></v-icon>
            </v-btn>
            <v-menu
              ref="menu"
              :close-on-content-click="false"
              :first-day-of-week="1"
              :nudge-right="-200"
              v-model="menu"
              :return-value.sync="day"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{on}">
                <span v-on="on">{{ day }}</span>
              </template>
              <v-date-picker v-model="day" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">{{ $t('rtables.cancel') }}</v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(day)">{{
                  $t('rtables.ok')
                }}</v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn color="black--text" icon small dark slot="activator" @click="nextDay">
              <v-icon color="black" v-text="'$vuetify.icons.material_chevron_right'"></v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs12 md6 lg3 v-for="item in postPersons[0]" :key="item.id" class="px-3 py-3">
          <v-card
            flat
            :class="[
              item.className,
              item.is_in ? 'persons-list__border--green' : 'persons-list__border--red'
            ]"
            class="elevation-1"
            style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border-radius: 3px; padding: 8px 0 8px 4px; background: #EDF2F4"
          >
            <v-layout>
              <v-flex md1 xs1 class="mr-2">
                <v-layout justify-center column>
                  <v-btn
                    :disabled="!item.url"
                    class="my-0 mx-0"
                    color="black--text"
                    icon
                    small
                    dark
                    slot="activator"
                    @click="showPhoto(item)"
                  >
                    <v-icon v-text="'$vuetify.icons.photo'"></v-icon>
                  </v-btn>
                  <v-btn
                    class="my-0 mx-0"
                    color="black--text"
                    icon
                    small
                    dark
                    slot="activator"
                    @click="showDrawer(item)"
                  >
                    <v-icon v-text="'$vuetify.icons.clock24'"></v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout align-center>
                  <span style="margin-bottom: 5px; color: #0D0000">{{
                    item.name || 'Неизвестный'
                  }}</span>
                </v-layout>
                <v-layout v-if="item.id_person" justify-space-between>
                  <v-flex xs8>
                    <v-layout column>
                      <span>Первый вход:</span>
                      <span>Последнее событие:</span>
                    </v-layout>
                  </v-flex>
                  <v-flex>
                    <v-layout column>
                      <span>{{ item.first_datepoint }}</span>
                      <span>{{ item.last_datepoint }}</span>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs12 sm6 md4 lg3 v-for="item in postPersons[1]" :key="item.id" class="px-3 py-3">
          <v-card flat :class="item.className" class="persons-list__card elevation-1">
            <v-layout>
              <v-flex>
                <v-layout align-center justify-space-between>
                  <span>{{ item.name }}</span>
                  <span class="pr-4">{{ getLastDate(item) || '' }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout class="pt-2" v-if="item.id_person">
              <v-layout justify-space-between>
                <v-flex xs6>
                  <p class="my-0">Первый вход:</p>
                  <p class="my-0">Последнее событие:</p>
                </v-flex>
                <v-flex xs6>
                  <p class="my-0">{{ item.first_datepoint }}</p>
                  <p class="my-0">{{ item.last_datepoint }}</p>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>

    <v-navigation-drawer
      app
      temporary
      right
      fixed
      hide-overlay
      stateless
      v-model="drawers.historyEvents"
      :width="403"
      :mobile-break-point="400"
      :style="marginDrawer"
      :disable-resize-watcher="true"
      class="mt-10"
    >
      <v-flex class="posr">
        <v-layout justify-end align-center class="overflow-hidden">
          <v-card
            style="min-width: 320px; max-width: 403px; width: 100%; overflow-y: auto"
            class="pt-2"
          >
            <v-layout class="px-2 mb-2">
              <v-icon
                v-text="'$vuetify.icons.arrow_right'"
                @click="drawers.historyEvents = false"
              ></v-icon>
            </v-layout>
            <v-layout
              v-for="item in listOfEvents"
              :key="item.datepoint"
              class="py-1"
              column
              style="border-bottom: 0.5px solid rgba(219, 221, 222, .5)"
            >
              <v-layout align-center justify-space-between style="margin: auto 17px auto 20px;">
                <v-layout align-center>
                  <v-btn
                    :disabled="!item.url"
                    small
                    icon
                    slot="activator"
                    color="transparent"
                    depressed
                    dark
                    class="text-none my-0 mx-0"
                    @click="showPhoto(item)"
                  >
                    <v-icon v-text="'$vuetify.icons.photo'"></v-icon>
                  </v-btn>
                  <span class="ml-3">{{ item.jdata.name }}</span>
                </v-layout>
                <v-layout align-center justify-end class="mt-0">
                  <v-icon v-text="`$vuetify.icons.${item.is_in ? 'is_in' : 'is_out'}`"></v-icon>
                  <span class="ml-2">{{ item.jdata.datepoint }}</span>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-card>
        </v-layout>
      </v-flex>
    </v-navigation-drawer>

    <v-snackbar top v-model="showNotify" :timeout="5000" class="pt-10">
      <v-layout column class="px-2">
        <h2 class="mb-3 mx-auto">
          {{ `Сотрудник ${person.name} - ${person.is_in ? 'Вошел' : 'Вышел'}` }}
        </h2>
        <v-flex>
          <v-layout justify-center>
            <v-btn
              color="main_color"
              text
              depressed
              @click="showNotify = false"
              class="mx-0 my-0 px-2"
            >
              {{ $t('close') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-dialog v-model="modals.showPhoto" content-class="elevation-0">
      <v-layout justify-center @click="modals.showPhoto = false">
        <v-flex md10>
          <v-layout justify-center>
            <img :src="photo" alt="" />
            <!-- <img src="cams/output12.mjpeg" alt=""> -->
            <!-- <video controls="controls">
              <source :src="photo" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
            </video> -->
          </v-layout>
        </v-flex>
      </v-layout>
    </v-dialog>
  </v-flex>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import HTTP from '@/api/http'
import pSocket from '@/mixins/pSocket'

export default {
  mixins: [pSocket],

  props: {
    component: {
      type: String
    }
  },

  mqtt: {
    TASKS(data, topic) {
      this.onMessage(data)
    }
  },

  data() {
    return {
      listOfPersons: [],
      listOfEvents: [],
      person: {},
      day: '',
      menu: false,
      showNotify: false,
      controls: {
        none: true,
        online: true,
        offline: true
      },
      search: '',
      photo: '',
      modals: {
        showPhoto: false
      },
      drawers: {
        historyEvents: false
      },
      eventListeners: {}
    }
  },
  computed: {
    postPersons() {
      const listOfPersons = this.listOfPersons
        .filter(
          ({dateday, is_in, name = ''}) =>
            dateday === this.day &&
            ((is_in && this.controls.online) || (!is_in && this.controls.offline)) &&
            name.includes(this.search)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      const restOfPersons = this.getDicts('persons')
        .filter(({id, name = ''}) => {
          const existPerson = listOfPersons.every(person => person.id_person !== id)
          if (existPerson && this.controls.none && name.includes(this.search)) {
            return true
          }
          return false
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))

      return [listOfPersons, restOfPersons]
    },
    marginDrawer() {
      const marginTop = window.pageYOffset
      return {
        'margin-top': `${marginTop}px`
      }
    },
    ...mapGetters(['getDicts'])
  },

  watch: {
    day(newVal, oldVal) {
      if (oldVal === '') return
      const newDate = newVal.replace(/-\d+$/, '')
      const oldDate = oldVal.replace(/-\d+$/, '')

      if (newDate !== oldDate) this.initCtrl()
    }
  },
  created() {
    this.day = moment().format('YYYY-MM-DD')
    this.initCtrl()
    this.$mqtt.subscribe(`TASKS`)

    this.eventListeners.onKeydown = e => {
      if (e.keyCode === 27) {
        this.drawers.historyEvents = false
      }
    }

    window.addEventListener('keydown', this.eventListeners.onKeydown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.eventListeners.onKeydown)
  },

  methods: {
    async initCtrl() {
      const findViolGroup = this.getDicts('groups').find(group => group.type_group === 'viol_calc')
      if (!findViolGroup) return

      const {data} = await HTTP.post('persons_list', {
        id_group: findViolGroup.id,
        dateday: moment(this.day).format('YYYY-MM-01')
      })
      if (!data.length) return

      this.listOfPersons = data.map(person => {
        const findPerson = this.getDicts('persons').find(
          personDict => personDict.id === person.id_person
        )

        // Temporarily
        let first_datepoint
        if (
          ['10f64e1b-d946-4019-aae7-336e563ab207', '94862b1a-cad1-4e90-be56-02b1b0a88904'].includes(
            person.id_person
          )
        ) {
          first_datepoint = moment(person.first_datepoint)
            .subtract(0, 'minutes')
            .format('HH:mm:ss')
        } else {
          first_datepoint = moment(person.first_datepoint).format('HH:mm:ss')
        }

        return {
          ...person,
          first_datepoint,
          last_datepoint: moment(person.last_datepoint).format('HH:mm:ss')
        }
      })
    },
    prevDay() {
      this.day = moment(this.day)
        .subtract(1, 'd')
        .format('YYYY-MM-DD')
    },
    nextDay() {
      this.day = moment(this.day)
        .add(1, 'd')
        .format('YYYY-MM-DD')
    },
    getLastDate(item) {
      const list = this.listOfPersons
        .filter(person => person.id_person === item.id && person.dateday < this.day)
        .sort((a, b) => (a.dateday < b.dateday ? 1 : -1))

      if (list.length) {
        return list[0].dateday
      }
      return null
    },
    showPhoto(person) {
      this.photo = person.url
      this.modals.showPhoto = true
    },
    async showDrawer(person) {
      const findViolGroup =
        this.getDicts('groups').find(group => group.type_group === 'viol_calc') ?? {}
      const options = {
        id_group: findViolGroup.id,
        id_person: person.id_person,
        date_from: moment(this.day).format('YYYY-MM-DD'),
        date_to: moment(this.day)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      const {data} = await HTTP.post('persons_events', options)
      this.listOfEvents = data
        .filter(event => event.id_person === person.id_person)
        .map(event => {
          const findPerson = this.getDicts('persons').find(pers => pers.id === event.id_person)

          return {
            ...event,
            jdata: {
              ...event.jdata,
              name: findPerson ? findPerson.name : '',
              datepoint: moment(event.datepoint).format('HH:mm:ss')
            }
          }
        })
      this.drawers.historyEvents = true
    },
    onMessage(e) {
      // console.log(e)
      const data = JSON.parse(e.data)
      console.log(data)
      if (data.id_person) {
        this.setPerson(data)
      }
    },
    setPerson(json) {
      this.person = {
        ...json
      }

      const findPerson = this.listOfPersons.find(
        ({id_person, dateday, name = ''}) =>
          name.toLowerCase().includes(this.search.toLowerCase()) &&
          json.id_person === id_person &&
          dateday === this.day
      )

      if (findPerson) {
        this.$set(findPerson, 'last_datepoint', moment(json.datepoint).format('HH:mm:ss'))
        this.$set(findPerson, 'is_in', json.is_in)
        this.$set(findPerson, 'url', json.url)
        this.$set(findPerson, 'className', 'animation_custom')

        setTimeout(() => {
          this.$set(findPerson, 'className', '')
        }, 5000)
      } else {
        this.listOfPersons.push({
          ...json,
          dateday: moment(json.datepoint).format('YYYY-MM-DD'),
          last_datepoint: moment(json.datepoint).format('HH:mm:ss'),
          first_datepoint: moment(json.datepoint).format('HH:mm:ss'),
          className: 'animation_custom'
        })

        const find = this.listOfPersons.find(
          person => person.dateday === this.day && person.id_person === json.id_person
        )

        setTimeout(() => {
          this.$set(find, 'className', '')
        }, 5000)
      }

      this.showNotify = true
    }
  }
}
</script>
