// Методы для обработки информации, получаемой с вебсокета в смисе

import HTTP from '@/api/http'

export default {
  data() {
    return {
      notificationsAll: []
    }
  },

  methods: {
    onMessage(e) {
      const channel = e.target.url.match(/\w+$/)
      if (channel[0] === 'DATA') {
        console.log(e.data)
        this.setSensorValue(e.data)
      } else if (channel[0] === 'TASKS') {
        const data = JSON.parse(e.data)
        console.log(data)
        if (data) {
          this.setNotify(data)
        }
      }
    },
    setSensorValue(link) {
      HTTP.post('key_redis', {
        key: `SENSORS:${link}`
      }).then(response => {
        const {data} = response
        const [id_terminal, addr, time] = link.split('@')

        Object.entries(data).forEach(item => {
          const [id, value] = [item[0], item[1]]
          const findLink = this.getDicts('tsensors').find(
            sensor =>
              sensor.id_terminal === id_terminal && sensor.id_sensor === id && sensor.addr === addr
          )
          console.log(data)

          this.$set(findLink, 'value', value)
          this.$set(findLink, 'jdata.last_update', time)
        })
      })
    },
    setNotify(json) {
      const findTask = this.getDicts('tasks').find(task => task.id === json.id_task)

      switch (findTask.type_task) {
        case 'change_10':
          this.pushNotify(json)
          break
        case 'change_01':
          this.recoveryLink(json)
          break
        default:
          break
      }
    },
    pushNotify(notify) {
      this.notificationsAll.unshift({
        ...notify
      })
      this.messages.unshift({
        ...notify
      })
      this.checkLinks()
      if (this.$route.params.sensors === notify.id_tsensor) {
        const findLink = this.getDicts('tsensors').find(link => link.id === notify.id_tsensor)
        this.getActiveNotify(findLink)
      }
    },
    addNotify(notify) {
      this.notificationsAll.unshift(notify)
    },
    delNotify(item) {
      this.notificationsAll.forEach((notify, index) => {
        if (notify.id === item.id) {
          this.notificationsAll.splice(index, 1)
        }
      })
    },
    putNotify(item) {
      const find = this.notificationsAll.find(notify => notify.id === item.id)

      if (find) {
        Object.assign(find, item)
      } else {
        this.notificationsAll.unshift({
          ...item,
          type_mess: 'warning'
        })
      }
    },
    checkLinks() {
      this.notificationsAll.forEach(notify => {
        const findLink = this.getDicts('tsensors').find(link => link.id === notify.id_tsensor)

        if (findLink && findLink.className !== 'error') {
          this.$set(findLink, 'className', notify.type_mess || 'warning')
        }
      })
    },
    checkColorLink() {
      const findLink = this.getDicts('tsensors').find(
        link => link.id === this.responseElem.id_tsensor
      )
      const filterNotifyes = this.notificationsAll.filter(
        notify => notify.id_tsensor === findLink.id_tsensor
      )

      if (!filterNotifyes.length) {
        this.$store.commit('PUT_ITEM', {
          ...findLink,
          className: ''
        })
      } else {
        this.$store.commit('PUT_ITEM', {
          ...findLink,
          className: 'warning'
        })
      }
    },
    recoveryLink(notify) {
      const findNotify = this.notificationsAll.find(item => item.id_tsensor === notify.id_tsensor)

      this.notificationsAll = this.notificationsAll.filter(
        item => item.id_tsensor !== notify.id_tsensor
      )
      this.messages = this.messages.filter(item => item.id_tsensor !== notify.id_tsensor)
      this.showNotify = true
      this.recoveryElem = {
        ...notify
      }
      if (this.error.id === notify.id) {
        this.error = {}
      }

      HTTP.put('response', {
        id_alarm: notify.id,
        id_user: this.auth.id,
        message: 'Автоматически восстановлено',
        type_mess: findNotify.type_mess
      })
    }
  }
}
// @TRS:1.0@192.168.0.55#C_004=3234535;D_101=0
