<template>
  <v-flex md12 sm12 xs12 class="px-6">
    <v-layout wrap style="margin: 0 -27.5px" justify-center>
      <v-flex md9 sm12 v-if="['admin'].includes(auth.acc_role)">
        <v-layout wrap>
          <v-flex md6 sm12 order-md4 order-sm2 order-xs2 class="px-3 mb-4">
            <v-layout column>
              <dict-title :title="$t('tax.territory')"></dict-title>

              <v-card elevation="2" class="mt-3 overflow-hidden">
                <v-layout class="px-3 pt-6 mt-n3 mb-5">
                  <v-text-field
                    type="search"
                    class="grey_custom--text"
                    v-model="search.territory"
                    hide-details
                  ></v-text-field>
                  <v-icon v-text="'$search'" class="dict__icon--search mt-8" size="14"></v-icon>
                </v-layout>

                <v-layout column style="height: calc(100vh - 162px); overflow-y: auto">
                  <!-- Pass card -->
                  <v-card
                    v-for="(item, index) in getDrivers"
                    :key="index"
                    class="mb-2"
                    :color="!item.jdata.timer ? $vuetify.theme.tax_red_color : ''"
                  >
                    <v-layout class="pt-2 pb-2 px-1 tax__out" wrap>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">ФИО</p>
                          <span class="black--text">{{ item.name }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">Телефон</p>
                          <span class="black--text">{{ item.jdata.telnumber }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">Время въезда</p>
                          <span class="black--text">{{ item.first_datepoint }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">
                            Номер автомобиля
                          </p>
                          <span class="black--text">{{ item.jdata.gosnumber }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">
                            Кол-во нарушений
                          </p>
                          <span class="black--text">{{ item.jdata.count_violation }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout justify-center align-center fill-height class="tax__out__icons">
                          <!-- <v-icon v-text="`$vuetify.icons.tax_parking`" v-if="item.is_in" style="height: 30px; width: 30px" class="mx-2"></v-icon> -->
                          <div class="mt-0">
                            <v-icon
                              v-text="`$vuetify.icons.tax_timer`"
                              style="height: 16px; min-width: 14px; margin-left: 20px"
                            ></v-icon>
                            <p class="mb-0" style="color: #F14742; font-size: 14px">
                              {{ item.jdata.timer || '00:00:00' }}
                            </p>
                          </div>
                          <div
                            style="position: relative; cursor: pointer"
                            class="mx-2"
                            @click="enterChat(item)"
                          >
                            <v-icon
                              v-text="`$vuetify.icons.tax_letter`"
                              style="min-height: 38px; min-width: 25px"
                            ></v-icon>
                            <span v-if="item.jdata.amount_messages > 0" class="white--text">{{
                              item.jdata.amount_messages || '0'
                            }}</span>
                          </div>
                        </v-layout>
                      </v-flex>
                    </v-layout>

                    <!-- <v-layout class="px-1 tax__timer" justify-center align-center>
                      <v-icon class="mr-1" :class="[i === 0 ? 'tax_alert_color--text' : 'tax_out_color--text']" v-text="'$vuetify.icons.tax_time'" style="width: 20px; height: 20px"></v-icon>
                      <span :class="[i === 0 ? 'tax_alert_color--text' : 'tax_out_color--text']">02:00:00</span>
                    </v-layout> -->
                  </v-card>
                </v-layout>
              </v-card>
            </v-layout>
          </v-flex>

          <v-flex md6 sm12 order-md5 order-sm4 order-xs4 class="px-3 mb-4">
            <v-layout column>
              <dict-title title="Пропуска">
                <v-btn icon class="mr-1 white--text" @click="dialogs.newCard = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </dict-title>

              <v-card elevation="2" class="mt-3" style="overflow: hidden;">
                <v-layout class="px-3 pt-6" style="margin-top: -14px; margin-bottom: 20px">
                  <v-text-field
                    type="search"
                    class="grey_custom--text"
                    v-model="search.out"
                    hide-details
                  ></v-text-field>
                  <v-icon v-text="'$search'" class="dict__icon--search mt-8" size="14"></v-icon>
                </v-layout>

                <v-layout column style="height: calc(100vh - 162px); overflow-y: auto">
                  <v-card
                    v-for="item in getCards"
                    :key="item.id"
                    style="margin-bottom: 10px; box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);"
                    :color="$vuetify.theme.tax_blue_color"
                    :class="[{'grey lighten-2': item.id === pass_card.id}]"
                    @click="sendCardToExtension(item)"
                  >
                    <v-layout class="pt-2 pb-2 px-1 tax__out" wrap>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">ФИО</p>
                          <span class="black--text">{{ item.id_person }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">Квартира</p>
                          <span class="black--text">{{ item.id_flat }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">Тип пропуска</p>
                          <span class="black--text">{{ item.type_card }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">Время от</p>
                          <span class="black--text">{{ localDate(item.date_from) }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">
                            Время до
                          </p>
                          <span class="black--text">{{ localDate(item.date_to) }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex md4 sm4 xs6>
                        <v-layout column justify-center class="px-1 mb-1">
                          <p class="mb-0 tax_out_color--text">Номер телефона</p>
                          <span class="black--text">{{ item.jdata.telnumber }}</span>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-layout>
              </v-card>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex md3 sm12 v-if="['user'].includes(auth.acc_role)">
        <tax-create-card></tax-create-card>
        <tax-active-doors></tax-active-doors>
      </v-flex>

      <v-flex md3 sm12 class="px-3 mb-4">
        <v-layout column fill-height style="padding-top: 2px" v-if="ready">
          <v-layout flex-column>
            <!-- Card 1 -->
            <v-flex sm12 order-md1 order-sm1 order-xs1 class="mb-0">
              <v-layout column class="tax__code">
                <v-card class="mb-3 py-2" style="">
                  <h3 class="text-center tax__code__title--orange">
                    Продление пропуска
                  </h3>
                  <v-flex md12 xs12 class="mx-auto mt-3 master">
                    <v-layout class="px-2" wrap>
                      <v-flex md6>
                        <v-layout column justify-end class="mb-0">
                          <p class="master__paragraph master__title mb-2">Время</p>
                          <v-menu offset-y :close-on-content-click="false" ref="menu">
                            <template v-slot:activator="{on}">
                              <v-text-field
                                v-on="on"
                                solo
                                single-line
                                flat
                                hide-details
                                class="input__options"
                                :value="localDate(pass_card.date_to)"
                                :disabled="!pass_card.id"
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="pass_card.date_to"
                              no-title
                              scrollable
                              first-day-of-week="1"
                              locale="ru-Ru"
                              :color="$vuetify.theme.main_color"
                              @input="setUtcDate(pass_card, 'date_to')"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-layout>
                      </v-flex>
                      <v-flex md6>
                        <v-layout justify-end fill-height align-end class="">
                          <v-btn
                            text
                            class="tax__code__btn--orange white--text ma-0"
                            :disabled="!pass_card.id"
                            @click="extendPass()"
                            >Продлить</v-btn
                          >
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card>
              </v-layout>
            </v-flex>

            <!-- Card 3 -->
            <v-flex sm12 order-md3 order-sm3 order-xs3 class=" mb-0">
              <v-layout column class="tax__code">
                <v-card class="mb-3 py-2">
                  <h3 class="text-center tax__code__title--blue">
                    Разовый пропуск
                  </h3>
                  <v-flex md12 xs12 class="mx-auto mt-3">
                    <v-layout class="px-2" wrap>
                      <v-flex md6>
                        <v-layout column justify-end class="mb-0">
                          <p class="master__paragraph master__title mb-2">Код</p>
                          <v-text-field
                            v-model="search.code"
                            solo
                            single-line
                            flat
                            hide-details
                            class="input__options"
                          >
                          </v-text-field>
                        </v-layout>
                      </v-flex>
                      <v-flex md6>
                        <v-layout justify-end fill-height align-end class="">
                          <v-btn
                            text
                            class="tax__code__btn--blue white--text ma-0"
                            @click="openDoor()"
                            >Открыть</v-btn
                          >
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card>
              </v-layout>
            </v-flex>
          </v-layout>

          <dict-title title="Чат"></dict-title>
          <v-card
            elevation="2"
            class="mt-3 pt-9 tax__chat"
            style="overflow: hidden; height: calc(100vh - 350px); min-height: 250px"
          >
            <v-layout v-show="chat.id" column justify-space-between fill-height>
              <div>
                <v-layout justify-space-between class="mb-1 px-2">
                  <v-flex>
                    <v-layout align-center fill-height>
                      <img
                        v-if="['admin', 'operator', 'manager'].includes(this.auth.acc_role)"
                        src="@/assets/tax/arrow_left.svg"
                        alt=""
                        class="mr-1 px-2"
                        @click="chat = {}"
                        style="cursor: pointer"
                      />
                      <div
                        style="height: 23px; width: 23px; background-color: #C4C4C4; border-radius: 50%"
                      ></div>
                      <v-layout column justify-center class="px-2 mt-0">
                        <p class="mb-0">{{ chat.name }}</p>
                      </v-layout>
                    </v-layout>
                  </v-flex>

                  <v-flex>
                    <v-layout align-center justify-end>
                      <v-btn text icon small>
                        <img src="@/assets/tax/dots.svg" alt="" />
                      </v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </div>

              <v-card
                elevation="0"
                style="max-height: calc(100%); overflow-y: auto; overflow-x: hidden"
                ref="chat"
                fill-height
              >
                <v-layout
                  column
                  class="px-2 tax__chat__messages"
                  justify-end
                  v-for="(item, index) in getChats"
                  :key="item.id + index"
                >
                  <v-layout
                    class="my-1"
                    :class="[
                      item.id_person === user_data.id
                        ? 'tax__chat__messages--right'
                        : 'tax__chat__messages--left'
                    ]"
                    :justify-end="item.id_person === user_data.id"
                  >
                    <div class="py-1 px-2">
                      <p class="mb-0" style="color: #EAFFAF; font-weight: 500">
                        {{ item.user_name }}
                      </p>
                      <v-layout align-end>
                        <span class="white--text" v-html="item.message"></span>
                        <span
                          class="ml-2"
                          style="font-size: 10px"
                          :style="
                            item.id_person === user_data.id ? 'color: #73777B' : 'color: #989EA4'
                          "
                          >{{ formatTime(item.created) }}</span
                        >
                      </v-layout>
                    </div>
                  </v-layout>
                </v-layout>
              </v-card>

              <v-card elevation="0">
                <v-layout column class="px-2 py-3 tax__chat__control">
                  <v-layout class="" align-end>
                    <!-- <v-text-field solo single-line flat hide-details class="input__options tax__chat__control--input" @keyup.enter="sendMsg()" v-model="msg"></v-text-field>-->
                    <textarea
                      rows="2"
                      v-model="msg"
                      class="px-2 py-1"
                      style="width: 100%; border: 1px solid #A6A4A4; border-radius: 10px; "
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="sendMsg"
                    ></textarea>
                    <v-btn
                      :disabled="msg.length === 0"
                      text
                      icon
                      class="my-0 ml-2 tax__chat__control--btn"
                      style="height: 38px; width: 38px;"
                      @click="sendMsg()"
                    >
                      <v-icon v-text="`$tax_enter`"></v-icon>
                    </v-btn>
                  </v-layout>
                </v-layout>
              </v-card>
            </v-layout>

            <v-fade-transition>
              <div v-if="!chat.id">
                <div class="px-5 pt-3" style="margin-top: -40px; margin-bottom: 5px;height:70px">
                  <v-text-field
                    prepend-inner-icon="$search"
                    type="search"
                    class="grey_custom--text"
                    v-model="search.chat"
                    hide-details
                  ></v-text-field>
                  <!-- <v-icon   v-text="'$vuetify.icons.search'" class="dict__icon--search mt-n12" size="14"></v-icon> -->
                </div>

                <v-container
                  class="px-2 mt-0"
                  v-if="['admin', 'operator', 'manager'].includes(auth.acc_role)"
                >
                  <v-card>
                    <v-btn
                      type="success"
                      text
                      v-bind:color="btns.showPersons == true ? 'primary' : 'teal'"
                      small
                      class="ma-0 "
                      @click="btns.showPersons = !btns.showPersons"
                      >{{ btns.showPersons ? 'Рассылка' : 'Жители' }}</v-btn
                    >
                  </v-card>
                </v-container>
              </div>
            </v-fade-transition>

            <v-fade-transition>
              <v-layout
                v-show="!chat.id"
                style="height: calc(100% - 10px); overflow-y: auto; overflow-x: hidden"
              >
                <v-layout column v-if="btns.showPersons">
                  <v-card
                    v-for="person in getPersons"
                    :key="person.id"
                    class="mb-2 pa-3"
                    elevation="2"
                    @click="enterPersonChat(person)"
                    hover
                    v-ripple="false"
                  >
                    <v-layout justify-space-between>
                      <v-flex>
                        <v-layout>
                          <div
                            style="height: 37px; width: 37px; background-color: #C4C4C4; border-radius: 50%;  min-width:37px "
                          ></div>
                          <v-layout column class="px-2 mt-0">
                            <p class="mb-0">{{ person.name }}</p>
                            <span
                              style="color: #655D5D; font-size: 12px;"
                              v-html="person.message"
                            ></span>
                          </v-layout>
                        </v-layout>
                      </v-flex>

                      <v-flex>
                        <v-layout align-end column fill-height>
                          <span style="color: #73777B; font-size: 10px; margin-top: 5px">{{
                            formatTime(person.time)
                          }}</span>
                          <span
                            style="color: white; font-size: 10px; margin-top: 3px; background: #3EAC79; border-radius: 50%; padding: 0 5px; font-weight: bold"
                            >{{ amountNonreadableMsg(person) }}</span
                          >
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-layout>

                <v-layout column v-if="!btns.showPersons">
                  <v-card
                    v-for="item in getGroupsChats"
                    :key="item.id + item.message"
                    class="mb-2 pa-2"
                    elevation="2"
                    @click="chat = item"
                    hover
                    v-ripple="true"
                  >
                    <v-layout justify-space-between>
                      <v-flex>
                        <v-layout>
                          <div
                            style="height: 37px; width: 37px; background-color: #C4C4C4; border-radius: 50%"
                          ></div>
                          <v-layout column class="px-2 mt-0">
                            <p class="mb-0">
                              {{ item.name }}
                              {{ `(${item.send_all ? 'всем' : 'на парковке'})` }}
                            </p>
                            <span style="color: #655D5D; font-size: 12px">{{
                              item.jdata.message | dots
                            }}</span>
                          </v-layout>
                        </v-layout>
                      </v-flex>

                      <v-flex>
                        <v-layout justify-end align-center fill-height>
                          <span style="color: #73777B; font-size: 10px">{{ item.jdata.time }}</span>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-layout>
              </v-layout>
            </v-fade-transition>
          </v-card>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogs.newCard" width="500">
      <v-card class="px-2 py-2">
        <h2>Создание пропуска</h2>
        <hr class="main_color my-2" />
        <div>
          <span>Телефон</span>
          <v-text-field
            v-mask="'+7##########'"
            solo
            single-line
            flat
            class="input__options"
            v-model="newCard.phone"
          >
          </v-text-field>
        </div>
        <div>
          <span>Имя</span>
          <v-text-field solo single-line flat class="input__options" v-model="newCard.name">
          </v-text-field>
        </div>
        <div>
          <span>Номер автомобиля</span>
          <v-text-field solo single-line flat class="input__options" v-model="newCard.gosnumber">
          </v-text-field>
        </div>
        <div>
          <span>Зоны</span>
          <v-select
            v-model="newCard.id_groups"
            :items="getDicts('groups')"
            item-value="id"
            item-text="name"
            item-color="main_color"
            color="main_color"
            multiple
            hide-details
            class="input__main px-2 pt-0 mt-0"
            height="27"
          >
          </v-select>
        </div>
        <div>
          <span>Время от</span>
          <v-menu offset-y :close-on-content-click="false" ref="menu">
            <template v-slot:activator="{on}">
              <v-text-field
                v-on="on"
                solo
                single-line
                flat
                class="input__options"
                :value="localDate(newCard.date_from)"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="newCard.date_from"
              no-title
              scrollable
              first-day-of-week="1"
              locale="ru-Ru"
              :color="$vuetify.theme.main_color"
              @input="setUtcDate(newCard, 'date_from')"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <div>
          <span>Время до</span>
          <v-menu offset-y :close-on-content-click="false" ref="menu">
            <template v-slot:activator="{on}">
              <v-text-field
                v-on="on"
                solo
                single-line
                flat
                class="input__options"
                :value="localDate(newCard.date_to)"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="newCard.date_to"
              no-title
              scrollable
              first-day-of-week="1"
              locale="ru-Ru"
              :color="$vuetify.theme.main_color"
              @input="setUtcDate(newCard, 'date_to')"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <hr class="main_color my-2" />
        <div class="text-right">
          <v-btn class="btn__save px-3 mx-1" text @click="createNewCard">
            <span class="subtitle-1 text-none">Создать</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.value" top absolute color="white">
      <v-layout align-center>
        <span class="black--text">{{ snackbar.text }}</span>
        <!-- <v-btn
          color="pink"
          text
          @click="snackbar.value = false"
        >
          Закрыть
        </v-btn> -->
      </v-layout>
    </v-snackbar>
  </v-flex>
</template>

<script>
import moment from 'moment'
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'
import pSocket from '@/mixins/pSocket'

import {db, Timestamp} from '@/components/db.js'
import {v4 as uuidv4} from 'uuid'

import taxActiveDoors from '@/components/custom/tax/active-doors'
import taxCreateCard from '@/components/custom/tax/create-card'
import dictTitle from '@/components/custom/dicts/title'

import {mask} from 'vue-the-mask'

export default {
  directives: {
    mask
  },

  components: {
    taxActiveDoors,
    taxCreateCard,
    dictTitle
  },
  filters: {
    dots(value, length = 22) {
      if (!value) return ''
      if (value.length >= length + 3) {
        return `${value.substring(0, length)}...`
      }
      return value
    }
  },

  mixins: [pSocket],

  mqtt: {
    test123(data, topic) {
      console.log(new TextDecoder('utf-8').decode(data))
      console.log('MQTT', topic)
    },
    CHATS(data, topic) {
      console.log(data)
    },
    TASKS(data, topic) {
      console.log(data)
    }
  },

  data() {
    return {
      ready: false,
      search: {
        territory: '',
        out: '',
        prisoner: '',
        pass: '',
        code: '',
        chat: ''
      },
      pass_card: {},
      msg: '',
      msg_test: '',
      lists: {
        drivers: []
      },
      chat: {},
      btns: {
        showPersons: true
      },
      snackbar: {
        value: false,
        text: ''
      },
      alert: {
        value: false,
        text: ''
      },
      dialogs: {
        newCard: false
      },
      newCard: {},
      timer: null,
      chartData: {},
      chats: []
    }
  },
  computed: {
    getChats() {
      return this.chats
      // this.chats.forEach(item => {
      //   item.message = item.message.replace(/(\\r)*\\n/g, '<br>')
      // })
    },
    getCards() {
      const getPersonName = id => {
        const findPerson = this.getDicts('persons').find(person => person.id === id)

        if (!findPerson) return ''
        return findPerson.name
      }

      const getFlatName = id => {
        const findPerson = this.getDicts('persons').find(person => person.id === id)
        if (!findPerson) return ''

        const findFlat = this.getDicts('flats').find(flat => flat.id === findPerson.id_flat)

        if (!findFlat) return ''
        return findFlat.name
      }

      const filterCard = card =>
        [
          'id_person',
          'id_flat',
          'type_card',
          'dt_active',
          'gosnumber',
          'telnumber',
          'numcard'
        ].some(option => {
          if (card[option] || card.jdata[option]) {
            return [
              card[option],
              card.jdata[option],
              this.$t(`lists.cards.type_cards.${card.type_card}`)
            ].some(str => (str ? str.toLowerCase().includes(this.search.out.toLowerCase()) : false))
          }
          return false
        })

      return this.getDicts('cards')
        .map(card => ({
          ...card,
          id_person: getPersonName(card.id_person),
          id_flat: getFlatName(card.id_person)
        }))
        .filter(filterCard)
        .sort((a, b) => (a.dt_active < b.dt_active ? 1 : -1))
    },
    getPersons() {
      return this.getDicts('persons')
        .filter(person => {
          return person.name.toLowerCase().includes(this.search.chat.toLowerCase())
        })
        .sort((personA, personB) => {
          const amountNonreadableMsg = person => {
            let role
            if (['admin', 'operator', 'manager'].includes(this.auth.acc_role)) {
              role = 'is_read_by_admin'
            } else {
              role = 'is_read_by_user'
            }

            const filterChats = this.chats.filter(
              chat => chat.id_person === person.id && chat[role] === false
            )

            return filterChats.length
          }

          if (amountNonreadableMsg(personA) || amountNonreadableMsg(personB)) {
            return amountNonreadableMsg(personB) - amountNonreadableMsg(personA)
          }

          if (personA.time || personB.time) {
            return (personA?.time?.seconds ?? 0) < (personB?.time?.seconds ?? 0) ? 1 : -1
          }

          return personA.name > personB.name ? 1 : -1
        })
    },
    getDrivers() {
      const filterDriver = driver =>
        ['gosnumber', 'telnumber', 'first_datepoint', 'name'].some(option => {
          if (driver[option] || driver.jdata[option]) {
            return [driver[option], driver.jdata[option]].some(str =>
              str ? str.toLowerCase().includes(this.search.territory.toLowerCase()) : false
            )
          }
          return false
        }) &&
        driver.numcard.includes(this.search.pass.toLowerCase()) &&
        driver.is_in

      return this.lists.drivers.filter(filterDriver).sort((a, b) => (a.jdata.timer ? 1 : -1))
    },
    getGroupsChats() {
      return this.getDicts('groups')
        .map(group => [
          {...group, type_msg: 'group', send_all: true},
          {...group, type_msg: 'group', send_all: false}
        ])
        .flat()
    },
    amountNonreadableMsg() {
      let role
      if (['admin', 'operator', 'manager'].includes(this.auth.acc_role)) {
        role = 'is_read_by_admin'
      } else {
        role = 'is_read_by_user'
      }

      return person => {
        const filterChats = this.chats.filter(
          chat => chat.id_person === person.id && chat[role] === false
        )

        return filterChats.length || ''
      }
    },
    ...mapState(['user_data', 'auth']),
    ...mapGetters(['getDicts'])
  },

  watch: {
    chat(value) {
      if (value.id) {
        this.$bind(
          'chats',
          db
            .collection(`modules`)
            .doc(this.auth.id)
            .collection('chats')
            .doc(value.id)
            .collection('messages')
            .orderBy('created')
        )

        setTimeout(() => {
          this.scrollChat()
        }, 0)
      } else {
        this.$bind(
          'chats',
          db
            .collectionGroup('messages')
            .where('id_module', '==', this.auth.id)
            .orderBy('created')
        )
        this.getPersonsLastMessage()
      }
    },
    chats(value) {
      if (this.chat.id) {
        this.$nextTick(() => {
          this.readMsg({id: this.chat.id})
          this.scrollChat()
        })
      }
    }
  },
  created() {
    this.$mqtt.subscribe(`CHATS`)
    this.$mqtt.subscribe(`TASKS`)
    this.init()
    this.timer = setInterval(() => {
      this.setDiffTimers()
    }, 1000)
  },
  mounted() {
    this.getPersonsLastMessage()
    this.$mqtt.subscribe('test123')
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

  methods: {
    async init() {
      // this.lists.chats = this.getDicts('chats')
      await this.setDrivers()
      if (this.auth.acc_role === 'user') {
        const findPerson = this.getDicts('persons').find(person => person.id === this.user_data.id)
        this.enterPersonChat(findPerson)
      }
      this.ready = true
    },
    getPersonsLastMessage() {
      this.getPersons.forEach(async person => {
        const {message = null, created = null} = (await this.getLastMessage(person)) ?? {}
        this.$set(person, 'message', message)
        this.$set(person, 'time', created)
      })
    },
    async setDrivers() {
      const cardInfo = id_card => {
        const findCard = this.getDicts('cards').find(card => card.id === id_card)
        return findCard
      }

      // const getAmountMessages = id => {
      //   const filterChats = this.getDicts('chats').filter(chat => chat.id_person === id && chat.jdata.is_read === false && chat.id_user !== this.user_data.id)
      //   return filterChats.length
      // }

      const getCountViolation = id => {
        const findPerson = this.getDicts('persons').find(person => person.id === id)
        return findPerson.jdata.count_violation
      }

      const filterViolGroups = this.getDicts('groups').filter(
        group => group.type_group === 'viol_calc'
      )
      for await (const group of filterViolGroups) {
        const {data} = await HTTP.post('cars_list', {
          id_group: group.id,
          dateday: moment(this.day).format('YYYY-MM-01')
        })

        data
          // .filter(item => item.is_in)
          .forEach(driver => {
            const card = cardInfo(driver.id_card)
            const count_violation = getCountViolation(driver.id_person)

            this.lists.drivers.push({
              ...driver,
              jdata: {
                ...driver.jdata,
                first_datepoint: moment(driver.first_datepoint).format('HH:mm:ss'),
                telnumber: card ? card.jdata.telnumber : '',
                gosnumber: card ? card.jdata.gosnumber : '',
                // amount_messages: getAmountMessages(driver.id_person),
                count_violation
              },
              id_group: group.id
            })
          })
      }
    },
    async getReport() {
      // const findPerson = this.getDicts('access').find(account => account.user_login === this.user_data.user_login)
      const filterViolGroups = this.getDicts('groups').filter(
        group => group.type_group === 'viol_calc'
      )
      for await (const group of filterViolGroups) {
        await HTTP.post('persons_events', {
          id_group: group.id,
          // id_person: findPerson.id_person,
          date_from: moment(this.day).format('YYYY-MM-01'),
          date_to: moment(this.day).format('YYYY-MM-DD')
        })
      }
    },
    async sendMsg() {
      if (this.msg.length === 0) return

      if (this.chat.type_msg === 'group') {
        await this.sendGroupMsg()
        return
      }
      // .replace(/(\r\n|\n|\r)/gm," ")
      const obj = {
        id: uuidv4(),
        created: Timestamp.now(),
        id_module: this.auth.id,
        id_person: this.user_data.id,
        message: this.msg,
        is_read_by_admin: ['admin', 'operator', 'manager'].includes(this.auth.acc_role),
        is_read_by_user: ['user'].includes(this.auth.acc_role)
      }
      await db
        .collection(`modules`)
        .doc(this.auth.id)
        .collection('chats')
        .doc(this.chat.id)
        .collection('messages')
        .doc(obj.id)
        .set(obj)
      // await HTTP.put('chats', obj)
      this.msg = ''
    },
    async sendGroupMsg() {
      const findPerson = id => {
        if (this.chat.send_all) {
          return this.getDrivers.some(driver => driver.id_person === id)
        }
        return this.getDrivers.some(driver => driver.id_person === id && driver.is_in)
      }

      const {data} = await HTTP.post('groups_persons')
      const groupId = this.chat.id
      const personsList = data.filter(
        link => link.id_group === groupId && findPerson(link.id_person)
      )
      // .replace(/(\r\n|\n|\r)/gm," ")
      const options = {
        id: uuidv4(),
        created: Timestamp.now(),
        id_module: this.auth.id,
        message: this.msg,
        id_group: groupId,
        send_all: this.chat.send_all,
        id_person: this.user_data.id
      }
      await db
        .collection(`modules`)
        .doc(this.auth.id)
        .collection('chats')
        .doc(this.chat.id)
        .collection('messages')
        .doc(options.id)
        .set(options)

      personsList.forEach(person => {
        const obj = {
          id: uuidv4(),
          created: Timestamp.now(),
          id_module: this.auth.id,
          id_person: this.user_data.id,
          message: this.msg,
          is_read_by_admin: ['admin', 'operator', 'manager'].includes(this.auth.acc_role),
          is_read_by_user: false
        }

        db.collection(`modules`)
          .doc(this.auth.id)
          .collection('chats')
          .doc(person.id)
          .collection('messages')
          .doc(obj.id)
          .set(obj)
      })
      this.msg = ''
    },
    setDiffTimers() {
      const setDiffTimer = driver => {
        const findGroup = this.getDicts('groups').find(
          group => driver.id_group === group.id && group.type_group === 'viol_calc'
        )
        if (!findGroup || !findGroup.jdata.time_parking) return 0
        const [hours, minutes] = findGroup.jdata.time_parking.split(':')
        const from = moment(driver.first_datepoint).add({hours, minutes})
        const now = moment()
        if (from.diff(now) > 0) {
          return moment(from.diff(now))
            .utc()
            .format('HH:mm:ss')
        }
        return 0
      }

      this.lists.drivers.forEach(driver => {
        this.$set(driver.jdata, 'timer', setDiffTimer(driver))
      })
    },
    scrollChat() {
      const chatScroll = this.$refs?.chat?.$el?.scrollHeight
      if (chatScroll) {
        this.$refs.chat.$el.scroll(0, chatScroll)
      }
    },
    async openDoor() {
      const findCard = this.getDicts('cards').find(card => card.numcard === this.search.code)
      const findPerson = this.getDicts('persons').find(person => person.id === findCard.id_person)
      const obj = {
        id_card: findCard.id,
        id_person: findPerson.id,
        // id_tsensor: '79dbfe98-c041-4172-bd8d-7e1144f1f8d2'
        id_tsensor: '84c8048e-aa6b-4089-b376-3bd012c7de66'
        // 13370306
      }
      const {data} = await HTTP.put('doors_open', obj)

      this.snackbar.value = true
      if (data.status === 'error') {
        this.snackbar.text = 'Дверь не открылась'
      } else {
        this.snackbar.text = `Дверь открыта жителем ${findPerson.name}`
      }
    },
    sendCardToExtension(card) {
      this.pass_card = {...card}
    },
    async extendPass() {
      delete this.pass_card.id_person
      delete this.pass_card.id_flat
      await HTTP.put('cmd_save?component=cards', this.pass_card)
      this.$store.commit('PUT_ITEM', {
        ...this.pass_card,
        jdata: {
          ...this.pass_card.jdata,
          component: 'cards'
        }
      })
      this.pass_card = {}
    },
    async createNewCard() {
      const card = {
        id_groups: this.newCard.id_groups,
        task_name: 'add_person',
        access: {
          acc_role: 'user_creator'
        },
        persons: {
          phone: this.newCard.phone,
          name: this.newCard.name,
          type_person: 'resident'
        },
        cards: {
          week: 127,
          no_time: false,
          date_from: this.newCard.date_from,
          date_to: this.newCard.date_to,
          jdata: {
            gosnumber: this.newCard.gosnumber
          }
        }
      }
      const {date} = await HTTP.put('tasks_run', card)
      this.newCard = {}
      this.dialogs.newCard = false
    },
    enterChat(driver) {
      const findPerson = this.getDicts('persons').find(person => person.id === driver.id_person)
      const filterChats = this.chats.filter(
        chat => chat.id_person === driver.id_person && this.auth.acc_role === 'admin'
      )

      this.chat = findPerson
    },
    enterPersonChat(person) {
      this.chat = person
      this.readMsg(person)
    },
    formatTime(time) {
      const seconds = time?.seconds
      if (!seconds) return null
      return moment(seconds * 1000).format('HH:mm')
    },
    setUtcDate(obj, prop) {
      const date = obj[prop]
      const utc = moment(date)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss')
      obj[prop] = utc
    },
    localDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
      if (!date) return ''
      const local = moment
        .utc(date)
        .local()
        .format(format)
      return local
    },
    async getLastMessage(person) {
      const messages = await db
        .collection(`modules`)
        .doc(this.auth.id)
        .collection('chats')
        .doc(person.id)
        .collection('messages')
        .orderBy('created')
        .get()

      if (messages) {
        const result = messages.docs.map(doc => doc.data())
        // console.log(result)
        return result[result.length - 1]
      }
      return {}
    },
    readMsg(person) {
      let role
      if (['admin', 'operator', 'manager'].includes(this.auth.acc_role)) {
        role = 'is_read_by_admin'
      } else {
        role = 'is_read_by_user'
      }

      this.chats
        .filter(
          chat =>
            chat.id_person === person.id && chat[role] === false && chat.id_module === this.auth.id
        )
        .forEach(chat => {
          db.collection(`modules`)
            .doc(this.auth.id)
            .collection('chats')
            .doc(person.id)
            .collection('messages')
            .doc(chat.id)
            // .get()
            .set({
              ...chat,
              [role]: true
            })
        })
    }
  }
}
</script>
