<template>
  <v-layout class="posr">
    <!-- <div id="map" style="max-height: 100vh; height: calc(100vh - 300px); position: absolute; top: 0; width: 100%"></div> -->
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      style="max-height: 100vh; height: calc(100vh - 300px); position: absolute; top: 0; width: 100%"
    >
      <l-tile-layer :url="url"></l-tile-layer>
      <!-- <l-control-zoom position="topright"></l-control-zoom> -->
      <l-control-scale position="bottomleft" :imperial="true" :metric="false"></l-control-scale>
    </l-map>
  </v-layout>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
  LImageOverlay,
  LGeoJson
} from 'vue2-leaflet'

import('leaflet')
import('leaflet-draw')

export default {
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    // LPolyline,
    // LLayerGroup,
    // LTooltip,
    // LPopup,
    // LControlZoom,
    // LControlAttribution,
    LControlScale
    // LControlLayers,
    // LImageOverlay,
    // LGeoJson
  },

  props: {
    component: {
      type: String
    },
    secondComponent: {
      type: String
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 5,
      center: [54.559322, -5.767822],
      editableLayers: null,
      options_draw: {}
    }
  },

  watch: {
    options(value) {
      this.editableLayers.clearLayers()
      this.drawPicture()
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      // SRID=4326;
      const map = this.$refs.map.mapObject

      this.editableLayers = new L.FeatureGroup()
      map.addLayer(this.editableLayers)

      setTimeout(() => {
        map.invalidateSize()
      }, 0)

      map.on(L.Draw.Event.CREATED, e => {
        const type = e.layerType
        const {layer} = e

        this.editableLayers.clearLayers()
        this.editableLayers.addLayer(layer)

        let stringifyCoords
        if (['rectangle', 'polygon'].includes(type)) {
          stringifyCoords = this.stringifyLatlon(layer._latlngs[0], type)
        }

        if (['circle', 'marker'].includes(type)) {
          stringifyCoords = this.stringifyLatlon([layer._latlng.lat, layer._latlng.lng], type)
        }

        if (['polyline'].includes(type)) {
          stringifyCoords = this.stringifyLatlon(layer._latlngs, type)
        }

        this.$emit('update:options', {
          ...this.options,
          type_latlon: type,
          latlon: stringifyCoords,
          jdata: {
            ...this.options.jdata,
            layer_options: {
              ...layer.options
            }
          }
        })
      })

      map.on(L.Draw.Event.EDITED, e => {
        const {layers} = e
        layers.eachLayer(layer => {
          let stringifyCoords
          if (['rectangle', 'polygon'].includes(this.options.type_latlon)) {
            stringifyCoords = this.stringifyLatlon(layer._latlngs[0], this.options.type_latlon)
          }

          if (['circle', 'marker'].includes(this.options.type_latlon)) {
            // console.log(layer.options.radius)
            stringifyCoords = this.stringifyLatlon(
              [layer._latlng.lat, layer._latlng.lng],
              this.options.type_latlon
            )
          }

          if (['polyline'].includes(this.options.type_latlon)) {
            stringifyCoords = this.stringifyLatlon(layer._latlngs, this.options.type_latlon)
          }

          // console.log(type, stringifyCoords)

          this.$emit('update:options', {
            ...this.options,
            latlon: stringifyCoords,
            jdata: {
              ...this.options.jdata,
              layer_options: {
                ...layer.options
              }
            }
          })
        })
      })

      map.on(L.Draw.Event.DELETED, e => {
        this.$emit('update:options', {
          ...this.options,
          latlon: null,
          jdata: {
            ...this.options.jdata,
            layer_options: {}
          }
        })
      })

      map.on('zoom', e => {
        map.invalidateSize()
      })

      this.addDrawToolbar()
      this.drawPicture()
    },
    addDrawToolbar() {
      const MyCustomMarker = L.Icon.extend({
        options: {
          shadowUrl: null,
          iconAnchor: new L.Point(12, 12),
          iconSize: new L.Point(24, 24),
          iconUrl: 'https://pngimg.com/uploads/zombie/zombie_PNG65.png'
        }
      })
      const options = {
        position: 'topright',
        draw: {
          polyline: {
            shapeOptions: {
              color: '#f357a1',
              weight: 10
            }
          },
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: "<strong>Oh snap!<strong> you can't draw that!" // Message that will show when intersect
            },
            shapeOptions: {
              color: '#bada55'
            }
          },
          circle: true, // Turns off this drawing tool
          rectangle: {
            shapeOptions: {
              clickable: false
            }
          },
          marker: {
            icon: new MyCustomMarker()
          },
          circlemarker: false
        },
        edit: {
          featureGroup: this.editableLayers // REQUIRED!!
          // remove: true,
          // edit: true
        }
      }
      this.options_draw = options

      const map = this.$refs.map.mapObject
      const drawControl = new L.Control.Draw(options)
      map.addControl(drawControl)
    },
    drawPicture() {
      if (this.options.latlon) {
        const map = this.$refs.map.mapObject
        const coords = this.parseLatlon(this.options.latlon, this.options.type_latlon)
        console.log(coords)
        const currentFigure = new L[this.options.type_latlon](coords, {
          ...this.options.jdata.layer_options,
          icon: this.options_draw.draw.marker.icon
        })
        this.editableLayers.addLayer(currentFigure)
        if (typeof coords[0] === 'object') {
          map.setView(coords[0])
        } else {
          map.setView(coords)
        }
      }
    },
    parseLatlon(latlon, type) {
      if (['rectangle', 'polygon'].includes(type)) {
        const coords = latlon
          .replace(/SRID=4326;POLYGON\(\((.+)\)\)/, '$1')
          .split(/,\s?/)
          .map(coord => coord.split(' '))
        // console.log(coords)
        return coords
      }
      if (['circle', 'marker'].includes(type)) {
        const coords = latlon.replace(/SRID=4326;POINT\((.+)\)/, '$1').split(/ /)
        // console.log(coords[0])
        return coords
      }
      if (['polyline'].includes(type)) {
        const coords = latlon
          .replace(/SRID=4326;LINESTRING\((.+)\)/, '$1')
          .split(/,\s?/)
          .map(coord => coord.split(' '))
        // console.log(coords)
        return coords
      }
      return null
    },
    stringifyLatlon(latlon, type) {
      if (['rectangle', 'polygon'].includes(type)) {
        let coords = latlon
          .map(coord => {
            const [x, y] = [coord.lat, coord.lng]
            return `${x} ${y}`
          })
          .join(', ')
        coords = `${coords}, ${latlon[0].lat} ${latlon[0].lng}`
        return `SRID=4326;POLYGON((${coords}))`
      }
      if (['circle', 'marker'].includes(type)) {
        const [x, y] = [latlon[0], latlon[1]]
        const coords = `SRID=4326;POINT(${x} ${y})`
        return coords
      }
      if (['polyline'].includes(type)) {
        const coords = latlon
          .map(coord => {
            const [x, y] = [coord.lat, coord.lng]
            return `${x} ${y}`
          })
          .join(', ')
        return `SRID=4326;LINESTRING(${coords})`
      }
      return null
    }
  }
}
</script>

<style>
.leaflet-draw-toolbar.leaflet-control-toolbar {
  margin-top: 0;
}
</style>
