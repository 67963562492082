<template>
  <div id="app">
    <v-app class="overflow-y-auto" :class="getThemeColor">
      <div :class="[getBgGlobalSmis, getBgGlobal]" class="main__bg"></div>

      <template v-if="ready">
        <custom-toolbar v-if="$route.path !== '/auth'"> </custom-toolbar>

        <custom-drawer
          v-if="canShowDrawer"
          :component="navigation.main_component"
          :mobile="mobileVersion"
        >
        </custom-drawer>

        <v-content class="main">
          <v-container fluid class="px-2">
            <v-layout>
              <router-view
                v-if="mobileVersion === false"
                class="main__router-view px-1"
                name="main"
                data-name="main"
                :component="navigation.main_component"
                :secondComponent="navigation.second_component"
                :mobile="mobileVersion"
              />
              <router-view
                v-if="mobileVersion === false"
                class="main__router-view px-1"
                name="second"
                data-name="second"
                :component="navigation.main_component"
                :secondComponent="navigation.second_component"
                :mobile="mobileVersion"
              />
              <router-view
                v-if="mobileVersion === true"
                class="main__router-view px-1"
                name="mobile"
                :component="navigation.main_component"
                :secondComponent="navigation.second_component"
                :mobile="mobileVersion"
              />
            </v-layout>
          </v-container>
        </v-content>
      </template>

      <template v-else>
        <div class="d-flex justify-center align-center fill-height">
          <v-progress-circular
            indeterminate
            color="main_color"
            size="100"
            width="15"
          ></v-progress-circular>
        </div>
      </template>
    </v-app>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import auth from '@/mixins/auth'
import CustomToolbar from '@/components/custom/toolbar'
import CustomDrawer from '@/components/custom/drawer'

import {db} from '@/components/db.js'

export default {
  name: 'App',

  components: {
    CustomToolbar,
    CustomDrawer
  },

  mixins: [auth],

  mqtt: {
    'companys/#/#': function(data, topic) {
      this.setElement(data, topic)
    },
    'modules/#/#': function(data, topic) {
      this.setElement(data, topic)
    }
  },

  data() {
    return {
      navigation: {
        main_component: '',
        second_component: ''
      },
      mobileVersion: false,
      ready: false
    }
  },
  computed: {
    getBgGlobalSmis() {
      if (this.$route.path === '/monitoring') {
        return 'main__bg--smis'
      }
      return 'main__bg--acs'
    },
    getBgGlobal() {
      if (this.$route.path === '/persons_list') {
        return 'window__bg--white'
      }
      return 'window__bg--grey'
    },
    canShowDrawer() {
      return this.$route.path !== '/auth' && this.auth.acc_role !== 'guest'
    },
    ...mapGetters(['getThemeColor', 'getDicts', 'auth'])
  },

  watch: {
    async $route(to, from) {
      const routeFromAuth = () => from.path === '/auth' && to.path === '/'
      if (routeFromAuth()) {
        this.ready = false
        await this.getAccess()
      }
      this.setComponentsValues()
    }
  },
  mounted() {
    this.init()
    window.onresize = () => {
      this.setMobileVersion()
    }
  },

  methods: {
    async init() {
      this.setComponentsValues()
      await this.getAccess()
      this.setMobileVersion()
    },
    setComponentsValues() {
      const second_components = {
        persons: 'cards',
        cards: '',
        groups: 'units',
        units: 'terminals',
        terminals: 'sensors',
        sensors: 'notify_templ',
        templates: 'rtables',
        rtables: '',
        notify_templ: 'tasks',
        tsensors: 'sensors',
        devices: '',
        impdata: '',
        tasks: '',
        schedules: 'tasks',
        flats: 'persons',
        maps: 'cams'
      }
      const [full_path, route, component = 'groups'] =
        /^\/(dictionary|reports\/rtable_[\w+|-]+)\/(\w+)/.exec(this.$route.path) || []

      this.navigation.main_component = component
      this.navigation.second_component = second_components[component]
    },
    setMobileVersion() {
      const {smAndDown: mobileVersion} = this.$vuetify.breakpoint
      this.mobileVersion = mobileVersion
    },
    setElement(data, topic) {
      const decode = new TextDecoder('utf-8').decode(data)
      const json = JSON.parse(decode)
      const [company, id_company, dict, id_elem, action] = topic.split('/')

      if (this.getDicts(dict)) {
        console.log(topic, json, dict, id_elem, action)

        if (['insert', 'update'].includes(action)) {
          this.$store.commit('PUT_ITEM', {
            ...json,
            jdata: {
              component: dict
            }
          })
        } else if (['delete'].includes(action)) {
          this.$store.commit('DELETE_ITEM', {
            id: id_elem,
            dict
          })
        }
      }
    }
  }
}
</script>
