<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="$t('menu.consumption')"></dict-title>

    <div class="d-flex pt-4 overflow-hidden fill-height">
      <v-card class="d-flex flex-column elevation-2 pt-4" style="width: 100%">
        <options-info :component="component" :options="getOptionsInfo"></options-info>

        <v-tabs
          v-model="tabs.model"
          background-color="#F0EEEE"
          color="main_color"
          style="width: 100%"
          class="flex-grow-0 mb-2"
        >
          <v-tabs-slider color="main_color"></v-tabs-slider>

          <v-tab v-for="tab in tabs.items" :key="tab" style="font-size: 11px;" v-ripple="false">
            {{ $t(`consumption.${tab}`) }}
          </v-tab>

          <v-tabs-items v-model="tabs.model" class="mt-2" style="min-height: 180px">
            <v-tab-item
              :transition="false"
              :reverse-transition="false"
              v-for="tab in tabs.items"
              :key="tab"
            >
              <v-layout class="px-2">
                <v-card
                  class="mx-2 px-3 py-1"
                  v-for="tsensor in table.tsensors"
                  :key="tsensor.id_tsensor"
                >
                  <div class="d-flex justify-center">
                    <v-icon
                      style="width: 62px; height: 76px"
                      :color="tsensor.jdata.color"
                      v-text="`$consumption_${tab}`"
                    ></v-icon>
                  </div>

                  <v-layout justify-space-between align-center class="my-2">
                    <h4>{{ tsensor.text }}</h4>
                    <v-checkbox
                      class="my-0 ml-2"
                      color="main_color"
                      hide-details
                      v-model="tsensor.toggle"
                      @change="charts.line = getChartData()"
                    ></v-checkbox>
                  </v-layout>

                  <div class="d-flex justify-space-between">
                    <v-text-field
                      solo
                      single-line
                      flat
                      class="input__options"
                      v-model="tsensor.jdata.val"
                      readonly
                    >
                    </v-text-field>
                  </div>
                </v-card>
              </v-layout>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <v-card class="d-flex flex-column elevation-2 pt-4 flex-grow-1">
          <v-layout class="pt-3 px-1 flex-grow-0" justify-space-between align-center wrap>
            <v-flex>
              <v-layout class="my-0" align-center>
                <date-picker
                  v-model="date_picker.time"
                  class="px-3 black--text input__calendar"
                  type="datetime"
                  range
                  value-type="format"
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="Выберите период"
                  confirm
                  confirm-text="Применить"
                  range-separator=" - "
                  :clearable="false"
                  :shortcuts="shortcuts"
                  @confirm="changeTimeRange()"
                >
                  <template v-slot:icon-calendar>
                    <v-icon v-text="'$calendar'" size="17"></v-icon>
                  </template>
                </date-picker>
              </v-layout>
            </v-flex>
            <v-flex md5>
              <v-layout justify-end>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn class="btn__reports" @click="togglePage()" v-on="on">
                      <v-icon v-text="'$graph'"></v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('rtables.graph') }}</span>
                </v-tooltip>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-card ref="table" class="table__main elevation-0 overflow-y-auto flex-grow-1">
            <custom-table
              :table="getTableData"
              :search="search"
              v-if="page === 'table'"
            ></custom-table>
            <line-chart
              v-if="page === 'graph'"
              :chart-data="charts.line"
              style="height: 100%"
            ></line-chart>
          </v-card>
        </v-card>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import HTTP from '@/api/http'
import {mapGetters} from 'vuex'
import moment from 'moment'

import dictTitle from '@/components/custom/dicts/title'
import optionsInfo from '@/components/custom/options/info'

import LineChart from '@/components/secondComponents/LineChart'
import customTable from '@/components/custom/custom-table'
import DatePicker from 'vue2-datepicker'

import reports from '@/mixins/reports'

export default {
  components: {
    dictTitle,
    optionsInfo,
    LineChart,
    customTable,
    DatePicker
  },

  props: {
    component: {
      type: String,
      default: ''
    }
  },

  mixins: [reports],

  data() {
    return {
      tabs: {
        items: ['water', 'electr', 'gas'],
        model: null
      },
      search: '',
      charts: {
        line: {}
      }
    }
  },
  computed: {
    getTableData() {
      const {headers, items, names} = this.table

      const tsensors_ids = this.table.tsensors
        .filter(tsensor => tsensor.toggle)
        .map(tsensor => tsensor.id_tsensor)
      const filter_headers = headers.filter(({id_tsensor}) => {
        if (id_tsensor) {
          return tsensors_ids.includes(id_tsensor)
        }
        return true
      })
      const names_without_number = filter_headers
        .filter(header => header.value !== 'number')
        .map(header => header.value)

      return {
        headers: filter_headers,
        items,
        names: names_without_number,
        height: this.getTableHeight()
      }
    },
    getOptionsInfo() {
      return this.getDicts(this.component).find(
        item => item.id === this.$route.params[this.component]
      )
    },
    ...mapGetters(['getDicts', 'config'])
  },

  watch: {
    'tabs.model': async function(value, oldValue) {
      if (Number.isInteger(oldValue)) {
        await this.getReport(this.$route)
      }
    },
    '$route.params': async function(value) {
      await this.getReport(this.$route)
    }
  },
  mounted() {
    this.init()
  },

  methods: {
    async init() {
      console.log('init')
    },
    async getReport(route) {
      const [from, to] = this.date_picker.time
      this.table.headers = []
      this.table.items = []
      this.table.tsensors = []
      this.loading = true

      const options = {
        date_from: from,
        date_to: to,
        [`id_${this.component.replace(/s$/, '')}`]: route.params[this.component],
        // show_itog: false,
        // type_dict: this.component,
        // type_group: 'day',
        type_rtable: `counter_${this.tabs.items[this.tabs.model]}`
      }

      const {field} = this.$route.query
      const config_opts =
        this.config.fields[this.component].find(el => el.field === field)?.items ?? {}
      const {data} = await HTTP.post(field, {
        ...config_opts,
        ...options
      })

      if (data) {
        const header = [
          {
            value: 'number',
            sortable: false,
            text: '№'
          }
        ]

        this.table.tsensors = data.columns
          .filter(item => item.fx_name === 'last_counter')
          .map(item => ({
            ...item,
            toggle: true
          }))

        this.table.headers = header
          .concat(data.columns.filter(item => item.visible).sort((a, b) => a.forder - b.forder))
          .map(item => ({
            ...item,
            align: 'left'
          }))

        if (!data.models) {
          this.loading = false
          return
        }
        const filterItog = data.models.filter(item => item._is_itog)

        this.table.names = data.columns
          .filter(item => item.visible)
          .sort((a, b) => a.forder - b.forder)
          .map(item => item.value)
        this.table.items = data.models
          .filter(item => !item._is_itog)
          .map((item, index) => ({
            ...item,
            index: index + 1
          }))
          .concat(filterItog)
      }

      this.charts.line = this.getChartData()

      this.loading = false
    },
    getChartData() {
      const {headers, items} = this.table

      const tsensors_ids = this.table.tsensors
        .filter(tsensor => tsensor.toggle)
        .map(tsensor => tsensor.id_tsensor)
      const last_counter_headers = headers.filter(
        ({fx_name, id_tsensor}) => fx_name === 'last_counter' && tsensors_ids.includes(id_tsensor)
      )
      const {value: first_datepoint} = headers.find(({fx_name}) => fx_name === 'first_datepoint')

      const labels = items.map(item => moment(item[first_datepoint]).format('YYYY-MM-DD'))
      const datasets = last_counter_headers.map(({text: label, value, jdata: {color}}) => {
        const data = items.map(item => item[value])
        return {
          label,
          backgroundColor: color,
          opacity: 0.5,
          data
        }
      })

      return {
        labels,
        datasets
      }
    },
    getRandomRgba() {
      const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
      const randomByte = () => randomNumber(0, 255)
      const randomCssRgba = () =>
        `rgba(${[randomByte(), randomByte(), randomByte(), 0.4].join(',')})`
      return randomCssRgba()
    }
  }
}
</script>
