<template>
  <v-layout fill-height>
    <v-flex class="px-2 overflow-hidden">
      <v-layout>
        <v-flex md8 class="pr-2 pt-3">
          <v-card>
            <v-layout class="py-1 px-3" align-center wrap>
              <v-flex md3>
                <v-layout justify-space-between>
                  <v-btn
                    class="btn__reports"
                    :class="false ? 'btn__reports--border' : ''"
                    text
                    @click.stop
                  >
                    {{ $t('meteo.month') }}
                  </v-btn>
                  <v-btn
                    class="btn__reports"
                    :class="false ? 'btn__reports--border' : ''"
                    text
                    @click.stop
                  >
                    {{ $t('meteo.quarter') }}
                  </v-btn>
                  <v-btn
                    class="btn__reports"
                    :class="false ? 'btn__reports--border' : ''"
                    text
                    @click.stop
                  >
                    {{ $t('meteo.half_year') }}
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>

            <line-chart :labels="labels" :datasets="datasets"></line-chart>
          </v-card>
        </v-flex>

        <v-flex md4 class="pl-2">
          <v-layout column fill-height>
            <dict-title :title="$t('meteo.map')"></dict-title>

            <v-card class="overflow-y-auto fill-height mt-3 pt-6"> </v-card>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-card
        style="margin: 15px 1px 0; min-height: 60px; max-height: calc(100vh - 571px); width: 100%; overflow-y: auto"
      >
        <v-card
          v-for="item in geoUnits"
          :key="item.id"
          style="width: 100%; padding: 13px 8px"
          class="my-2"
        >
          <v-layout align-center>
            <v-btn
              class="ma-0 btn__icon--remove"
              small
              icon
              v-ripple="false"
              text
              @click.stop="toggleShowingGraph(item)"
            >
              <v-icon
                v-if="item.jdata.show_graph === true"
                v-text="'$vuetify.icons.meteo_show_eye'"
              ></v-icon>
              <v-icon
                v-if="item.jdata.show_graph === false"
                v-text="'$vuetify.icons.meteo_hidden_eye'"
              ></v-icon>
            </v-btn>
            <span
              style="width: 80px; font-weight: bold; font-size: 16px; margin-left: 28px"
              :style="{color: item.jdata.color}"
              >{{ item.name }}</span
            >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{on}">
                <v-text-field
                  v-on="on"
                  solo
                  single-line
                  flat
                  hide-details
                  class="input__options meteo__input"
                  style="width: 135px; font-size: 16px;"
                  v-model="item.jdata.date_from"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.jdata.date_from"
                @input="setDatasets()"
                no-title
                scrollable
                first-day-of-week="1"
                locale="ru-Ru"
                :color="$vuetify.theme.main_color"
              >
              </v-date-picker>
            </v-menu>

            <v-layout justify-space-around class="px-3">
              <div
                style="max-width: 200px"
                class="d-flex px-2"
                v-for="(field, index) in ['temperature', 'humidity', 'pressure', 'precipitation']"
                :key="index"
              >
                <label class="d-flex" style="cursor: pointer">
                  <v-layout>
                    <v-checkbox
                      hide-details
                      class="mt-0 pt-0 meteo__checkbox"
                      v-ripple="false"
                      color="main_color"
                    ></v-checkbox>
                  </v-layout>
                  <v-layout column align-center>
                    <span>{{ $t(`master.units.${field}`) }}</span>
                    <span style="color: rgba(73, 80, 87, .5)">40</span>
                  </v-layout>
                </label>
              </div>
            </v-layout>

            <v-layout align-center class="mt-0 ml-2" justify-end style="max-width: 24px">
              <v-btn
                class="ma-0 btn__icon--remove"
                small
                icon
                v-ripple="false"
                text
                @click.stop="removeGeoUnit(item)"
              >
                <v-icon v-text="'$vuetify.icons.remove'"></v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
        </v-card>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import moment from 'moment'
import lineChart from '@/components/secondComponents/LineChart'
import dictTitle from '@/components/custom/dicts/title'
import HTTP from '@/api/http'

export default {
  components: {
    dictTitle,
    lineChart
  },

  props: {
    component: {
      type: String,
      default: 'units'
    }
  },

  data() {
    return {
      activeGeoUnits: [],
      colors: ['green', 'yellowgreen', 'grey', 'red', 'violet'],
      labels: [],
      datasets: []
    }
  },
  computed: {
    postUnits() {
      return this.getDicts('units')
    },
    geoUnits() {
      return this.activeGeoUnits
    },
    ...mapState(['auth', 'navigation', 'user_data']),
    ...mapGetters(['getDicts'])
  },

  methods: {
    setDatasets() {
      this.labels = []
      this.datasets = []
      this.activeGeoUnits.forEach(geounit => {
        const activeSensors = Object.keys(geounit.checkboxes).filter(
          checkbox => geounit.checkboxes[checkbox] === true && geounit.jdata.show_graph
        )
        activeSensors.forEach(sensor_name => {
          this.getSensorHistory(geounit, sensor_name)
        })
      })
    },
    async getSensorHistory(geounit, sensor_name) {
      const {data} = await HTTP.post('jr_tsensors', {
        date_from: moment(geounit.jdata.date_from).format('YYYY-MM-DD'),
        date_to: moment()
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        id_tsensor: geounit.jdata[sensor_name]
      })
      const {models} = data
      const geounit_options = {
        data: models.map(model => model.val),
        label: geounit.name,
        borderColor: geounit.jdata.color,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 0,
        showLines: false
        // borderDash: [10, 5, 10]
      }

      if (this.labels.length === 0) {
        this.labels = models.map(model => moment(model.datepoint).format('HH:mm'))
      }
      this.datasets.push(geounit_options)
    },
    addGeoUnit(unit) {
      const randomColor = () => {
        const color = Math.floor(Math.random() * this.colors.length)
        return this.colors[color]
      }
      // const findActiveGeoUnit = this.activeGeoUnits.find(activeUnit => activeUnit.id === unit.id)
      // if (findActiveGeoUnit) return
      this.activeGeoUnits.push({
        ...unit,
        jdata: {
          ...unit.jdata,
          show_graph: true,
          date_from: moment().format('YYYY-MM-DD'),
          color: randomColor()
        },
        checkboxes: {
          temperature: false,
          humidity: false,
          pressure: false,
          precipitation: false
        }
      })
      this.setDatasets()
    },
    removeGeoUnit(unit) {
      this.activeGeoUnits = this.activeGeoUnits.filter(activeUnit => activeUnit.id !== unit.id)
      this.setDatasets()
    },
    toggleShowingGraph(unit) {
      unit.jdata.show_graph = !unit.jdata.show_graph
      this.setDatasets()
    }
  }
}
</script>
