import HTTP from '@/api/http'

const mutations = {
  UPDATE_MODULE: (state, {rootState, auth}) => {
    rootState.auth = {...rootState.auth, ...auth}
  },
}

const actions = {
  getModule: async ({rootState, commit}) => {
    const {data: auth} = await HTTP.post('module')
    commit('UPDATE_MODULE', {rootState, auth})
    // document.title = rootState.auth.type_module.toUpperCase()
  },
  getCompOpts: async ({rootState}) => {
    const {data: options} = await HTTP.get('component')
    rootState.options = options
  },
  getCompany: async ({rootState}) => {
    const {data: company} = await HTTP.post('company')
    rootState.company_data = {...rootState.company_data, ...company}
  },
  getConfigFields: async ({rootState}) => {
    const {data: config} = await HTTP.get('config')
    rootState.config_fields = config
  },
  getUser: async ({rootState}) => {
    const {data: user} = await HTTP.post('user')
    rootState.user_data = {...user}
  },
}

export default {
  namespaced: true,
  mutations,
  actions,
}
