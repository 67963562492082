<template>
  <div class="temp px-2 posr">
    <div class="simcir-help mt-3">
      {{ getCircuit }}
    </div>
    <v-btn class="btn__save btn__simcir--save px-3" text>
      <span class="subtitle-1 text-none">{{ $t('save') }}</span>
    </v-btn>
    <v-dialog
      v-model="dialogs.simcir"
      hide-overlay
      class="posa"
      width="300"
      content-class="elevation-0"
    >
      <v-card class="simcir__dialog" dark v-show="device.info">
        <v-card-text>
          <span class="simcir__info">{{ device.info }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
// import {init} from '@/simcir/simcir-init'
import HTTP from '@/api/http'

export default {
  props: {
    component: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      circuit: {
        width: 0,
        height: 0
      },
      dialogs: {
        simcir: false
      },
      device: {
        info: ''
      }
    }
  },
  computed: {
    getCircuit() {
      const findElem = this.getDicts(this.component).find(
        elem => elem.id === this.$route.params[this.component]
      )
      return JSON.stringify({
        ...this.circuit,
        ...findElem.jdata
      })
    },
    ...mapGetters(['config', 'getDicts'])
  },

  watch: {
    '$route.params': function(obj) {
      document.querySelector('.simcir-help').innerHTML = this.getCircuit
      this.initSimcir()
    }
  },
  mounted() {
    this.setCircuitSize()
    this.initSimcir()
    this.listenerCustomEvents()
  },

  methods: {
    setCircuitSize() {
      // this.circuit.width = window.innerWidth - 100;
      // this.circuit.height = window.innerHeight - 60;
      const width = document.querySelector('.temp').clientWidth
      this.circuit.width = width - 20
      this.circuit.height = 600
    },
    async initSimcir() {
      import('@/simcir/simcir-init').then(async simcir => {
        const element =
          this.getDicts(this.component).find(
            elem => elem.id === this.$route.params[this.component]
          ) || {}
        const {data: devices} = await HTTP.post(`dv_${this.component}`, {
          [`id_${this.component.replace(/s$/, '')}`]: element.id
        })

        simcir.default(element, this.$store, devices, this.component)
      })
    },
    async getDeviceData(device_id) {
      const terminal_id = this.$route.params[this.component]
      const {data} = await HTTP.post('key_redis', {
        key: `${terminal_id}.${device_id}`
      })
      this.device.info = data
    },
    listenerCustomEvents() {
      let timeout = null
      let id = null

      document.addEventListener('getDeviceData', e => {
        e.stopPropagation()
        clearTimeout(timeout)
        const {device_id} = e.detail
        if (id !== device_id) {
          id = device_id
          this.getDeviceData(device_id)
        }
        this.dialogs.simcir = true
      })

      document.addEventListener('removeDeviceData', e => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          this.dialogs.simcir = false
        }, 2000)
      })
    }
  }
}
</script>

<style scoped>
.code {
  margin: 16px 0px 16px 0px;
  padding: 4px;
  border-width: 0px 0px 0px 4px;
  border-color: #cccccc;
  border-style: solid;
  background-color: #f0f0f0;
  white-space: pre;
}

.note {
  color: #0000ff;
}

.simcir__dialog {
  position: absolute;
  top: 260px;
  right: 36px;
  max-width: 350px;
}

.simcir__info {
  font-size: 12px;
  line-height: 14px;
  white-space: pre-wrap;
}
</style>
