<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="$t('gantt.title')"></dict-title>

    <div class="d-flex overflow-hidden pt-4 fill-height">
      <v-card class="d-flex flex-column elevation-2 pt-4 flex-grow-1">
        <v-layout
          class="pt-3 px-3 flex-grow-0 overflow-scroll"
          justify-space-between
          align-center
          wrap
        >
          <g-gantt-chart
            :chart-start="chartStart"
            :chart-end="chartEnd"
            :grid="grid"
            :hide-timeaxis="hideTimeaxis"
            :push-on-overlap="true"
            snap-back-on-overlap
            :highlighted-hours="highlightedHours"
            :row-label-width="rowLabelWidth"
            :row-height="rowHeight"
            :theme="selectedTheme"
            locale="ru"
          >
            <template>
              <g-gantt-row
                v-for="row in rowList"
                :key="row.label"
                :label="row.label"
                :bars="row.barList"
                :highlight-on-hover="highlightOnHover"
                bar-start="date_from"
                bar-end="date_to"
              >
                <template #bar-label="{bar}">
                  <span>{{ bar.label }}</span>
                </template>
              </g-gantt-row>
            </template>
          </g-gantt-chart>
        </v-layout>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import {GGanttChart, GGanttRow} from 'vue-ganttastic'
import dictTitle from '@/components/custom/dicts/title'
import {mapGetters} from 'vuex'
import HTTP from '@/api/http'
import moment from 'moment'

export default {
  components: {
    GGanttChart,
    GGanttRow,
    dictTitle,
  },

  props: {
    component: {
      type: String,
    },
  },

  data: () => ({
    chartStart: null,
    chartEnd: null,
    pushOnOverlap: true,
    grid: false,
    rowHeight: 24,
    rowLabelWidth: '15%',
    hideTimeaxis: false,
    highlightOnHover: false,
    hours: [...Array(24).keys()],
    highlightedHours: [],
    showContextmenu: false,
    contextmenuTimeout: null,
    contextmenuX: 0,
    contextmenuY: 0,
    selectedTheme: 'default',
    themes: [
      'default',
      'vue',
      'dark',
      'material-blue',
      'creamy',
      'slumber',
      'sky',
      'crimson',
      'grove',
      'fuchsia',
      'flare',
    ],
    ganttBarConfig: {
      color: 'white',
      backgroundColor: '#389667',
      immobile: true,
      // opacity: 0.5,
    },
    rowList: [],
  }),

  computed: {
    ...mapGetters(['getDicts', 'config']),
  },

  beforeMount() {
    this.getGanttsData()
  },

  methods: {
    getRequestOptions() {
      const [date_from, date_to] = [this.chartStart, this.chartEnd]
      const {items = {}, field: request} = this.config.fields[this.component].find(
        ({tag}) => tag === 'top-btn-gantt'
      )
      const id = `id_${this.component.slice(0, -1)}`

      return {request, date_from, date_to, [id]: this.$route.params[this.component], ...items}
    },

    async getGanttsData() {
      const {request, ...options} = this.getRequestOptions()
      const {data} = await HTTP.post(request, options)

      if (data.models) {
        let minDate = null
        let maxDate = null

        const updateMinMaxDates = (from, to) => {
          if (minDate === null || from < minDate) {
            minDate = from
          }

          if (maxDate === null || to > maxDate) {
            maxDate = to
          }
        }

        const rowList = data.models
          .sort((itemA, itemB) => (itemA.date_from > itemB.date_from ? 1 : -1))
          .reduce((acc, item) => {
            const barItem = {
              date_from: moment(item.date_from).format('YYYY-MM-DD 00:00'),
              date_to: moment(item.date_to).format('YYYY-MM-DD 00:00'),
              label: '',
              ganttBarConfig: this.ganttBarConfig,
            }

            updateMinMaxDates(item.date_from, item.date_to)

            if (!acc[item.name]) {
              acc[item.name] = {
                label: item.name,
                barList: [barItem],
              }
              return acc
            }

            acc[item.name].barList.push(barItem)
            return acc
          }, {})

        this.chartStart = minDate
        this.chartEnd = maxDate
        this.rowList = Object.values(rowList)
      }
    },
  },
}
</script>
