<template>
  <v-flex md3 sm12 xs12>
    <dict-title :title="`${config.module[component].label} - ${getDicts(component).length}`">
      <v-layout justify-end fill-height>
        <v-layout class="mr-1" justify-end v-if="/dict/.test($route.path)">
          <v-btn
            class="mx-0 my-auto white--text"
            max-height="32"
            max-width="32"
            icon
            active-class="main_color main_color--text"
            :disabled="!$route.params[component]"
            :to="`/dictionary/${component}/${component.replace(/s$/, '')}_${
              $route.params[component]
            }`"
            exact
            @click="setNavigation('')"
          >
            <v-icon size="19">$build</v-icon>
          </v-btn>

          <template v-for="(item, index) in getTopBtns">
            <template v-if="item.tag === 'top-btn-debug'">
              <v-btn
                :key="index"
                class="mx-0 my-auto white--text"
                max-height="32"
                max-width="32"
                icon
                active-class="main_color main_color--text"
                :to="`/dictionary/${component}/${component.replace(/s$/, '')}_${
                  $route.params[component]
                }/debug`"
                :disabled="item.disabled"
                @click="setNavigation('debug')"
              >
                <v-icon size="20">$debug</v-icon>
              </v-btn>
            </template>

            <template v-if="item.tag === 'top-btn-counter'">
              <v-btn
                :key="index + item.tag"
                class="mx-0 my-auto white--text"
                max-height="32"
                max-width="32"
                icon
                active-class="main_color main_color--text"
                :to="`/dictionary/${component}/${component.replace(/s$/, '')}_${
                  $route.params[component]
                }/consumption?field=${item.field}`"
                :disabled="item.disabled"
                @click="setNavigation(`consumption?field=${item.field}`)"
              >
                <v-icon size="20">$dicts_reports_report</v-icon>
              </v-btn>
            </template>

            <template v-if="item.tag === 'top-btn-reports'">
              <v-btn
                :key="index"
                class="mx-0 my-auto white--text"
                max-height="32"
                max-width="32"
                icon
                active-class="main_color main_color--text"
                exact
                :to="`/dictionary/${component}/${component.replace(/s$/, '')}_${
                  $route.params[component]
                }/reports?field=${item.field.replace('#', '_')}`"
                :disabled="item.disabled"
                @click="setNavigation(`reports?field=${item.field.replace('#', '_')}`)"
              >
                <v-icon size="20">$dicts_reports_report</v-icon>
              </v-btn>
            </template>

            <template v-if="item.tag === 'top-btn-gantt'">
              <v-btn
                :key="index"
                class="mx-0 my-auto white--text"
                max-height="32"
                max-width="32"
                icon
                active-class="main_color main_color--text"
                exact
                :to="`/dictionary/${component}/${component.replace(/s$/, '')}_${
                  $route.params[component]
                }/gantt`"
                :disabled="item.disabled"
                @click="setNavigation(`gantt`)"
              >
                <v-icon size="20">mdi-waves</v-icon>
              </v-btn>
            </template>

            <template v-if="item.tag === 'top-btn-history'">
              <v-btn
                :key="index"
                class="mx-0 my-auto white--text"
                max-height="32"
                max-width="32"
                icon
                active-class="main_color main_color--text"
                :to="`/dictionary/${component}/${component.replace(/s$/, '')}_${
                  $route.params[component]
                }/history?request=${item.field}`"
                :disabled="item.disabled"
                @click="setNavigation(`history/?request=${item.field}`)"
              >
                <v-icon size="20">$history</v-icon>
              </v-btn>
            </template>

            <template v-if="item.tag === 'top-button'">
              <v-btn
                :key="index"
                class="mx-0 my-auto white--text"
                max-height="32"
                max-width="32"
                icon
                active-class="main_color main_color--text"
                :to="item.url"
                :disabled="item.disabled"
                @click="setNavigation(item.field)"
              >
                <v-icon size="20">{{ `$${item.field}` }}</v-icon>
              </v-btn>
            </template>
          </template>
        </v-layout>
      </v-layout>
    </dict-title>
    <div class="d-flex overflow-hidden fill-height pt-4">
      <v-card class="d-flex flex-column elevation-2" style="width: 100%">
        <div class="px-3 mt-2">
          <v-text-field type="search" v-model="search" color="main_color">
            <template v-slot:append>
              <v-icon size="14" class="mt-2">$search</v-icon>
            </template>
          </v-text-field>
        </div>

        <div class="overflow-y-auto dict__body flex-grow-1">
          <v-card
            class="card__dict"
            :style="getStatusColor(item)"
            :color="$route.path.includes(item.id) ? 'report_color' : 'transparent'"
            v-for="item in postItems"
            :ref="item.id"
            :key="item.id"
            @click="$router.push(getRoute(item)).catch((err) => {})"
            active-class="transparent"
            v-ripple="false"
          >
            <dict-info :component="component" :options="item"></dict-info>
          </v-card>
        </div>
      </v-card>
    </div>
  </v-flex>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import dictInfo from '@/components/custom/dicts/info'
import dictTitle from '@/components/custom/dicts/title'
import searchFilter from '@/mixins/searchFilter'

export default {
  components: {
    dictInfo,
    dictTitle,
  },

  mixins: [searchFilter],

  props: {
    component: {
      type: String,
    },
    mobile: {
      type: Boolean,
    },
  },

  data() {
    return {
      search: '',
      fields: {},
    }
  },
  computed: {
    postItems() {
      return this.getDicts(this.component).filter((item) => this.searchFields(item, this.component))
    },
    getTopBtns() {
      return this.config.fields[this.component]
        .filter((field) => field.tag.startsWith('top'))
        .map((item) => {
          const getAdditionalProps = () => {
            if (['master'].includes(item.field)) {
              return {
                url: `/dictionary/${this.component}/${item.field}`,
                disabled: false,
              }
            }

            const component_elem = this.component.replace(/s$/, '')
            return {
              url: `/dictionary/${this.component}/${component_elem}_${
                this.$route.params[this.component]
              }/${item.field}`,
              disabled: !this.$route.params[this.component],
            }
          }

          return {
            ...item,
            ...getAdditionalProps(),
          }
        })
        .sort((a, b) => (a.sort > b.sort ? 1 : -1))
    },
    ...mapState(['auth', 'navigation', 'user_data', 'company_data']),
    ...mapGetters(['getDicts', 'config', 'getOptions']),
  },

  watch: {
    component(val) {
      this.$store.dispatch('getItems', this.component)
      this.search = ''
    },
  },
  created() {
    this.$store.dispatch('getItems', this.component)
  },

  methods: {
    setNavigation(value) {
      const payload = {
        component: this.component,
        value,
      }

      if (value === 'master') return
      this.$store.commit('SET_NAVIGATION_ROUTE', payload)
    },
    getRoute(item) {
      const component_elem = this.component.split('_')[0].replace(/s$/, '')
      if (/^\/reports/.test(this.$route.path)) {
        const path = `/reports/rtable_${this.$route.params.rtables}/${this.component}/${component_elem}_${item.id}`
        return path
      }
      const path = `/dictionary/${this.component}/${component_elem.split('_')[0]}_${item.id}/${
        this.navigation[this.component].value
      }`
      return path
    },
    getStatusColor(el) {
      const {field} =
        this.config.fields[this.component].find((config) => config.tag === 'status') ?? {}

      return {
        'border-right': `8px solid ${el.jdata[field] ?? 'transparent'} !important`,
      }
    },
  },
}
</script>
