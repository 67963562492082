var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-flex',{attrs:{"md3":"","sm12":"","xs12":""}},[_c('dict-title',{attrs:{"title":`${_vm.config.module[_vm.component].label} - ${_vm.getDicts(_vm.component).length}`}},[_c('v-layout',{attrs:{"justify-end":"","fill-height":""}},[(/dict/.test(_vm.$route.path))?_c('v-layout',{staticClass:"mr-1",attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","disabled":!_vm.$route.params[_vm.component],"to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
            _vm.$route.params[_vm.component]
          }`,"exact":""},on:{"click":function($event){return _vm.setNavigation('')}}},[_c('v-icon',{attrs:{"size":"19"}},[_vm._v("$build")])],1),_vm._l((_vm.getTopBtns),function(item,index){return [(item.tag === 'top-btn-debug')?[_c('v-btn',{key:index,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                _vm.$route.params[_vm.component]
              }/debug`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation('debug')}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$debug")])],1)]:_vm._e(),(item.tag === 'top-btn-counter')?[_c('v-btn',{key:index + item.tag,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                _vm.$route.params[_vm.component]
              }/consumption?field=${item.field}`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`consumption?field=${item.field}`)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$dicts_reports_report")])],1)]:_vm._e(),(item.tag === 'top-btn-reports')?[_c('v-btn',{key:index,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","exact":"","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                _vm.$route.params[_vm.component]
              }/reports?field=${item.field.replace('#', '_')}`,"disabled":item.disabled},on:{"click":function($event){_vm.setNavigation(`reports?field=${item.field.replace('#', '_')}`)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$dicts_reports_report")])],1)]:_vm._e(),(item.tag === 'top-btn-gantt')?[_c('v-btn',{key:index,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","exact":"","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                _vm.$route.params[_vm.component]
              }/gantt`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`gantt`)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-waves")])],1)]:_vm._e(),(item.tag === 'top-btn-history')?[_c('v-btn',{key:index,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":`/dictionary/${_vm.component}/${_vm.component.replace(/s$/, '')}_${
                _vm.$route.params[_vm.component]
              }/history?request=${item.field}`,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(`history/?request=${item.field}`)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$history")])],1)]:_vm._e(),(item.tag === 'top-button')?[_c('v-btn',{key:index,staticClass:"mx-0 my-auto white--text",attrs:{"max-height":"32","max-width":"32","icon":"","active-class":"main_color main_color--text","to":item.url,"disabled":item.disabled},on:{"click":function($event){return _vm.setNavigation(item.field)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(`$${item.field}`))])],1)]:_vm._e()]})],2):_vm._e()],1)],1),_c('div',{staticClass:"d-flex overflow-hidden fill-height pt-4"},[_c('v-card',{staticClass:"d-flex flex-column elevation-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"px-3 mt-2"},[_c('v-text-field',{attrs:{"type":"search","color":"main_color"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"mt-2",attrs:{"size":"14"}},[_vm._v("$search")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"overflow-y-auto dict__body flex-grow-1"},_vm._l((_vm.postItems),function(item){return _c('v-card',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],key:item.id,ref:item.id,refInFor:true,staticClass:"card__dict",style:(_vm.getStatusColor(item)),attrs:{"color":_vm.$route.path.includes(item.id) ? 'report_color' : 'transparent',"active-class":"transparent"},on:{"click":function($event){_vm.$router.push(_vm.getRoute(item)).catch((err) => {})}}},[_c('dict-info',{attrs:{"component":_vm.component,"options":item}})],1)}),1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }