<template>
  <v-tooltip bottom :nudge-left="-300" max-width="400" :open-delay="500" :close-delay="3500">
    <template v-slot:activator="{on}">
      <v-btn
        @click="copyJson"
        v-on="on"
        class="mr-2 btn__settings white--text"
        icon
        small
        depressed
        text
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
    <pre class="options__json" style="font-size: 12px; line-height: 14px">{{ json }}</pre>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    json: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    copyJson() {
      const node = document.querySelector('.options__json')
      const range = new Range()
      if (node) {
        range.setStart(node, 0)
        range.setEnd(node, 1)
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(range)
        document.execCommand('copy')
      }
    }
  }
}
</script>
