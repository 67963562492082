<template>
  <v-layout class="posr" fill-height>
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      style="height: 100%; position: absolute; width: 100%"
    >
      <l-tile-layer :url="url"></l-tile-layer>
    </l-map>
  </v-layout>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
  LImageOverlay
} from 'vue2-leaflet'
import L from 'leaflet'
import pSocket from '@/mixins/pSocket'

export default {
  components: {
    LMap,
    LTileLayer
    // LMarker,
    // LPolyline,
    // LLayerGroup,
    // LTooltip,
    // LPopup,
    // LControlZoom,
    // LControlAttribution,
    // LControlScale,
    // LControlLayers,
    // LImageOverlay
  },

  mixins: [pSocket],

  data() {
    return {
      map: null,
      options: {
        crs: L.CRS.Simple,
        zoomControl: false
      },
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 3,
      center: [47.41322, -1.219482],
      bounds: null,
      latlng: [],
      sensorMarker: {},
      id_marker: '',
      margin: {
        x: 0,
        y: 0
      },
      onlineTerms: []
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.map = this.$refs.map.mapObject
    })
  }
}
</script>
