<template>
  <v-flex class="posr">
    <v-layout class="dict__header" align-center text-center>
      <span class="white--text mr-auto ml-2 subtitle-2" style="white-space: nowrap">{{
        title
      }}</span>
      <slot></slot>
    </v-layout>
  </v-flex>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
