// Авторизация

import {mapState, mapGetters} from 'vuex'

import Vue from 'vue'
import VueMqtt from 'vue-mqtt'

export default {
  data() {
    return {
      ready: false,
    }
  },
  computed: {
    ...mapState(['auth', 'user_data', 'company_data']),
    ...mapGetters(['getOptions', 'getThemeColors', 'config']),
  },

  methods: {
    async getAccess() {
      const res = await Promise.all([
        this.$store.dispatch('auth/getModule'),
        this.$store.dispatch('auth/getCompOpts'),
        this.$store.dispatch('auth/getCompany'),
        this.$store.dispatch('auth/getUser'),
        this.$store.dispatch('auth/getConfigFields'),
      ]).catch((err) => {
        // this.$router.push('/auth').catch(() => {})
        this.ready = true
      })

      if (!res) return

      const dicts = this.getDictionaries()

      this.setComponents()

      this.$store.dispatch('updateThemeColor', {
        $vuetify: this.$vuetify,
      })
      // this.mqttConnect()

      this.setRoutes()
      this.routeAuth()

      // this.$mqtt.subscribe(`companys/${this.company_data.id}/#`)
      // Object.keys(this.config.info).forEach((dict) => {
      //   this.$mqtt.subscribe(`modules/${this.auth.id}/${dict}/#`)
      // })

      await dicts
      this.ready = true
    },
    mqttConnect() {
      const options = {
        clientId: this.auth.id_access,
        username: this.user_data.id,
        password: this.auth.id_access,
        useSSL: true,
      }
      Vue.use(VueMqtt, 'wss://skifgate.ru/ws', options)
    },
    routeAuth() {
      const {acc_role} = this.auth
      if (['/auth', '/module'].includes(this.$route.path)) {
        location.replace(`/${acc_role}`)
        // this.$router.push(`/${acc_role}`)
      }

      const routes = {
        smis: 'monitoring',
        acs: 'tax',
        default: '',
      }

      if (this.$route.path === '/' && routes[this.auth.type_module]) {
        this.$router.push(`/${routes[this.auth.type_module]}`)
      }
    },
    setRoutes() {
      const dictionaries = require.context('@/views/dicts', true, /vue$/)
      const unics = require.context('@/views/unic', true, /vue$/)
      const secondMenu = require.context('@/views/secondMenu', false, /vue$/)

      const files = {}

      dictionaries.keys().forEach((file) => {
        const component = file.replace(/.vue$/, '').replace(/\.\//, '')
        files[component] = dictionaries(file).default
      })
      unics.keys().forEach((file) => {
        const component = file.replace(/.vue$/, '').replace(/\.\//, '')
        files[component] = unics(file).default
      })
      secondMenu.keys().forEach((file) => {
        const component = file.replace(/.vue$/, '').replace(/\.\//, '')
        files[component] = secondMenu(file).default
      })

      const dicts = Object.keys(this.getOptions)
      const children = dicts.flatMap((dict) => {
        const dict_elem = dict.replace(/s$/, '')

        return [
          {
            path: dict,
          },
          {
            path: `${dict_elem}_:${dict}`,
          },
          {
            path: `${dict}/${dict_elem}_:${dict}`,
          },
        ]
      })
      const getUnicChildren = (dict) =>
        children.filter((child) => child.path.includes(dict) === false)
      const routes = dicts.flatMap((dict) => {
        const dict_elem = dict.replace(/s$/, '')

        return [
          {
            path: `/dictionary/${dict}`,
            components: {
              main: files.Dict,
              mobile: files.Dict,
            },
          },
          {
            path: `/dictionary/${dict}/${dict_elem}_:${dict}/`,
            components: {
              main: files.Dict,
              second: files.Options,
              mobile: files.Options,
            },
            children: getUnicChildren(dict),
          },
          {
            path: `/dictionary/${dict}/master`,
            components: {
              main: files.Dict,
              second: files.Master,
              mobile: files.Master,
            },
          },
          {
            path: `/reports/rtable_:rtables/${dict}`,
            components: {
              main: files.Dict,
              mobile: files.Dict,
            },
          },
          {
            path: `/reports/rtable_:rtables/${dict}/${dict_elem}_:${dict}`,
            components: {
              main: files.Dict,
              second: files.Reports,
              mobile: files.Reports,
            },
          },
          ...secondMenu.keys().map((file) => {
            const component = file.replace(/.vue$/, '').replace(/\.\//, '')

            return {
              path: `/dictionary/${dict}/${dict_elem}_:${dict}/${component}`,
              components: {
                main: files.Dict,
                second: files[component],
                mobile: files[component],
              },
            }
          }),
          ...unics.keys().flatMap((file) => {
            const component = file.replace(/.vue$/, '').replace(/\.\//, '')

            return [
              {
                path: `/${component}`,
                components: {
                  main: files[component],
                  mobile: files[component],
                },
                children: getUnicChildren(dict),
              },
              {
                path: `/dictionary/${dict}/${dict_elem}_:${dict}/${component}`,
                components: {
                  main: files.Dict,
                  second: files[component],
                  mobile: files[component],
                },
                children: getUnicChildren(dict),
              },
            ]
          }),

          {
            path: '/maps',
            components: {
              main: () => import('@/views/others/Map'),
              mobile: () => import('@/views/others/Map'),
            },
          },
          {
            path: '/maps/:id_person',
            components: {
              main: () => import('@/views/others/Map'),
              mobile: () => import('@/views/others/Map'),
            },
          },
          {
            path: '/m/t',
            components: {
              main: () => import('@/views/others/Map'),
              mobile: () => import('@/views/others/Map'),
            },
          },
        ]
      })

      this.$router.addRoutes(routes)
    },
    setComponents() {
      this.setNavigationHistory()
      this.addDicts()
    },
    setNavigationHistory() {
      const dicts = Object.keys(this.getOptions)
      dicts.forEach((dict) => {
        this.$store.commit('ADD_NAVIGATION_HISTORY', dict)
      })
    },
    addDicts() {
      const dicts = Object.keys(this.getOptions).reduce((result, dict) => {
        result[dict] = []
        return result
      }, {})
      this.$store.commit('ADD_DICTS', dicts)
    },
    async getDictionaries() {
      const dicts = Object.keys(this.getOptions)
      for await (const dict of dicts) {
        this.$store.dispatch('getItems', dict)
      }
    },
  },
}
