<template>
  <v-flex>
    <dict-title title="Новый пропуск"></dict-title>
    <v-card
      elevation="2"
      class="mt-3 px-2 tax__chat"
      style="overflow: hidden; padding-top: 40px; height: auto; min-height: 350px"
    >
      <v-layout wrap>
        <v-flex md5 sm5 xs12>
          <p class="master__title">{{ $t('cabinet.phone') }}</p>
        </v-flex>
        <v-flex>
          <v-text-field
            solo
            single-line
            flat
            class="input__options"
            v-model="card.jdata.telnumber"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex md5 sm5 xs12>
          <p class="master__title">{{ $t('cabinet.nickname') }}</p>
        </v-flex>
        <v-flex>
          <v-text-field
            solo
            single-line
            flat
            class="input__options"
            v-model="card.jdata.name"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex md5 sm5 xs12>
          <p class="master__title">{{ $t('master.cards.gosnumber') }}</p>
        </v-flex>
        <v-flex>
          <v-text-field
            solo
            single-line
            flat
            class="input__options"
            v-model="card.jdata.gosnumber"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <v-layout justify-center class="my-4">
        <v-flex md6 sm6 xs12>
          <v-btn
            small
            @click="sendInvite()"
            depressed
            class="btn__company btn__save mx-0 my-0"
            style="width: 100%;"
          >
            {{ $t('create') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </v-flex>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import HTTP from '@/api/http'
import dictTitle from '@/components/custom/dicts/title'

export default {
  components: {
    dictTitle
  },

  data() {
    return {
      card: {
        jdata: {}
      }
    }
  },
  computed: {
    getOptions() {
      return this.$store.getters.options
    },
    ...mapState(['user_data']),
    ...mapGetters(['config', 'getDicts'])
  },

  methods: {
    async sendInvite() {
      const findCard = this.getDicts('cards').find(
        card => card.id_person === this.user_data.id_person
      )
      if (findCard) {
        const {data} = await HTTP.post('send_invite', {
          id_card: findCard.id
        })
      }
    }
  }
}
</script>
