<template>
  <v-btn class="btn__save px-3 mx-1" text @click="$emit('click')" :disabled="disabled">
    <span class="subtitle-1 text-none">{{ button.label }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    button: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
