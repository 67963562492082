<template>
  <v-layout column>
    <v-layout class="py-3" wrap>
      <v-flex
        column
        md6
        justify-start
        v-for="(item, index) in getConfigFields"
        :key="item.field + index"
        class="px-1"
      >
        <!-- Mask  -->
        <template v-if="item.tag === 'text-phone' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-text-field
            v-mask="'+7##########'"
            solo
            single-line
            flat
            class="input__options"
            :class="[item.readonly ? 'input__options--readonly' : '']"
            v-model="getModelInput(item)[item.field]"
            :readonly="item.readonly || false"
            :placeholder="item.defval"
          >
          </v-text-field>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'pdf-field' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <input type="file" ref="file" hidden @change="uploadPdf($event)" />
          <div class="d-flex align-end pb-4">
            <a
              download
              :href="`upload/${component}/${$route.params[component]}/${
                getModelInput(item)[item.field]
              }`"
            >
              <v-icon style="height: 64px; width: 64px">$pdf</v-icon>
            </a>
            <v-btn v-if="!getModelInput(item)[item.field]" small text @click="$refs.file[0].click()"
              >Загрузить</v-btn
            >
            <v-btn
              v-if="getModelInput(item)[item.field]"
              small
              text
              @click="getModelInput(item)[item.field] = null"
              >Удалить</v-btn
            >
          </div>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'text-field' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-text-field
            solo
            single-line
            flat
            class="input__options"
            :class="[item.readonly ? 'input__options--readonly' : '']"
            v-model="getModelInput(item)[item.field]"
            :readonly="item.readonly || false"
            :placeholder="item.defval"
          >
          </v-text-field>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'textarea' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <textarea
            class="input__main px-2"
            v-model="getModelInput(item)[item.field]"
            rows="5"
            style="width: 100%"
            :placeholder="item.defval"
          ></textarea>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'select-dict' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-autocomplete
            v-model="getModelInput(item)[item.field]"
            class="input__main px-2 pt-0 mt-0"
            :items="getDicts(item.items)"
            item-value="id"
            :item-text="item.item_text"
            :readonly="item.readonly || false"
            hide-details
            height="27"
            color="main_color"
            item-color="main_color"
          >
          </v-autocomplete>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'select-list' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-autocomplete
            v-model="getModelInput(item)[item.field]"
            class="input__main px-2 pt-0 mt-0"
            :items="item.items"
            item-value="key"
            item-text="label"
            :readonly="item.readonly || false"
            hide-details
            height="27"
            color="main_color"
            item-color="main_color"
          >
          </v-autocomplete>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'multi-select-dict' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-select
            v-model="getModelInput(item)[item.field]"
            :items="item.items"
            item-value="key"
            item-text="label"
            item-color="main_color"
            color="main_color"
            multiple
            hide-details
            class="input__main px-2 pt-0 mt-0"
            :readonly="item.readonly || false"
            height="27"
          >
          </v-select>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'select-filter' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-autocomplete
            v-model="getModelInput(item)[item.field]"
            class="input__main px-2 pt-0 mt-0"
            :items="getSelectFilterItems(item)"
            item-value="key"
            item-text="label"
            :readonly="item.readonly || false"
            hide-details
            height="27"
            color="main_color"
            item-color="main_color"
          >
          </v-autocomplete>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'switch' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-switch
            class="mt-0 pt-0"
            v-model="getModelInput(item)[item.field]"
            color="main_color"
            hide-details
            :class="[item.readonly ? 'input__options--readonly' : '']"
            :readonly="item.readonly || false"
          ></v-switch>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'time' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-menu offset-y :close-on-content-click="false" ref="menu">
            <template v-slot:activator="{on}">
              <v-text-field
                v-on="on"
                solo
                single-line
                flat
                class="input__options"
                :class="[item.readonly ? 'input__options--readonly' : '']"
                :value="item.is_jdata ? options.jdata[item.field] : options[item.field]"
                :readonly="item.readonly || false"
                :placeholder="item.defval"
              >
              </v-text-field>
            </template>
            <v-time-picker
              v-model="getModelInput(item)[item.field]"
              @click:minute="$refs.menu.save(options.jdata[item.field])"
              use-seconds
              format="24hr"
              color="main_color"
            >
            </v-time-picker>
          </v-menu>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'date' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <v-menu offset-y :close-on-content-click="false" ref="menu">
            <template v-slot:activator="{on}">
              <v-text-field
                v-on="on"
                solo
                single-line
                flat
                class="input__options"
                :class="[item.readonly ? 'input__options--readonly' : '']"
                :value="localDate(getModelInput(item)[item.field], 'YYYY-MM-DD')"
                :readonly="item.readonly || false"
                :placeholder="item.defval"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="getModelInput(item)[item.field]"
              no-title
              scrollable
              first-day-of-week="1"
              locale="ru-Ru"
              :color="$vuetify.theme.main_color"
              @input="setUtcDate($event, item)"
            >
            </v-date-picker>
          </v-menu>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>

        <template v-if="item.tag === 'datetime' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <date-picker
            @input="setUtcDate($event, item)"
            :value="localDate(getModelInput(item)[item.field])"
            class="test"
            type="datetime"
            value-type="format"
            format="YYYY-MM-DD HH:mm:ss"
            confirm
            confirm-text="Применить"
            :clearable="false"
          >
            <template v-slot:icon-calendar>
              <span></span>
            </template>
          </date-picker>
        </template>

        <template v-if="item.tag === 'img-field' && shouldShowComponent(item)">
          <p class="master__paragraph master__title mb-2">{{ getLabel(item.label) }}</p>
          <options-photo
            :options.sync="options"
            :component="component"
            :field="item"
          ></options-photo>
          <template v-for="button in getButtonFields(item)">
            <options-button
              v-if="shouldShowComponent(item)"
              :key="button.field"
              :button="button"
              @click="saveChangesOfItem(button)"
            >
            </options-button>
          </template>
        </template>
      </v-flex>

      <!-- <template v-if="component === 'tsensors'">
        <v-layout v-if="auth.type_module === 'smis'">
          <v-flex md2 xs6 class="px-3">
            <v-layout>
              <p class="mb-3">{{ $t('master.icon') }}</p>
              <v-icon
                v-if="options.jdata.icon"
                class="ml-1"
                v-text="`$vuetify.icons.monitoring_systems_${options.jdata.icon}`"
              ></v-icon>
            </v-layout>
          </v-flex>
          <v-flex md3 xs6 class="px-3">
            <v-input class="input__main px-2" height="25" hide-details>
              <custom-select
                v-model="options.jdata.icon"
                :items="getSensorsIcons"
                icons
                icons-src="$vuetify.icons.monitoring_systems"
              ></custom-select>
            </v-input>
          </v-flex>
        </v-layout>
      </template> -->

      <slot></slot>
    </v-layout>

    <v-snackbar top right absolute v-model="notify.show" :timeout="2000">
      <v-layout column class="px-2">
        <h2 class="mb-3 mx-auto white--text">{{ notify.message }}</h2>
        <v-flex>
          <v-layout justify-center>
            <v-btn
              color="main_color"
              text
              depressed
              @click="notify.show = false"
              class="mx-0 my-0 px-2"
            >
              <span>{{ $t('close') }}</span>
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-snackbar>
  </v-layout>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import {mask} from 'vue-the-mask'
// import customSelect from '@/components/custom/custom-select'
import optionsPhoto from '@/components/custom/options/photo'
import setListsOfElems from '@/mixins/setListsOfElems'
import optionsButton from '@/components/custom/options/button'
import DatePicker from 'vue2-datepicker'

import moment from 'moment'

export default {
  directives: {
    mask,
  },

  components: {
    // customSelect,
    optionsPhoto,
    optionsButton,
    DatePicker,
  },

  mixins: [setListsOfElems],

  props: {
    component: {
      type: String,
      default: '',
    },
    secondComponent: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    configList: {
      type: String,
      default: 'fields',
    },
  },

  computed: {
    getConfigFields() {
      const list = (this.config?.[this.configList]?.[this.component] ?? []).filter((item) =>
        [
          'text-phone',
          'pdf-field',
          'text-field',
          'textarea',
          'select-dict',
          'select-list',
          'multi-select-dict',
          'select-filter',
          'switch',
          'time',
          'date',
          'datetime',
          'img-field',
        ].includes(item.tag)
      )
      return list.sort((a, b) => (a.sort > b.sort ? 1 : -1))
    },
    getSelectFilterItems() {
      return (item) => {
        const {item_text} = item
        const findField = this.config[this.configList][this.component].find(
          (obj) => obj.field === item_text
        )

        if (this.options.id_sensor && this.component === 'tsensors') {
          const sensor = this.getDicts('sensors').find((el) => el.id === this.options.id_sensor)
          if (sensor) {
            const {type_terminal} = sensor
            return item?.items?.[type_terminal] ?? []
          }
          return []
        }

        if (findField?.item_text) {
          const findFilter = findField?.items?.[this.options[findField.item_text]].find(
            (obj) => obj.key === this.options[item_text]
          )
          if (item?.items?.[findFilter.filter]) {
            return item?.items?.[findFilter.filter]
          }
          return []
        }
        const prop = this.options[item_text]
        if (this.getDicts(prop)) {
          return this.getDicts(prop).map((elem) => ({
            key: elem.id,
            label: elem.name,
          }))
        }
        return item?.items?.[prop] || []
      }
    },
    getSensorsIcons() {
      const icons = require.context('@/icons/monitoring/systems', true, /vue$/)
      return icons.keys().map((icon) => `${icon.replace(/.vue$/, '').replace(/\.\//, '')}`)
    },
    checkToShowBtnPut() {
      const {rules} = this.getOptions[this.component]
      return !rules.includes('PUT')
    },
    getButtonFields() {
      return (element) => {
        const buttons = this.config[this.configList][this.component].filter(
          (button) => button.item_text === element.field && button.tag === 'button-field'
        )

        return buttons
      }
    },
    ...mapState(['auth', 'user_data']),
    ...mapGetters(['config', 'getDicts', 'getOptions']),
  },

  mounted() {
    this.setDefaultValues()
  },

  methods: {
    setUtcDate(value, tag) {
      const utc = moment(value).utc(true).format('YYYY-MM-DDTHH:mm:ss')
      this.getModelInput(tag)[tag.field] = utc
    },
    localDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
      if (!date) return ''

      const local = moment.utc(date).local().format(format)
      return local
    },
    setDefaultValues() {
      if (this.configList === 'master') {
        const list = this.config?.[this.configList]?.[this.component] ?? []
        list.forEach(({field, defval, is_jdata, tag, items, item_text}) => {
          const getDevaultValue = () => {
            if (tag === 'select-dict') {
              const firstElement = this.getDicts(items)[0]
              return firstElement?.id
            }

            if (tag === 'select-list') {
              const firstElement = items[0]
              return firstElement?.key
            }

            if (tag === 'select-filter') {
              const firstElement = this.getSelectFilterItems({items, item_text})[0]
              return firstElement?.key
            }

            if (tag === 'date') {
              return moment().utc(true).format('YYYY-MM-DD')
            }

            // return defval
            return null
          }

          const defaultValue = getDevaultValue()

          if (!defaultValue) return

          if (is_jdata) {
            this.$set(this.options.jdata, field, defaultValue)
          } else {
            this.$set(this.options, field, defaultValue)
          }
        })
      }
    },

    getLabel(label) {
      return label ?? 'null'
    },
  },
}
</script>

<style scoped lang="scss">
.test {
  width: 100%;
}
.test::v-deep .mx-input {
  font-size: 12px;
  height: 29px;
  border: 1px solid rgba(73, 80, 87, 0.5);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding-left: 8px;
  color: black;
}
</style>
