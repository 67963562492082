// Вебсокеты

export default {
  data() {
    return {
      websockets: []
    }
  },

  beforeDestroy() {
    this.websockets.forEach(ws => {
      ws.onclose = () => {}
      ws.onerror = () => {}
      console.log('Closed')
      ws.close(1000)
    })
  },

  methods: {
    setEventsWebsockets(ws) {
      ws.onopen = this.onOpen
      ws.onclose = this.onClose
      ws.onmessage = this.onMessage
      ws.onerror = this.onError
    },
    reconnectWebsocket(url) {
      this.websockets = this.websockets.filter(ws => ws.url !== url)
      console.log('Reconnected')
      this.createWebsocket(url)
    },
    createWebsocket(url) {
      const ws = new WebSocket(url)
      this.websockets.push(ws)
      this.setEventsWebsockets(ws)
    },
    onOpen(e) {
      console.log('Connected')
    },
    onClose(event) {
      console.log('Disconnected')
      this.reconnectWebsocket(event.target.url)
    },
    onError(e) {
      console.info(e)
    },
    sendMessage(url, message) {
      const findChannel = this.websockets.find(ws => ws.url === url)
      if (!findChannel) return
      findChannel.send(message)
    }
  }
}
