<template>
  <v-list class="mt-3 pt-8 elevation-2 pb-3">
    <v-list-item-group color="main_color">
      <v-list-item
        class="options__list statistics__list"
        @click.stop
        v-for="(item, i) in list"
        :key="i"
        :style="item.border ? 'border-bottom: 1px solid #E2E2E2' : ''"
      >
        <v-list-item-content>
          <v-list-item-title :class="item.className ? item.className : 'statistics__list--text'">{{
            item.name
          }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action class="my-0">
          <v-list-item-action-text
            :class="item.className ? item.className : 'statistics__list--text'"
            >{{ item.value }}</v-list-item-action-text
          >
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="sass">
.statistics__list
  // height: 30px

  &--text
    font-size: 18px
    color: #000

  &--text-small
    font-size: 17px !important
    color: #646464
</style>
