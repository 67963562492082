<template>
  <v-flex>
    <!-- <v-layout wrap v-if="component === 'notify_templ'">
      <v-flex md2 xs12 class="px-3">
        <p class="mb-3">Условия:</p>
        <v-tooltip right>
          <v-btn small @click="addCondition" icon slot="activator" class="main_color main_color--text">
            <v-icon v-text="'$vuetify.icons.add'"></v-icon>
          </v-btn>
          <span>Добавить условие</span>
        </v-tooltip>
      </v-flex>
      <v-flex md10 xs12 class="px-3">
        <v-layout v-for="(item, index) in options.condition" :key="index" class="mb-3">
          <v-flex xs10>
            <v-layout>
              <v-layout>
                <v-select
                  :items="['Ne(a,b)', 'Eq(a,b)', 'a>b', 'a<b', 'a>=b', 'a<=b']"
                  label="Условие"
                  class="mx-1"
                  v-model="item.where"
                  hide-details
                  persistent-hint
                ></v-select>
              </v-layout>
              <v-layout>
                <v-select
                  :items="lists.values"
                  style="min-width: 100px"
                  label="a"
                  class="mx-1"
                  v-model="item.a"
                  hide-details
                  persistent-hint
                ></v-select>
              </v-layout>
              <v-layout>
                <v-text-field
                  label="Свое значение a"
                  class="mx-1"
                  v-model="item.a"
                  hide-details
                  persistent-hint
                ></v-text-field>
              </v-layout>
              <v-layout>
                <v-select
                  :items="lists.values"
                  style="min-width: 100px"
                  label="b"
                  class="mx-1"
                  v-model="item.b"
                  hide-details
                  persistent-hint
                ></v-select>
              </v-layout>
              <v-layout>
                <v-text-field
                  label="Свое значение b"
                  class="mx-1"
                  v-model="item.b"
                  hide-details
                  persistent-hint
                ></v-text-field>
              </v-layout>
            </v-layout>
          </v-flex>

          <v-flex xs2>
            <v-layout column>
              <v-tooltip left>
                <v-btn small slot="activator" icon class="main_color main_color--text" @click="removeCondition(index)">
                  <v-icon v-text="'$vuetify.icons.remove'"></v-icon>
                </v-btn>
                <span>Удалить формулу</span>
              </v-tooltip>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout> -->

    <v-layout wrap v-if="component === 'sensors' || component === 'tsensors'">
      <v-card class="mx-3 mt-2 pt-2 pb-3" style="width: 100%">
        <v-flex>
          <v-flex md12 class="px-3">
            <v-btn small @click="addFormula" icon class="main_color--text">
              <v-icon v-text="'$vuetify.icons.add'"></v-icon>
            </v-btn>
          </v-flex>
          <v-flex md12 xs12 class="px-3">
            <v-layout v-for="(item, index) in lists.formula" :key="index" class="mb-3">
              <v-flex md11>
                <v-layout wrap>
                  <v-flex md2 sm4 xs6>
                    <v-layout column class="options__formulae">
                      <p class="text-center">
                        {{ $t('options.formulae.condition') }}
                      </p>
                      <v-input class="input__main px-2 mx-2" height="25" hide-details>
                        <custom-select v-model="item.where" :items="formulae_conditions">
                        </custom-select>
                      </v-input>
                    </v-layout>
                  </v-flex>
                  <v-flex md2 sm4 xs6>
                    <v-layout column class="options__formulae">
                      <p class="text-center">
                        {{ $t('options.formulae.formula') }}
                      </p>
                      <v-input class="input__main px-2 mx-2" height="25" hide-details>
                        <custom-select v-model="item.formula" :items="formulae_formula">
                        </custom-select>
                      </v-input>
                    </v-layout>
                  </v-flex>
                  <v-flex md2 sm4 xs6>
                    <v-layout column class="options__formulae">
                      <p class="text-center">x</p>
                      <v-input class="input__main px-2 mx-2" height="25" hide-details>
                        <input
                          type="text"
                          readonly
                          v-model="options.terminal_column"
                          style="width: 100%; font-size: 12px;"
                        />
                      </v-input>
                    </v-layout>
                  </v-flex>
                  <v-flex md2 sm4 xs6>
                    <v-layout column class="options__formulae">
                      <p class="text-center">a</p>
                      <v-input class="input__main px-2 mx-2" height="25" hide-details>
                        <input type="text" v-model="item.a" style="width: 100%; font-size: 12px;" />
                      </v-input>
                    </v-layout>
                  </v-flex>
                  <v-flex md2 sm4 xs6>
                    <v-layout column class="options__formulae">
                      <p class="text-center">b</p>
                      <v-input class="input__main px-2 mx-2" height="25" hide-details>
                        <input type="text" v-model="item.b" style="width: 100%; font-size: 12px;" />
                      </v-input>
                    </v-layout>
                  </v-flex>
                  <v-flex md2 sm4 xs6>
                    <v-layout column class="options__formulae">
                      <p class="text-center">n</p>
                      <v-input class="input__main px-2 mx-2" height="25" hide-details>
                        <input type="text" v-model="item.n" style="width: 100%; font-size: 12px;" />
                      </v-input>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md1>
                <v-layout>
                  <v-btn
                    class="ma-0 btn__icon--remove"
                    small
                    icon
                    v-ripple="false"
                    text
                    @click.native.prevent="removeFormula(index)"
                  >
                    <v-icon v-text="'$vuetify.icons.remove'"></v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-flex>
      </v-card>
    </v-layout>

    <v-flex md12 xs12 class="my-3">
      <v-layout justify-center>
        <v-btn class="btn__save px-3" text @click="saveFormula()">
          <span class="subtitle-1 text-none">{{ $t('save') }}</span>
        </v-btn>
      </v-layout>
    </v-flex>
  </v-flex>
</template>

<script>
import HTTP from '@/api/http'
import customSelect from '@/components/custom/custom-select'

export default {
  components: {
    customSelect
  },

  props: {
    component: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      lists: {
        formula: [],
        condition: []
      },
      formulae_example: {
        where: 'x>n',
        formula: 'a*x+b',
        x: this.options.terminal_column,
        a: 0,
        b: 0,
        n: 0
      },
      formulae_conditions: ['Eq(x, x)', 'Eq(x, n)', 'Ne(x, n)', 'x>n', 'x<n', 'x>=n', 'x<=n'],
      formulae_formula: ['x', 'a*x+b']
    }
  },
  computed: {
    getParamsX() {
      try {
        const tableName = this.getOptions[this.component].type_terminal[this.options.type_terminal]
        const findSensorType = tableName[`${this.component}_types`].find(
          obj => obj.type_sensor === this.options.type_sensor
        ).x_type
        const paramsX = tableName.terminal_columns
          .filter(type => findSensorType.includes(type.data_type))
          .map(type => type.value)
        return paramsX
      } catch (e) {
        return []
      }
    }
  },

  watch: {
    $route(to, from) {
      this.init()
    }
  },
  created() {
    this.init()
  },

  methods: {
    init() {
      if (
        this.options.formula &&
        this.options.formula.length &&
        Array.isArray(this.options.formula)
      ) {
        this.lists.formula = this.options.formula
      } else {
        this.lists.formula = []
      }
    },
    saveFormula() {
      const obj = {
        id: this.$route.params[this.component],
        jdata: {
          ...this.options.jdata
        }
      }

      if (this.lists.formula.length) {
        this.lists.formula.forEach(formula => {
          // if ([1, 2, '1', '2'].includes(formula.x)) {
          //   formula.x = parseInt(this.options.jdata.formula, 10)
          // } else {
          //   formula.x = this.options.jdata.formula
          // }
          formula.a = parseFloat(formula.a)
          formula.b = parseInt(formula.b, 10)
          formula.n = parseInt(formula.n, 10)
        })

        obj.formula = this.lists.formula.sort((a, b) => parseInt(a.n, 10) - parseInt(b.n, 10))
      } else {
        obj.formula = []
      }

      HTTP.put(this.component, obj).then(res => {
        this.$store.commit('PUT_ITEM', {
          ...obj,
          jdata: {
            ...obj.jdata,
            component: this.component
          }
        })
      })
    },
    addCondition() {
      this.options.condition.push({
        where: '',
        a: 0,
        b: 0
      })
    },
    removeCondition(index) {
      this.options.condition.splice(index, 1)
    },
    addFormula() {
      this.lists.formula.push({...this.formulae_example})
    },
    removeFormula(index) {
      this.lists.formula.splice(index, 1)
    }
  }
}
</script>
