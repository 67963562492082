<template>
  <v-layout class="posr" fill-height>
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      style="height: 100%; position: absolute; width: 100%"
    >
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker
        v-for="watcher in watchers"
        :key="watcher.topic"
        :lat-lng="parseLatlon(watcher.jdata.latlon, 'marker')"
      >
        <l-popup>
          <p class="mt-0 mb-1"><span class="font-weight-bold">Имя:</span> {{ watcher.name }}</p>
          <p class="mt-0 mb-1">
            <span class="font-weight-bold">Телефон:</span> {{ watcher.phone }}
          </p>
          <p class="mt-0 mb-1">
            <span class="font-weight-bold">Время:</span>
            {{ formatDate(watcher.jdata.datepoint, 'L LT') }}
          </p>
        </l-popup>
      </l-marker>
    </l-map>

    <div class="watchers px-2 py-2">
      <h2 class="mb-3 text-center">Метки</h2>

      <div v-for="watcher in watchers" :key="watcher.topic" class="watcher__item">
        <h3 class="text-center">{{ watcher.name }}</h3>
        <p class="mb-1">
          От {{ formatDate(toLocalDate(watcher.date_from), 'L LT') }} до
          {{ formatDate(toLocalDate(watcher.date_to), 'L LT') }}
        </p>
      </div>
    </div>
  </v-layout>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  // LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
  LImageOverlay
} from 'vue2-leaflet'
import L from 'leaflet'

import {parseLatlon, formatDate, toLocalDate} from '@/utils/index.js'
import HTTP from '@/api/http'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    // LPolyline,
    // LLayerGroup,
    // LTooltip,
    LPopup
    // LControlZoom,
    // LControlAttribution,
    // LControlScale,
    // LControlLayers,
    // LImageOverlay
  },

  mqtt: {
    'debug/tm_gps/#': function(data, topic) {
      const decode = new TextDecoder('utf-8').decode(data)
      const json = JSON.parse(decode)
      const watcher = this.watchers.find(el => {
        const [tm_gps, id] = el.topic.split('/')
        return id === json.imei_md5
      })

      if (watcher) {
        this.$set(watcher.jdata, 'latlon', json.latlon)
        this.$set(watcher.jdata, 'datepoint', json.datepoint)
      }

      console.log(topic, json)
    }
  },

  data() {
    return {
      map: null,
      options: {
        crs: L.CRS.Simple,
        zoomControl: false
      },
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 3,
      center: [47.41322, -1.219482],
      markerLatLon: [47.41322, -1.219482],
      bounds: null,
      watchers: []
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.map = this.$refs.map.mapObject
    })

    this.getWatchers()
    this.$mqtt.subscribe('debug/tm_gps/#')
  },

  methods: {
    async getWatchers() {
      const {data: watchers = []} = await HTTP.post('watchers')
      this.watchers = Array.isArray(watchers) ? watchers : []
      this.watchers.forEach(watcher => {
        this.$mqtt.subscribe(`debug/${watcher.topic}/#`)
      })
    },
    parseLatlon,
    formatDate,
    toLocalDate
  }
}
</script>

<style scoped>
.watchers {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.75);
  max-height: 512px;
  overflow-y: auto;
}

.watcher__item {
  border-bottom: 1px solid black;
}
</style>
