<template>
  <v-flex class="mt-12">
    <dict-title title="Управление шлагбаумом"></dict-title>
    <v-card elevation="2" class="mt-3 px-2 tax__chat overflow-hidden pt-10">
      <v-layout column>
        <p class="master__title">{{ $t('tax.entry') }}</p>
        <v-input class="input__main px-2" height="25" hide-details>
          <custom-select
            v-model="entry"
            :items="active_doors"
            item-value="id_tsensor"
            item-text="name"
          ></custom-select>
        </v-input>
      </v-layout>

      <v-layout justify-center class="mb-3">
        <v-flex md6 sm6 xs12>
          <v-btn
            small
            @click="openActiveDoor()"
            depressed
            class="btn__company btn__save mx-0 my-0"
            style="width: 100%;"
          >
            {{ $t('open') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
  </v-flex>
</template>

<script>
import HTTP from '@/api/http'
import customSelect from '@/components/custom/custom-select'
import dictTitle from '@/components/custom/dicts/title'

export default {
  components: {
    customSelect,
    dictTitle
  },

  data() {
    return {
      entry: '',
      active_doors: []
    }
  },

  created() {
    this.activeDoors()
  },

  methods: {
    async activeDoors() {
      const {data} = await HTTP.post('active_doors')
      this.active_doors = data.map(door => {
        const type_sensor = this.$t(`lists.tsensors.type_sensor.${door.type_sensor}`)
        const name = `${door.name} - ${type_sensor}`
        return {
          ...door,
          name
        }
      })
    },
    async openActiveDoor() {
      const findActiveDoor = this.active_doors.find(door => door.id_tsensor === this.entry)
      if (findActiveDoor) {
        const {id_card, id_person, id_tsensor} = findActiveDoor
        const options = {
          id_card,
          id_person,
          id_tsensor
        }
        await HTTP.put('doors_open', options)
      }
    }
  }
}
</script>
