<template>
  <v-layout wrap>
    <v-flex md10>
      <v-layout fill-height class="mx-2 mb-4">
        <v-card width="100%" style="margin-top: 14px; margin-bottom: 15px">
          <v-flex class="mx-2 mt-4 mb-4">
            <v-layout>
              <v-btn
                @click="meteozone = 'temperature'"
                v-ripple="false"
                depressed
                text
                tile
                active-class="transparent"
                class="mx-2 px-2 text-capitalize weather__top-btn"
                :class="meteozone === 'temperature' ? 'weather__top-btn--bold' : ''"
                >{{ $t('weather.temperature') }}</v-btn
              >
              <v-btn
                @click="meteozone = 'humidity'"
                v-ripple="false"
                depressed
                text
                tile
                active-class="transparent"
                class="mx-2 px-2 text-capitalize weather__top-btn"
                :class="meteozone === 'humidity' ? 'weather__top-btn--bold' : ''"
                >{{ $t('weather.humidity') }}</v-btn
              >
              <v-btn
                @click="meteozone = 'pressure'"
                v-ripple="false"
                depressed
                text
                tile
                active-class="transparent"
                class="mx-2 px-2 text-capitalize weather__top-btn"
                :class="meteozone === 'pressure' ? 'weather__top-btn--bold' : ''"
                >{{ $t('weather.pressure') }}</v-btn
              >
              <v-btn
                @click="meteozone = 'precipitation'"
                v-ripple="false"
                depressed
                text
                tile
                active-class="transparent"
                class="mx-2 px-2 text-capitalize weather__top-btn"
                :class="meteozone === 'precipitation' ? 'weather__top-btn--bold' : ''"
                >{{ $t('weather.precipitation') }}</v-btn
              >
            </v-layout>
          </v-flex>
          <v-flex style="max-height: 650px; height: 650px" class="mb-3 px-6">
            <leaflet-map></leaflet-map>
          </v-flex>
          <v-flex class="pa-0 mx-6">
            <v-progress-linear color="#575656" value="15" height="7"></v-progress-linear>
          </v-flex>
          <v-flex class="my-2 mx-6">
            <v-layout justify-space-between>
              <v-layout>
                <v-btn icon x-small>
                  <v-icon size="21" v-text="'$vuetify.icons.weather_pause'"></v-icon>
                </v-btn>
                <v-btn icon x-small>
                  <v-icon size="21" v-text="'$vuetify.icons.weather_play'"></v-icon>
                </v-btn>
              </v-layout>
              <v-layout justify-end>
                <v-btn icon x-small>
                  <v-icon size="21" v-text="'$vuetify.icons.weather_prev'"></v-icon>
                </v-btn>
                <v-btn icon x-small>
                  <v-icon size="21" v-text="'$vuetify.icons.weather_next'"></v-icon>
                </v-btn>
              </v-layout>
            </v-layout>
          </v-flex>
          <v-flex> </v-flex>
        </v-card>
      </v-layout>
    </v-flex>
    <v-flex md2>
      <v-layout column class="mx-2 mb-4">
        <dict-title :title="city"></dict-title>
        <v-card class="pt-7 mt-3">
          <v-flex md6 class="mt-2 mb-4 px-2">
            <v-select
              class="statistics__select elevation-0"
              :items="lists.timezones"
              v-model="timezone"
              hide-details
              solo
              single-line
            ></v-select>
          </v-flex>

          <v-flex class="pa-0" style="max-height: calc(100vh - 178px); overflow: scroll">
            <v-list class="pt-0 elevation-0">
              <v-list-item-group color="main_color" v-for="(day, i_day) in lists.days" :key="i_day">
                <v-list-item
                  class="options__list weather__list"
                  @click.stop
                  v-for="(item, i_hours) in day.hours"
                  :key="i_hours"
                >
                  <v-list-item-content>
                    <v-list-item-title class="weather__text--14" v-if="i_hours === 0">{{
                      day.day
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text class="weather__text--14">{{
                      item
                    }}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-flex>
        </v-card>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import dictTitle from '@/components/custom/dicts/title'
import leafletMap from '@/components/secondComponents/CustomLeaflet'

export default {
  components: {
    dictTitle,
    leafletMap
  },

  data() {
    return {
      city: 'Moscow',
      timezone: 'UTC+3',
      meteozone: '',
      lists: {
        timezones: ['UTC+3', 'UTC+4', 'UTC+5', 'UTC+6', 'UTC+7', 'UTC+8', 'UTC+9'],
        days: [
          {
            day: 'Пн, 8 июля',
            hours: ['22:00']
          },
          {
            day: 'Вт, 9 июля',
            hours: ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00']
          },
          {
            day: 'Ср, 10 июля',
            hours: ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00']
          },
          {
            day: 'Чт, 11 июля',
            hours: ['00:00', '03:00', '06:00', '09:00', '12:00', '15:00', '18:00', '21:00']
          }
        ]
      }
    }
  }
}
</script>
