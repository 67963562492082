<template>
  <div>
    <template v-for="(item, i) in toolbarBtns">
      <v-btn
        :key="i"
        v-if="item.condition"
        small
        :to="item.to"
        color="transparent"
        depressed
        class="mx-3 grey_custom--text text-none"
        active-class="main_color--text"
      >
        {{ $t(item.text) }}
      </v-btn>
    </template>
    <v-btn
      small
      color="transparent"
      depressed
      class="mx-3 grey_custom--text text-none"
      active-class="main_color--text"
      @click="() => location.replace('https://enrgocon.com/monitoring')"
    >
      {{ $t('menu.monitoring') }}
    </v-btn>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  data() {
    return {
      location: window.location,
    }
  },
  computed: {
    toolbarBtns() {
      const {type_module, acc_role} = this.auth
      return [
        {
          to: '/',
          text: 'master.main',
          condition: true,
        },
        {
          to: '/reports',
          text: 'menu.accounting',
          condition: acc_role !== 'guest',
        },
        // {
        //   to: '/persons_list',
        //   text: 'component.control',
        //   condition: type_module === 'acs'
        // },
        // {
        //   to: '/monitoring',
        //   text: 'menu.monitoring',
        //   condition: type_module === 'smis',
        // },
        {
          to: '/meteo',
          text: 'menu.meteo',
          condition: type_module === 'hmg',
        },
        {
          to: '/statistics',
          text: 'menu.statistics',
          condition: type_module === 'hmg',
        },
        {
          to: '/weather',
          text: 'menu.weather',
          condition: type_module === 'hmg',
        },
        {
          to: '/map',
          text: 'menu.map',
          condition: type_module === 'skifme',
        },
        // {
        //   to: '/tax',
        //   text: 'menu.tax',
        //   condition: type_module === 'acs'
        // }
        // {
        //   to: '/consumption',
        //   text: 'menu.consumption',
        //   condition: type_module === 'hmg'
        // }
        // {
        //   to: '/player',
        //   text: 'menu.player',
        //   condition: type_module === 'acs'
        // }
      ]
    },
    ...mapState(['auth']),
  },
}
</script>
