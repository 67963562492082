<template>
  <v-flex md9 sm12 xs12>
    <dict-title :title="$t('debug.title')"></dict-title>

    <v-layout class="pt-4 fill-height">
      <v-card class="d-flex flex-column elevation-0 pt-4 flex-grow-1">
        <v-layout align-center justify-center class="flex-grow-0">
          <v-btn class="btn__reports" @click="clearTableItems" text>
            {{ $t('debug.clearTable') }}
          </v-btn>
        </v-layout>

        <v-card ref="table" class="table__main elevation-0 overflow-y-auto flex-grow-1">
          <custom-table :table="table" :search="search"></custom-table>
        </v-card>
      </v-card>
    </v-layout>
  </v-flex>
</template>

<script>
import moment from 'moment'
import HTTP from '@/api/http'
import pSocket from '@/mixins/pSocket'

import customTable from '@/components/custom/custom-table'
import dictTitle from '@/components/custom/dicts/title'

import {mapGetters} from 'vuex'

export default {
  components: {
    customTable,
    dictTitle
  },

  mixins: [pSocket],

  props: {
    component: {
      type: String
    }
  },

  mqtt: {
    'debug/#': function(data, topic) {
      // console.log(topic)
      const decode = new TextDecoder('utf-8').decode(data)
      const json = JSON.parse(decode)
      const [debug, type, id_debug, ...rest] = topic.split('/')
      const {type_terminal = null, id} =
        this.getDicts(this.component).find(
          el => el.type_terminal === type && el.jdata.id_debug === id_debug
        ) ?? {}

      if (type_terminal && id === this.$route.params[this.component]) {
        this.addColumn({
          ...json,
          index: this.table.items.length + 1
        })
      }
      // console.log(json)
    }
  },

  data() {
    return {
      search: '',
      table: {
        headers: [],
        items: [],
        names: []
      },
      channel: ''
    }
  },
  computed: {
    ...mapGetters(['getDicts'])
  },

  watch: {
    $route(to, from) {
      this.init()
      this.clearTableItems()
      this.subscribeDebugChannel()
    }
  },
  created() {
    this.init()
    this.subscribeDebugChannel()
  },

  methods: {
    async init() {
      Object.keys(this.table).forEach(item => {
        this.table[item] = []
      })
      const findElem = this.getDicts(this.component).find(
        elem => elem.id === this.$route.params[this.component]
      )

      const {data} = await HTTP.get(`${findElem.type_terminal}`)
      const header = [
        {
          value: 'number',
          sortable: false,
          text: 'Номер'
        }
      ]
      this.table.names = data.filter(column => column.visible).map(item => item.value)
      this.table.headers = header.concat(data.filter(column => column.visible))
    },
    clearTableItems() {
      this.table.items = []
    },
    addColumn(json) {
      this.table.items.push(json)
    },
    subscribeDebugChannel() {
      this.$mqtt.unsubscribe('#')

      const {type_terminal = null, id, jdata} =
        this.getDicts(this.component).find(el => el.id === this.$route.params[this.component]) ?? {}
      const {id_debug} = jdata ?? {}

      this.$mqtt.subscribe(`debug/${type_terminal}/${id_debug}/#`)
    }
  }
}
</script>
