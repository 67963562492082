<template>
  <v-data-table
    :headers="table.headers"
    :items="table.items"
    :loading="loading"
    :search="search"
    hide-default-footer
    hide-default-header
    fixed-header
    :height="table.height"
    :items-per-page="100000"
    no-data-text="Данных нет"
    elevation="0"
  >
    <template v-slot:header="{props: {headers}}">
      <thead>
        <tr class="table__headers">
          <th
            v-for="(header, index) in headers"
            :key="header.text"
            class="black--text"
            :class="[getAlignHeaders(index), transformHeaders()]"
          >
            <span>{{ header.text }}</span>
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:body="{items}">
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="item.name"
          class="table__items--td"
          :class="[
            item._is_itog ? 'main_color' : '',
            !item._is_itog && index % 2 === 1 ? 'report_color' : ''
          ]"
        >
          <td class="black--text text-center">
            {{ item._is_itog ? 'Итог' : item.index }}
          </td>
          <td
            class="black--text py-0 px-1"
            :class="[getBgCell(item[name]), getBorders(item[name])]"
            v-for="(name, index) in table.names"
            :key="index"
            colspan
          >
            <span>{{ item[name] }}</span>
          </td>
        </tr>
      </tbody>
    </template>
    <template v-slot:progress>
      <v-progress-linear color="main_color" :height="10" indeterminate></v-progress-linear>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    table: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getDicts'])
  },

  methods: {
    getAlignHeaders(index) {
      const findTable = this.getDicts('rtables').find(
        table => table.id === this.$route.params.rtables
      )
      if (!findTable) return ''

      if (findTable.type_report === 'alarm' || index) {
        return 'table__headers--left'
      }
      return ''
    },
    transformHeaders() {
      const findTable = this.getDicts('rtables').find(
        table => table.id === this.$route.params.rtables
      )
      if (!findTable) return ''

      if (findTable.type_report === 'alarm') {
        return 'reports__transform'
      }

      return ''
    },
    getBgCell(value) {
      const findTable = this.getDicts('rtables').find(
        table => table.id === this.$route.params.rtables
      )
      if (!findTable) return ''

      if (findTable.type_report === 'alarm') {
        if (value === 'error') {
          return 'error transparent--text'
        }
        if (value === 'warning') {
          return 'warning transparent--text'
        }
      }
      return ''
    },
    getBorders(type_mess) {
      const findTable = this.getDicts('rtables').find(
        table => table.id === this.$route.params.rtables
      )
      if (!findTable) return ''

      if (findTable.type_report === 'alarm' && ['warning', 'error'].includes(type_mess)) {
        return 'reports__borders'
      }

      return ''
    }
  }
}
</script>
