<template>
  <v-menu offset-y>
    <template v-slot:activator="{on}">
      <span v-on="on" class="master__select">
        {{ getValue }}
        <img src="@/assets/select.svg" class="master__select--icon" alt="" />
      </span>
    </template>
    <v-card class="custom__card" v-if="readonly === false">
      <v-layout column>
        <v-card
          class="pa-2 custom__select elevation-0"
          v-for="(item, index) in getItems"
          :key="index"
          @click="emit(item)"
        >
          <v-layout justify-space-between align-center>
            <span class="custom__select--item">{{ getItem(item) }}</span>
            <v-icon v-if="icons" v-text="`${iconsSrc}_${item}`"></v-icon>
          </v-layout>
        </v-card>
      </v-layout>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number]
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: [String, Number]
    },
    itemText: {
      type: [String, Number]
    },
    icons: {
      type: Boolean
    },
    iconsSrc: {
      type: String,
      default: '$vuetify.icons'
    },
    translation: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getItems() {
      if (this.itemText) {
        return this.items.map(item => item[this.itemText])
      }
      return this.items
    },
    getValue() {
      if (this.itemText) {
        const findText = this.items.find(option => option[this.itemValue] === this.value)
        if (!findText) return ''
        return findText[this.itemText]
        // return this.$t(findText[this.itemText])
      }
      if (this.value) {
        // return this.value
        return this.getItem(this.value)
      }
      return this.value
    },
    getItem() {
      return item => {
        if (this.translation) {
          return this.$t(`${this.translation}.${item}`)
        }
        return item
      }
    }
  },

  watch: {
    items(arr) {
      this.setDefaultValue()
    }
  },
  mounted() {
    if (this.defaultValue) {
      this.setDefaultValue()
    }
  },

  methods: {
    setDefaultValue() {
      const first_item = this.getItems[0]
      if (first_item) {
        this.emit(first_item)
      }
    },
    emit(item) {
      if (this.itemValue) {
        const findValue = this.items.find(option => option[this.itemText] === item)
        this.$emit('input', findValue[this.itemValue])
      } else {
        this.$emit('input', item)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.custom__select
  cursor: pointer
  border-radius: 0

.custom__select--item
  font-size: 12px

.custom__select:hover
  background-color: #EEE

.custom__card
  position: relative
  max-height: 300px

.master__select
  outline: none
  min-width: 100%
  min-height: 25px
  width: 100%
  height: 100%
  font-size: 12px
  line-height: 25px
  cursor: pointer

.master__select option
  height: 50px !important

.master__select--icon
  position: absolute
  right: 3px
  top: 10px
</style>
