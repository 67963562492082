import Vue from 'vue'
import Vuex from 'vuex'
import HTTP from '@/api/http'
import auth from '@/store/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dicts: {},
    auth: {},
    company_data: {},
    user_data: {},
    navigation: {},
    options: {},
    interval: null,
    config_fields: {},
    monitoring: {
      amount: {
        systems: 0,
        accidents: 0,
        failures: 0
      }
    },
    theme_colors: {
      value: 'green',
      items: [
        {
          color_name: 'green',
          main_color: '#389667',
          report_color: '#E3F3EC'
        },
        {
          color_name: 'blue',
          main_color: '#2D87C8',
          report_color: '#8187FF'
        },
        {
          color_name: 'red',
          main_color: '#91527F',
          report_color: '#FF7CB7'
        },
        {
          color_name: 'violet',
          main_color: '#A668D5',
          report_color: '#EAE898'
        },
        {
          color_name: 'orange',
          main_color: '#FFA073',
          report_color: '#EAE898'
        },
        {
          color_name: 'indigo',
          main_color: '#7A45D6',
          report_color: '#EAE898'
        },
        {
          color_name: 'greenlight',
          main_color: '#57B9A1',
          report_color: '#EAE898'
        }
      ]
    }
  },
  getters: {
    getDicts: state => dict => state.dicts[dict],
    interval: state => state.interval,
    getOptions: state => state.options,
    config: state => state.config_fields,
    getThemeColor: state => `theme__${state.theme_colors.value}`,
    getThemeColors: state => state.theme_colors.items,
    auth: state => state.auth
  },
  mutations: {
    DELETE_ITEM: async (state, {dict, id}) => {
      state.dicts[dict] = state.dicts[dict].filter(item => item.id !== id)
    },
    PUT_ITEM: (state, element) => {
      const {component: dict} = element.jdata

      if (!state.dicts[dict]) return

      const exist_element = state.dicts[dict].find(item => item.id === element.id)

      if (exist_element) {
        Object.assign(exist_element, element)
      } else {
        state.dicts[dict].unshift(element)
      }
    },
    GET_ITEMS: (state, {dict, component}) => {
      state.dicts[component] = dict.sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    CLEAR_ALL: (state, payload) => {
      state.auth = {}
      state.user_data = {}
      state.options = {}
    },
    SET_NAVIGATION_ROUTE: (state, payload) => {
      const component = state.navigation[payload.component]
      component.value = payload.value
    },
    UPDATE_INTERVAL: (state, interval) => {
      state.interval = interval
    },
    UPDATE_AMOUNT_TROUBLES: (state, obj) => {
      state.monitoring.amount[obj.type] = obj.value
    },
    ADD_NAVIGATION_HISTORY: (state, dict) => {
      state.navigation[dict] = {
        value: ''
      }
    },
    ADD_DICTS: (state, dicts) => {
      state.dicts = Vue.observable({...dicts})
    },
    SET_THEME_COLOR: (state, color) => {
      state.theme_colors.value = color
    }
  },
  actions: {
    getItems: async (context, component) => {
      const {data: dict} = await HTTP.post(component)
      if (Array.isArray(dict)) {
        context.commit('GET_ITEMS', {
          dict,
          component
        })
      }
    },
    deleteItem: async (context, {element, dict}) => {
      const {
        data: {id}
      } = await HTTP.delete(`${dict}?id=${element.id}`)
      if (id) {
        context.commit('DELETE_ITEM', {
          dict,
          id
        })
      }
    },
    updateThemeColor: ({state, getters, commit}, {$vuetify}) => {
      const {color} = state.auth?.jdata ?? {}
      if (color) {
        const colorTheme = getters.getThemeColors.find(theme => theme.color_name === color)

        if (colorTheme) {
          Object.entries(colorTheme).forEach(([key, value]) => {
            $vuetify.theme.themes.light[key] = value
          })
        }
        commit('SET_THEME_COLOR', color)
      }
    }
  },

  modules: {
    auth
  }
})
