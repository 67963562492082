const icons = {}

const context = require.context('@/icons', true, /vue$/)
context.keys().forEach(icon => {
  const file_name = icon.replace(/^(\.\/)([\w|/]+).vue$/, '$2').replace(/\//g, '_')
  icons[file_name] = {
    component: context(icon).default
  }
})

export default icons
