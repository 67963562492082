<template>
  <v-layout class="pt-2 pb-1">
    <dict-fields :component="component" :options="options"></dict-fields>
    <v-flex v-if="/dictionary/.test($route.path) && checkToShowDeleteBtn">
      <v-layout align-center justify-end>
        <v-btn
          class="ma-0 btn__icon--remove"
          small
          icon
          v-ripple="false"
          @click.stop="removeItem(options)"
        >
          <v-icon color="currentColor" v-text="`$vuetify.icons.remove`"></v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import {mapGetters} from 'vuex'
import dictFields from '@/components/custom/dicts/fields'

export default {
  components: {
    dictFields
  },

  props: {
    component: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    checkToShowDeleteBtn() {
      const {rules} = this.getOptions[this.component]
      return rules.includes('DELETE')
    },
    ...mapGetters(['getOptions'])
  },

  methods: {
    removeItem(element) {
      this.$store.dispatch('deleteItem', {
        element,
        dict: this.component
      })
      this.$router.push(`/dictionary/${this.component}`)
      this.search = ''
    }
  }
}
</script>
