<template>
  <v-flex class="pt-1 train" d-flex>
    <v-flex md3 class="train__options">
      <v-flex d-flex>
        <v-flex md4 xs6 class="px-3">
          <p class="mb-3">{{ $t('component.cams') }}</p>
        </v-flex>
        <v-flex md8 xs6 d-flex class="px-3">
          <v-input class="input__main px-2" height="25" hide-details>
            <custom-select
              v-model="cam.value"
              item-value="id"
              item-text="name"
              :items="getDicts('cams')"
              default-value
            >
            </custom-select>
          </v-input>
          <v-btn dark icon small color="main_color" class="ml-1" @click="clearPhoto">
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-flex>
      </v-flex>

      <v-flex>
        <v-flex d-flex>
          <v-flex md4 xs6 class="px-3">
            <p class="mb-3">{{ options.func_task }}</p>
          </v-flex>
          <v-flex md8 xs6 class="px-3">
            <v-input class="input__main px-2" height="25" hide-details>
              <custom-select
                v-model="func_val"
                :items="getFuncVal"
                item-value="key"
                item-text="label"
                default-value
              >
              </custom-select>
            </v-input>
          </v-flex>
        </v-flex>

        <v-flex class="px-3" md12>
          <v-card class="px-2 py-1">
            <v-layout justify-end class="mr-1">
              <v-btn class="mx-0 my-auto main_color--text" max-height="32" max-width="32" icon>
                <v-icon size="20" v-text="`$vuetify.icons.add`" @click="savePhoto"></v-icon>
              </v-btn>
            </v-layout>
            <v-layout wrap>
              <v-flex md4 v-for="(item, i) in filterPhotos" :key="i" class="px-2">
                <img
                  :src="`/upload/${options.func_task}/${item.func_val}/${item.jdata.img_src}`"
                  alt=""
                  class="train__photo"
                  :class="[
                    photo
                      ? photo.jdata.img_src === item.jdata.img_src
                        ? 'train__photo--active'
                        : ''
                      : ''
                  ]"
                  @click="showPhoto(item)"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-flex>
    </v-flex>

    <v-flex md9>
      <v-layout justify-space-between class="px-3">
        <v-btn class="btn__save px-3" text @click="train()">
          <span class="subtitle-1 text-none">{{ $t('train') }}</span>
        </v-btn>
        <v-btn class="btn__save px-3" text :disabled="!photo" @click="updateCoords">
          <span class="subtitle-1 text-none">{{ $t('save') }}</span>
        </v-btn>
      </v-layout>
      <v-layout justify-center align-center fill-height class="mt-2 posr">
        <img
          ref="stream"
          :src="
            photo
              ? `/upload/${options.func_task}/${photo.func_val}/${photo.jdata.img_src}`
              : `/jpeg/${findCam().name}`
          "
          alt=""
          class="train__img"
        />
        <canvas height="540" width="960" style="position: absolute; cursor: crosshair"></canvas>
      </v-layout>
    </v-flex>
  </v-flex>
</template>

<script>
import {mapGetters} from 'vuex'
import customSelect from '@/components/custom/custom-select'
import HTTP from '@/api/http'

export default {
  components: {
    customSelect
  },

  props: {
    component: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      updatePhoto_interval: null,
      cam: {
        value: null,
        show: false
      },
      func_val: '',
      last_mousex: 0,
      last_mousey: 0,
      mousex: 0,
      mousey: 0,
      mousedown: false,
      ctx: null,
      tasks_cams: [],
      photo: null,
      coords: {},
      location
    }
  },
  computed: {
    filterPhotos() {
      return this.tasks_cams.filter(
        photo => photo.func_val === this.func_val && photo.id_task === this.options.id
      )
    },
    getFuncVal() {
      const item = this.config.fields[this.component].find(elem => elem.field === 'func_val')

      const {item_text} = item
      const findField = this.config.fields[this.component].find(obj => obj.field === item_text)
      if (findField.item_text) {
        const findFilter = findField.items[this.options[findField.item_text]].find(
          obj => obj.key === this.options[item_text]
        )
        if (item.items[findFilter.filter]) {
          return item.items[findFilter.filter]
        }
        return []
      }
      const prop = this.options[item_text]
      if (this.getDicts(prop)) {
        return this.getDicts(prop).map(elem => ({
          key: elem.id,
          label: elem.name
        }))
      }
      return item.items[prop] || []
    },
    ...mapGetters(['config', 'getDicts'])
  },

  mounted() {
    this.init()
    this.updatePhoto_interval = setInterval(this.updatePhoto, 1000)
  },
  beforeDestroy() {
    clearInterval(this.updatePhoto_interval)
    this.updatePhoto_interval = null
  },

  methods: {
    async init() {
      const {data} = await HTTP.post('tasks_cams')
      this.tasks_cams = data

      const img = document.querySelector('.train__img')
      const {width, height} = getComputedStyle(img)

      const canvas = document.querySelector('canvas')
      canvas.onmousedown = this.canvasMouseDown
      canvas.onmouseup = this.canvasMouseUp
      canvas.onmousemove = this.canvasMouseMove
      this.ctx = canvas.getContext('2d')
      this.ctx.strokeStyle = 'yellow'
      this.ctx.lineWidth = 2
    },
    canvasMouseDown(e) {
      this.last_mousex = e.layerX
      this.last_mousey = e.layerY
      this.clearRect()
      this.mousedown = true
    },
    canvasMouseUp(e) {
      const {layerX, layerY} = e
      // console.log([this.last_mousex, this.last_mousey], [layerX, layerY])
      const [xmin, xmax] = [Math.min(this.last_mousex, layerX), Math.max(this.last_mousex, layerX)]
      const [ymin, ymax] = [Math.min(this.last_mousey, layerY), Math.max(this.last_mousey, layerY)]
      console.log([xmin, xmax], [ymin, ymax])
      this.coords = {
        xmin,
        xmax,
        ymin,
        ymax
      }
      this.mousedown = false
    },
    canvasMouseMove(e) {
      const canvas = document.querySelector('canvas')
      this.mousex = e.layerX
      this.mousey = e.layerY

      if (this.mousedown) {
        this.clearRect()
        const width = this.mousex - this.last_mousex
        const height = this.mousey - this.last_mousey
        this.strokeRect({
          xmin: this.last_mousex,
          ymin: this.last_mousey,
          xmax: width,
          ymax: height
        })
      }
    },
    clearRect() {
      const canvas = document.querySelector('canvas')
      this.ctx.clearRect(0, 0, canvas.width, canvas.height)
    },
    strokeRect({xmin, ymin, xmax, ymax}) {
      this.ctx.strokeRect(xmin, ymin, xmax, ymax)
    },
    findCam() {
      const camera = this.getDicts('cams').find(cam => cam.id === this.cam.value)
      return camera || {}
    },
    async updateCoords() {
      const opts = {
        ...this.photo,
        jdata: {
          ...this.photo.jdata,
          ...this.parseCoords(this.coords)
        }
      }

      const {data} = await HTTP.put('tasks_cams', opts)
      const findPhoto = this.tasks_cams.find(elem => elem.id === this.photo.id)
      if (findPhoto) {
        Object.assign(findPhoto, opts)
      }
    },
    async savePhoto() {
      const cam = this.findCam()
      const {data: imgData} = await HTTP.get(`jpeg/${cam.name}`, {
        responseType: 'blob'
      })
      const fileReader = new FileReader()

      fileReader.onloadend = async () => {
        const data = new FormData()
        const file = new File([fileReader.result], 'cam.png', {
          type: 'image/png'
        })
        data.append('img', file)

        const {data: imgSrc} = await HTTP.post(
          `upload/${this.options.func_task}/${this.func_val}`,
          data
        )
        if (imgSrc) {
          const opts = {
            func_val: this.func_val,
            id_task: this.options.id,
            id_cam: cam.id,
            jdata: {
              img_src: imgSrc.img,
              ...this.parseCoords(this.coords)
            }
          }
          const {data: tasks_cams} = await HTTP.put('tasks_cams', opts)
          this.tasks_cams.push({
            ...opts,
            id: tasks_cams.id
          })
        }
      }

      fileReader.readAsArrayBuffer(imgData)
    },
    showPhoto(photo) {
      this.photo = photo
      this.clearRect()
      const {xmin, xmax, ymin, ymax} = photo.jdata
      // DOM tree doesnt update
      setTimeout(() => {
        this.strokeRect(
          this.stringifyCoords({
            xmin,
            xmax,
            ymin,
            ymax
          })
        )
      }, 0)
    },
    clearPhoto() {
      this.photo = null
      this.clearRect()
    },
    async updatePhoto() {
      if (this.photo !== null) return

      const {stream} = this.$refs
      // console.log(stream)
      const {data} = await HTTP.get(`jpeg/${this.findCam().name}`, {
        responseType: 'blob'
      })
      const src = URL.createObjectURL(data)
      this.$refs.stream.src = src
    },
    getCoeffs() {
      const img = document.querySelector('.train__img')
      const {height, width, naturalHeight, naturalWidth} = img
      const [coeffHeight, coeffWidth] = [
        (naturalHeight / height).toFixed(2),
        (naturalWidth / width).toFixed(2)
      ]
      return [coeffHeight, coeffWidth]
    },
    parseCoords({xmin, xmax, ymin, ymax}) {
      const [coeffHeight, coeffWidth] = this.getCoeffs()
      return {
        xmin: xmin * coeffWidth,
        xmax: xmax * coeffWidth,
        ymin: ymin * coeffHeight,
        ymax: ymax * coeffHeight
      }
    },
    stringifyCoords({xmin, xmax, ymin, ymax}) {
      const [coeffHeight, coeffWidth] = this.getCoeffs()
      return {
        xmin: xmin / coeffWidth,
        xmax: (xmax - xmin) / coeffWidth,
        ymin: ymin / coeffHeight,
        ymax: (ymax - ymin) / coeffHeight
      }
    },
    async train() {
      const {data} = await HTTP.post('train', {
        id_task: this.options.id
      })
    }
  }
}
</script>

<style scoped lang="sass">
.train
  &__photo
    height: 100%
    width: 100%
    box-sizing: border-box
    &:hover
      cursor: pointer

    &--active
      border: 1px solid red

  &__img
    width: 960px
    height: 540px
</style>
