<template>
  <v-layout>
    <v-flex class="px-3" md6>
      <v-text-field type="search" v-model="search" color="main_color">
        <template v-slot:append>
          <v-icon size="14" class="mt-2">$search</v-icon>
        </template>
      </v-text-field>
      <v-flex>
        <v-card>
          <v-list dense style="height: calc(100vh - 360px)">
            <v-subheader>{{ getSubheader }}</v-subheader>

            <v-list-item-group
              color="main_color"
              style="max-height: calc(100% - 40px); overflow: scroll"
            >
              <v-subheader v-if="arrays.activeItems.length > 0">{{
                $t('main.activeItems')
              }}</v-subheader>
              <v-list-item
                v-for="element in getListActiveItems"
                :key="element.id"
                @click="() => toggleActiveItem(element)"
                class="options__list card__dict"
                dense
              >
                <v-list-item-content
                  @dblclick="
                    $router.push({
                      path: `/dictionary/${secondComponent}/${single_names.child}_${element.id}`,
                    })
                  "
                >
                  <dict-fields :component="secondComponent" :options="element"></dict-fields>
                </v-list-item-content>
                <v-list-item-action v-if="typeList === 'child'">
                  <v-checkbox
                    hover
                    :input-value="element.toggle_bind_elem"
                    :value="element.toggle_bind_elem"
                    @change="toggleBindElement(element)"
                    color="main_color"
                    hide-details
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>

              <v-subheader v-if="arrays.restItems.length > 0">{{
                $t('main.restItems')
              }}</v-subheader>
              <v-list-item
                v-for="element in getListRestItems"
                :key="element.id"
                @click="() => toggleActiveItem(element)"
                class="options__list card__dict"
                dense
              >
                <v-list-item-content
                  @dblclick="
                    $router.push({
                      path: `/dictionary/${secondComponent}/${single_names.child}_${element.id}`,
                    })
                  "
                >
                  <dict-fields :component="secondComponent" :options="element"></dict-fields>
                </v-list-item-content>
                <v-list-item-action v-if="typeList === 'child'">
                  <v-checkbox
                    hover
                    :input-value="element.toggle_bind_elem"
                    :value="element.toggle_bind_elem"
                    @change="toggleBindElement(element)"
                    color="main_color"
                    hide-details
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-flex>
    </v-flex>
    <v-flex class="px-3" md6 v-if="activeItem && typeList === 'link'">
      <options-settings :options="options" :component="api_name" style="overflow-y: auto">
        <v-layout wrap justify-center class="mt-2">
          <options-button
            v-for="button in getActionButtons"
            :key="button.field"
            :button="button"
            @click="putAction(button)"
          >
          </options-button>
        </v-layout>
      </options-settings>
    </v-flex>
  </v-layout>
</template>

<script>
import HTTP from '@/api/http'
import {mapGetters} from 'vuex'

import setListsOfElems from '@/mixins/setListsOfElems'

import dictFields from '@/components/custom/dicts/fields'
import searchFilter from '@/mixins/searchFilter'
import optionsSettings from '@/components/custom/options/settings'
import optionsButton from '@/components/custom/options/button'

export default {
  components: {
    dictFields,
    optionsSettings,
    optionsButton,
  },

  mixins: [searchFilter, setListsOfElems],

  props: {
    component: {
      type: String,
    },
    secondComponent: {
      type: String,
    },
    sortBy: {
      type: String,
      default: 'name',
    },
    typeList: {
      type: String,
      default: 'child',
    },
    api_name: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    arrays: {
      activeItems: [],
      restItems: [],
    },
    search: '',
    single_names: {
      parent: '',
      child: '',
    },
    options: {
      jdata: {},
    },
    activeItem: null,
  }),
  computed: {
    getListActiveItems() {
      const activeItems = [...this.arrays.activeItems].sort((a, b) =>
        a[this.sortBy] > b[this.sortBy] ? 1 : -1
      )
      return activeItems.filter((item) => this.searchFields(item, this.secondComponent))
    },

    getListRestItems() {
      const restItems = [...this.arrays.restItems].sort((a, b) =>
        a[this.sortBy] > b[this.sortBy] ? 1 : -1
      )
      return restItems.filter((item) => this.searchFields(item, this.secondComponent))
    },

    getList() {
      const activeItems = [...this.arrays.activeItems].sort((a, b) =>
        a[this.sortBy] > b[this.sortBy] ? 1 : -1
      )
      const restItems = [...this.arrays.restItems].sort((a, b) =>
        a[this.sortBy] > b[this.sortBy] ? 1 : -1
      )
      return [...activeItems, ...restItems].filter((item) =>
        this.searchFields(item, this.secondComponent)
      )
    },
    getSubheader() {
      return this.config.module?.[this.secondComponent]?.label ?? ''
    },
    getActionButtons() {
      return this.config.fields[this.api_name]
        .filter((item) => item.tag === 'button')
        .sort((a, b) => (a.sort > b.sort ? 1 : -1))
    },
    ...mapGetters(['getDicts', 'config']),
  },

  watch: {
    $route(to, from) {
      this.shouldUpdateList(to, from)
    },
    activeItem(link) {
      if (link) {
        const linkElement = this.getLinkElement(link)
        this.options = linkElement
      }
    },
  },
  created() {
    this.setSingleNameComponents()
    this.init()
  },

  methods: {
    init() {
      Object.keys(this.arrays).forEach((arr) => {
        this.arrays[arr] = []
      })

      const getSecondItems = {
        child: this.getChildren,
        link: this.getLinks,
      }
      getSecondItems[this.typeList]()
    },
    shouldUpdateList(to, from) {
      const differentIds = () => to.params[this.component] !== from.params[this.component]
      if (differentIds()) {
        this.init()
      }
    },
    toggleBindElement(element) {
      const type_list = {
        child: element.toggle_bind_elem ? this.removeChild : this.addChild,
        link: element.toggle_bind_elem ? this.removeLink : this.addLink,
      }
      this.$set(element, 'toggle_bind_elem', !element.toggle_bind_elem)
      type_list[this.typeList](element)
    },
    async removeChild(child) {
      child[`id_${this.single_names.parent}`] = null

      const {data} = await HTTP.put(`${this.secondComponent}`, child)
      if (data) {
        this.arrays.activeItems = this.arrays.activeItems.filter(({id}) => child.id !== id)
        this.arrays.restItems.push(child)

        this.$store.commit('PUT_ITEM', {
          ...child,
          jdata: {
            ...child.jdata,
            component: this.secondComponent,
          },
        })
      }
    },
    async addChild(child) {
      child[`id_${this.single_names.parent}`] = this.$route.params[this.component]

      const {data} = await HTTP.put(`${this.secondComponent}`, child)
      if (data) {
        this.arrays.restItems = this.arrays.restItems.filter(({id}) => child.id !== id)
        this.arrays.activeItems.push(child)

        this.$store.commit('PUT_ITEM', {
          ...child,
          jdata: {
            ...child.jdata,
            component: this.secondComponent,
          },
        })
      }
    },
    async removeLink(link) {
      const existLinkElement = this.arrays.restItems.find(({id}) => link.id === id)
      if (!existLinkElement) {
        this.arrays.activeItems = this.arrays.activeItems.filter(
          (element) => link.id !== element.id
        )
        this.arrays.restItems.push(link)
      }
    },
    async addLink(link) {
      const existLinkElement = this.arrays.activeItems.find(({id}) => link.id === id)
      if (!existLinkElement) {
        this.arrays.restItems = this.arrays.restItems.filter(({id}) => link.id !== id)
        this.arrays.activeItems.push(link)
      }
    },

    getLinkElement(link) {
      const links = this.getDicts(this.api_name)
      const linkElement =
        links.find(
          (element) =>
            element[`id_${this.single_names.child}`] === link.id &&
            element[`id_${this.single_names.parent}`] === this.$route.params[this.component]
        ) || {}

      return linkElement
    },

    async getLinks() {
      const {data} = await HTTP.post(this.api_name)
      if (data) {
        const hasIdParentLinks = data.filter(
          (element) =>
            element[`id_${this.single_names.parent}`] === this.$route.params[this.component]
        )
        this.arrays.activeItems = this.getDicts(this.secondComponent)
          .filter((element) =>
            hasIdParentLinks.some(
              (parent) => element.id === parent[`id_${this.single_names.child}`]
            )
          )
          .map((element) => ({
            ...element,
            toggle_bind_elem: true,
          }))
        this.arrays.restItems = this.getDicts(this.secondComponent)
          .filter((element) =>
            hasIdParentLinks.every(
              (parent) => element.id !== parent[`id_${this.single_names.child}`]
            )
          )
          .map((element) => ({
            ...element,
            toggle_bind_elem: false,
          }))
      }
    },
    getChildren() {
      this.arrays.activeItems = this.getDicts(this.secondComponent)
        .filter(
          (element) =>
            element[`id_${this.single_names.parent}`] === this.$route.params[this.component]
        )
        .map((element) => ({
          ...element,
          toggle_bind_elem: true,
        }))
      this.arrays.restItems = this.getDicts(this.secondComponent)
        .filter((element) => element[`id_${this.single_names.parent}`] === null)
        .map((element) => ({
          ...element,
          toggle_bind_elem: false,
        }))
    },
    setSingleNameComponents() {
      this.single_names = {
        parent: this.component.replace(/s$/, ''),
        child: this.secondComponent.replace(/s$/, ''),
      }
    },

    toggleActiveItem(element) {
      if (this.activeItem === null || this.activeItem.id !== element.id) {
        this.activeItem = element
      } else {
        this.activeItem = null
      }
    },

    async putAction(button) {
      this.options[`id_${this.single_names.parent}`] = this.$route.params[this.component]
      this.options[`id_${this.single_names.child}`] = this.activeItem.id

      if (button.field === 'cmd_save') {
        const linkElement = await this.saveItemEdit(button)
        this.$store.commit('PUT_ITEM', {
          ...linkElement,
          jdata: {...linkElement.jdata, component: `${this.component}_${this.secondComponent}`},
        })
        this.addLink(this.activeItem)
      }

      if (button.field === 'cmd_delete') {
        await this.saveItemEdit(button)
        this.$store.commit('DELETE_ITEM', {
          dict: `${this.component}_${this.secondComponent}`,
          id: this.options.id,
        })
        this.removeLink(this.activeItem)
        this.options = {
          jdata: {},
        }
      }
    },
  },
}
</script>
